import { ReactNode } from 'react';

import { Box } from '../box/box';
import { AlertCircleIcon, EmptyCheckIcon, FilledCheckIcon } from '../icons';
import { Text } from '../text/text';

export const TEST_IDS = {
  CONTAINER: 'validation-checklist--container',
  ICON_INCOMPLETE: 'validation-checklist--icon-incomplete',
  ICON_COMPLETE: 'validation-checklist--icon-complete',
  ICON_ERROR: 'validation-checklist--icon-error',
};

interface ValidationChecklistItem {
  valid: boolean;
  text: ReactNode;
}

export interface ValidationChecklistProps {
  id?: string;
  displayState?: 'error' | 'incomplete';
  items: ValidationChecklistItem[];
}

export const ValidationChecklist = ({
  id,
  items,
  displayState = 'incomplete',
}: ValidationChecklistProps) => {
  return (
    <Box component="ul" id={id} data-testid={TEST_IDS.CONTAINER}>
      {items.map(({ valid, text }, i) => (
        <Box
          key={i}
          stack="row"
          alignItems={['left', 'center']}
          spacing="baseNeg3"
          component="li"
        >
          {!valid && displayState === 'incomplete' ? (
            <EmptyCheckIcon data-testid={TEST_IDS.ICON_INCOMPLETE} size="s" />
          ) : null}
          {!valid && displayState === 'error' ? (
            <AlertCircleIcon
              data-testid={TEST_IDS.ICON_ERROR}
              size="s"
              type="error"
            />
          ) : null}
          {valid ? (
            <FilledCheckIcon data-testid={TEST_IDS.ICON_COMPLETE} size="s" />
          ) : null}
          <Text
            color={
              !valid && displayState === 'error' ? 'alert500' : 'neutral500'
            }
            variant="caption150"
          >
            {text}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

import { useState } from 'react';

import { SortCodeInput, Text } from '@shieldpay/bumblebee';
import InputFieldMarkdown from '@shieldpay/bumblebee/markdown/sort-code-input.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const SortCodeInputPage = () => {
  usePageTitle({ id: 'hive.components.sortCodeInput.title' });

  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('123456');

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <SortCodeInput
          id="someInput1"
          label="I am a sort code input with some help"
          helpText="e.g. 12-34-56"
          onChange={(value) => setValue1(value)}
          value={value1}
          bankPlaceholderIconAlt="Bank"
        />
        <SortCodeInput
          id="someInput2"
          label="I am a sort code input with some help and an error"
          helpText="Example: name@domain.com"
          validation={{ messages: ['Sort it out pal'], valid: false }}
          showValidation={true}
          onChange={(value) => setValue2(value)}
          value={value2}
          bankPlaceholderIconAlt="Bank"
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The Sort code input component is the interactive component used to
            capture a user’s UK bank sort code. It consists of a field, a bank
            icon and helper text.
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            The sort code input height is constructed based on the ‘Field input‘
            text style and padding.
          </Text>
          <Text paragraph>
            The bank icon sits within the sort code input and it is visible all
            the time. It is constructed using one SVG icons drawn at size L
            [32px] and and grey background.
          </Text>
          <Text paragraph>
            The width of the sort code input is flexible, unless specifically
            constrained by a defined value or alignment. The width of the form
            field should match the width of the field label text box across all
            viewport sizes, unless otherwise specified.
          </Text>
          <Text paragraph>
            The form field has a 1px border with 4px rounded corners to be
            consistent with the buttons.
          </Text>
          <Text paragraph>
            When text is entered/displayed in the form field it uses the ‘Field
            input’ style (16px) across all viewports. It is not proposed to
            display a disabled state for an empty field. If a field is not
            required it should be hidden.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            The state of the sort code input changes depending on the actions of
            the user.
          </Text>
          <Text paragraph>
            The component works as a combination of text input and icon. The
            user is able to type into the field. When the user enters the sort
            code number a sort code number is recognised, the identified bank
            logo will replace the bank icon.
          </Text>
          <Text paragraph>
            When the user is typing the sort code, the digits will split
            automatically into pairs by hyphens [even if the user doesn’t enter
            hyphens]
          </Text>
          <Text paragraph>
            The length of the sort code should be 6 digits + 2 hyphens = 8
            symbols. The user won’t be allowed to type more than 6 digits.{' '}
          </Text>
          <Text paragraph>
            The sort code input should employ existing behaviour native to
            inputs.
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            The focused state is used to help accessibility and is consistent
            with the focus state used on other components. Use the appropriate
            aria attributes for screen readers where applicable.
          </Text>
          <Text paragraph>
            The ‘Text’ style colour used on entered text in the field, is
            comfortably within the AAA contrast ratio for legibility.
          </Text>
          <Text paragraph>
            Placeholder text will not be used within text fields. Placeholder
            text has to be dark enough to pass AA rating for legibility. It can
            confuse users who may think the field is already completed. When a
            detailed description or an example is required to complement a field
            label, ‘Helper text’ will be used.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <InputFieldMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { Children, cloneElement, ReactElement, ReactNode } from 'react';

import { Color } from '../text/use-text.styles';
import * as styles from './icon.styles';

export type IconType = keyof typeof styles.iconType;

export type IconSize = keyof typeof styles.iconSize;

export interface IconProps {
  className?: string;
  size?: IconSize;
  children?: ReactNode;
  type?: IconType;
  color?: Color;
  'data-testid'?: string;
  'svg-testid'?: string;
}

export const Icon = ({
  className,
  size = 'm',
  children,
  type = 'default',
  color = 'currentColor',
  'data-testid': testId,
  'svg-testid': svgTestId,
}: IconProps) => {
  // Pass down props to rendered SVG
  const svgChildren = Children.map(children, (child, i) =>
    cloneElement(child as ReactElement, {
      css: [styles.svg, styles.iconType[type]],
      'data-testid': svgTestId,
      preserveAspectRatio: 'none',
      index: i,
    }),
  );

  return (
    <span
      aria-hidden="true"
      className={className}
      css={[styles.icon, styles.iconSize[size], color && styles.color(color)]}
      data-testid={testId}
    >
      {svgChildren}
    </span>
  );
};

import { ReactNode, useState } from 'react';

import { useAriaId } from '../../hooks/use-aria-id';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { HideIcon, ShowIcon } from '../icons';
import { InputField, InputFieldProps } from '../input-field/input-field';
import { Text } from '../text/text';

import * as styles from './password-input.styles';

export const TEST_IDS = {
  SHOW_HIDE_TOGGLE: 'password-show-hide-toggle',
  INPUT: 'password-input',
};

export interface PasswordInputProps extends Omit<InputFieldProps, 'password'> {
  showPasswordText: ReactNode;
  hidePasswordText: ReactNode;
}

export const PasswordInput = ({
  showPasswordText,
  hidePasswordText,
  ...inputProps
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const ariaId = useAriaId('show-hide');

  return (
    <InputField
      {...inputProps}
      type={showPassword ? 'text' : 'password'}
      data-testid={TEST_IDS.INPUT}
      css={[styles.withInputControl, styles.input]}
      extraContainerStyles={[styles.inputDisableFocusWhenButtonFocused]}
    >
      <Box
        css={styles.buttonContainer}
        alignItems={['center', 'center']}
        padding="baseNeg2"
      >
        {/*
          TODO: Extract this (and associated styles) into an InputControl
          component or similar when the need arises.
        */}
        <Button
          aria-labelledby={ariaId}
          aria-pressed={showPassword}
          css={styles.showPasswordToggle}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setShowPassword(!showPassword);
          }}
          data-testid={TEST_IDS.SHOW_HIDE_TOGGLE}
          variant="icon"
          RightIcon={showPassword ? HideIcon : ShowIcon}
        >
          <Text id={ariaId} screenReaderOnly>
            {showPassword ? hidePasswordText : showPasswordText}
          </Text>
        </Button>
      </Box>
    </InputField>
  );
};

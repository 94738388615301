import { css, keyframes } from '@emotion/react';

import { themeifyStyle, transparentize } from '../../themes/utilities';

const fade = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const dialog = themeifyStyle((theme) => ({
  // Override browser defaults
  background: 'transparent',
  overflow: 'visible',
  // Margin auto centers dialog when in fullscreen mode
  margin: 'auto',
  // The Box component sets display to flex, so override
  // this when the modal is not active
  // https://developer.mozilla.org/en-US/docs/Web/CSS/:modal
  ':not(:modal)': {
    display: 'none',
  },
  // Entry animation to dialog::backdrop, cannot be done
  // with css transition or react-spring
  '::backdrop': {
    animation: `0.3s ${fade}`,
    background: transparentize(theme.palette.neutral500, 25),
  },
}));

export const modalActions = css({
  button: { width: '100%' },
});

export const size = {
  large: themeifyStyle(({ modal }) => ({
    width: `${modal.width.large}px`,
  })),
};

export const close = themeifyStyle((theme) => ({
  display: 'inline',
  color: theme.palette.primary500,
  position: 'absolute',
  borderRadius: theme.borderRadius,
  padding: theme.spacing.baseNeg4,
  top: 0,
  right: 0,
}));

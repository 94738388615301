import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Label } from '../label/label';

import * as styles from './form-control.styles';

export type FormControlProps = {
  id: string;
  onClick: () => void;
  label: ReactNode;
  checked?: boolean;
  'data-testid'?: string;
  children?: ReactNode;
  disabled?: boolean;
  name?: string;
} & (
  | {
      type: 'radio';
      /** a string value required to when reading the form on submit*/
      value: string;
    }
  | { type: 'checkbox'; value?: never }
);

export const TEST_IDS = {
  CHECKBOX: 'form-control',
  FAUX_INPUT: 'form-control-faux-input',
};

export const FormControl = ({
  onClick,
  id,
  label,
  checked = false,
  'data-testid': testId,
  type,
  children,
  disabled = false,
  name,
  value,
}: FormControlProps) => (
  <Box
    stack="row"
    alignItems={['left', 'top']}
    spacing="baseNeg2"
    touchArea={!disabled}
    data-testid={testId}
  >
    <Box
      component="input"
      screenReaderOnly
      id={id}
      name={name}
      type={type}
      checked={checked}
      onChange={onClick}
      data-testid={TEST_IDS.CHECKBOX}
      disabled={disabled}
      value={value}
    />
    <Box
      aria-hidden
      css={[
        styles.fauxInput,
        styles.fauxInputType[type],
        !disabled ? styles.fauxInputStates : styles.fauxInputDisabled,
      ]}
      component="label"
      htmlFor={id}
      stack="row"
      alignItems={['center', 'center']}
      data-testid={TEST_IDS.FAUX_INPUT}
    >
      {checked ? children : null}
    </Box>
    <Label htmlFor={id} disabled={disabled} variant="body150">
      {label}
    </Label>
  </Box>
);

import { Button } from '../button/button';
import {
  ChevronLeftEndIcon,
  ChevronLeftIcon,
  ChevronRightEndIcon,
  ChevronRightIcon,
} from '../icons';
import { Link } from '../link/link';
import { Text } from '../text/text';

import * as styles from './pagination-item.styles';

export type IconType = keyof typeof icons;

interface SharedProps {
  /**
   * The type of pagination item/icon.
   */
  type: IconType;
  /**
   * Total page count.
   */
  pageCount: number;
  /**
   * The current page in Pagination component.
   */
  currentPage: number;
}

export interface PaginationItemProps extends SharedProps {
  createTo: (page: number) => string;
}

/**
 * @deprecated
 * Uses handler when the page is changed. [Legacy - SPA]
 */
export interface PaginationItemLegacyProps extends SharedProps {
  onPageChange: (page: number) => void;
}

export const icons = {
  first: ChevronLeftEndIcon,
  previous: ChevronLeftIcon,
  next: ChevronRightIcon,
  last: ChevronRightEndIcon,
};

// Map the button type to its page number
const buttonPage = (type: IconType, currentPage: number, pageCount: number) => {
  switch (type) {
    case 'previous':
      return currentPage - 1;
    case 'next':
      return currentPage + 1;
    case 'last':
      return pageCount;
    case 'first':
    default:
      return 1;
  }
};

export const PaginationItem = ({
  type,
  createTo,
  currentPage,
  pageCount,
}: PaginationItemProps) => {
  const Icon = icons[type];

  const isDisabled =
    type === 'next' || type === 'last'
      ? currentPage >= pageCount
      : currentPage <= 1;

  return (
    <Link
      css={[
        styles.paginationItem,
        isDisabled ? styles.disabled : styles.enabled,
      ]}
      disabled={isDisabled}
      size="small"
      variant="outline"
      to={createTo(buttonPage(type, currentPage, pageCount))}
    >
      <Icon size="s" />
      {/* TODO  these should be internationalised */}
      <Text screenReaderOnly>{type}</Text>
    </Link>
  );
};

/**
 * @deprecated
 * Uses handler when the page is changed. [Legacy - SPA]
 */
export const PaginationItemLegacy = ({
  type,
  onPageChange,
  currentPage,
  pageCount,
}: PaginationItemLegacyProps) => {
  const Icon = icons[type];

  const isDisabled =
    type === 'next' || type === 'last'
      ? currentPage >= pageCount
      : currentPage <= 1;

  return (
    <Button
      css={[
        styles.paginationItem,
        isDisabled ? styles.disabled : styles.enabled,
      ]}
      disabled={isDisabled}
      size="small"
      variant="outline"
      onClick={() => onPageChange(buttonPage(type, currentPage, pageCount))}
    >
      <Icon size="s" />
      {/* TODO  these should be internationalised */}
      <Text screenReaderOnly>{type}</Text>
    </Button>
  );
};

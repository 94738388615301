import { Box, Checkbox, Text, TextList } from '@shieldpay/bumblebee';
import CheckboxMarkdown from '@shieldpay/bumblebee/markdown/checkbox.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const CheckboxComponentPage = () => {
  usePageTitle({ id: 'hive.components.checkbox.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Checkbox
          id="example1"
          key="example1"
          label="I am checkbox 1, check it."
          onChange={noop}
        />
        <Checkbox
          id="example2"
          key="example2"
          label="I am checkbox 2, check it."
          onChange={noop}
          value={true}
        />
        <Checkbox
          id="example3"
          key="example3"
          label="I am a disabled checkbox."
          onChange={noop}
          disabled
        />
        <Checkbox
          id="example4"
          key="example4"
          label="I have a validation error"
          onChange={noop}
          showValidation={true}
          validation={{
            valid: false,
            messages: ['Please select'],
          }}
        />
        <Checkbox
          id="example5"
          key="example5"
          label={
            <>
              I am a checkbox with an incredibly very overly long label, I need
              to be that long so that I can wrap and everybody can see how
              pretty I look nonetheless
            </>
          }
          onChange={noop}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Box>
            <Text paragraph>Only to be used when:</Text>
            <TextList>
              <TextList.Item>
                Selecting one or more options from a TextList.
              </TextList.Item>
              <TextList.Item>
                Presenting a TextList containing sub-selections.
              </TextList.Item>
              <TextList.Item>Turn an item on or off.</TextList.Item>
            </TextList>
          </Box>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <CheckboxMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

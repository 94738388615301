import { useSelect } from 'downshift';

import { usePositionedPopup } from '../../hooks/use-positioned-popup';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { GrandEntrance } from '../grand-entrance/grand-entrance';
import { ChevronDownIcon } from '../icons';
import { Text } from '../text/text';

import { items, UserMenuCard, UserMenuCardProps } from './user-menu-card';

import * as styles from './user-menu-legacy.styles';

export interface UserMenuLegacyProps extends UserMenuCardProps {
  logOutCallback: () => void;
  logOutHref?: never;
}

export const TEST_IDS = {
  EMAIL: 'user-menu--email',
};

export const UserMenuLegacy = ({
  email,
  group,
  logOutCallback,
  logOutText,
}: UserMenuLegacyProps) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    items,
    id: 'user-menu',
    stateReducer: (state, { changes }) => {
      if (state.isOpen === false && changes.isOpen === true) {
        // deselect everything when opening
        return {
          ...changes,
          selectedItem: '',
        };
      }

      // We see if the logout link has been selected when the menu is closed and logout if so
      if (
        changes.selectedItem === 'logout' &&
        state.isOpen === true &&
        changes.isOpen === false &&
        document.hasFocus() // this can fire when you switch tabs if we don’t assert this
      ) {
        logOutCallback();
        // returning state here, in case there are any issues with returning undefined
        return state;
      }

      // …otherwise default useSelect reducer behaviour
      return changes;
    },
  });

  const labelProps = getLabelProps();

  const {
    callbackRefs,
    mutableRefs: { reference },
    constrainStyles,
    positionStyles,
  } = usePositionedPopup({
    open: isOpen,
    placement: 'bottom-end',
  });

  return (
    <>
      <Text paragraph data-testid={TEST_IDS.EMAIL} {...labelProps}>
        {email}
      </Text>
      <Button
        // merge refs used by downshift and usePositionedPopup
        {...getToggleButtonProps({ ref: reference })}
        variant="icon"
      >
        <ChevronDownIcon />
      </Button>

      <Box
        ref={callbackRefs.setFloating}
        css={[positionStyles, styles.floating]}
      >
        <GrandEntrance
          show={isOpen}
          config={
            isOpen
              ? {
                  mass: 1,
                  tension: 260,
                  friction: 10,
                  precision: 0.01,
                  clamp: true,
                  velocity: 0.002, // seconds
                }
              : {
                  duration: 0,
                }
          }
        >
          <Box
            css={[styles.menu, constrainStyles]}
            alignItems={false}
            padding={{ top: 'baseNeg2' }}
            aria-labelledby={labelProps.id}
          >
            <UserMenuCard
              email={email}
              group={group}
              logOutText={logOutText}
              getMenuProps={getMenuProps}
              getItemProps={getItemProps}
              logoutSelected={highlightedIndex === 0}
            />
          </Box>
        </GrandEntrance>
      </Box>
    </>
  );
};

import { memo, ReactNode, useEffect, useId, useMemo, useState } from 'react';
import { useSelect } from 'downshift';

import { ValidationProps } from '@shieldpay/blurr';
import { onlyText } from '@shieldpay/utility-functions-ui';

import { usePositionedPopup } from '../../hooks/use-positioned-popup';
import { Box } from '../box/box';
import { Padding } from '../box/use-box-styles';
import { Card } from '../card/card';
import { GrandEntrance } from '../grand-entrance/grand-entrance';
import { ChevronDownIcon, ChevronUpIcon, TickIcon } from '../icons';
import { useInputFieldStyles } from '../input-field/use-input-field-styles';
import { Label } from '../label/label';
import { Text } from '../text/text';

import * as styles from './dropdown.styles';

export interface DropdownItem<Value> {
  /* NOTE: the label cannot use <FormattedMessage /> because the onlyText
   * function cannot extract the text.
   **/
  label: ReactNode;
  value: Value;
}

export interface DropdownProps<Value = string> {
  /* NOTE: the label on each item cannot use <FormattedMessage />
   * because the onlyText function cannot extract the text.
   **/
  items: DropdownItem<Value>[];
  label: ReactNode;
  /* set this to true to use a <label /> element when in a field context */
  useLabelComponent?: boolean;
  placeholder?: ReactNode;
  size?: 'medium' | 'large';
  disabled?: boolean;
  validation?: ValidationProps;
  /* Supply a value to make component controlled
   * If you want a placeholder value use an empty string ('')
   **/
  value?: Value | '';
  onBlur?: () => void;
  onChange: (value: Value) => void;
  hasError?: boolean;
}

/**
 * Our validation is designed to show when a field loses focus, and we need to
 * simulate this in this custom <select>-like component in two cases:
 * 1: When a user is focussed on the button, and then onto outside of the component
 * 2: When the user is focussed on the menu, and then onto outside of the component
 *
 * `menuOpen` and `buttonState` are used to track this.
 *
 * We set `buttonState` to 'DEFOCUSSED' on button blur event.
 *
 * 1: `isOpen` will be `false` if user has left this component so in useEffect we fire `onBlur`.
 * 2: `isOpen` will be `true` when user opens menu, so `onBlur` will not fire at this point,
 *     but will fire until the users focus moves away from the component. Note that we
 *     set `buttonState` to 'FOCUSSED' if the focus moves back to the button.
 */
const useSimulatedOnBlur = (menuOpen: boolean, onBlur?: () => void) => {
  const [buttonState, setButtonState] = useState<
    'IDLE' | 'FOCUSSED' | 'DEFOCUSSED'
  >('IDLE');

  useEffect(() => {
    if (onBlur && buttonState === 'DEFOCUSSED' && !menuOpen) {
      onBlur?.();
      setButtonState('IDLE');
    }
  }, [buttonState, menuOpen, onBlur]);

  return onBlur
    ? {
        onBlur: () => setButtonState('DEFOCUSSED'),
        onFocus: () => setButtonState('FOCUSSED'),
      }
    : {};
};

export const DropdownWithoutMemo = <Value,>({
  disabled = false,
  items,
  label,
  useLabelComponent = false,
  placeholder,
  size = 'medium',
  onChange,
  onBlur,
  value,
  hasError,
}: DropdownProps<Value>) => {
  // id needs to be consistent between server and client
  const id = useId();

  const { inputStyles, containerStyles, stateStyles } = useInputFieldStyles({
    disabled,
    includePadding: false,
    error: hasError,
  });

  // When running as a controlled version by passing in a value, memoize
  // the selectedItem because otherwise every render the useSelect hook
  // thinks the selectedItem has changed and updates the
  // `#a11y-status-message`.
  const controlledSelectedItem = useMemo(
    () =>
      // We need to create a special case here to pass in a empty string ''
      // which marks this out as a controlled input without an initial value
      value === ''
        ? ({ value: '' } as DropdownItem<''>)
        : items.find((item) => item.value === value),
    // disable exhaustive-deps, because we want to check deep equality of items values
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(items.map(({ value }) => value)), value],
  );

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect<DropdownItem<Value | ''>>({
    id: `dropdown-${id}`,
    items,
    // This runs on each item whenever a keypress happens on the button
    // to allow us to match a string to the label, which will be a ReactNode
    // Do not use <FormattedMessage />, use formatMessage. See OPTIMUS-600 */
    itemToString: (item) => onlyText(item?.label),
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem?.value && onChange(selectedItem.value);
    },
    selectedItem: controlledSelectedItem,
  });

  const buttonEventHandlers = useSimulatedOnBlur(isOpen, onBlur);

  const labelProps = getLabelProps();

  const {
    mutableRefs: { reference },
    callbackRefs: { setFloating },
    positionStyles,
    constrainStyles,
  } = usePositionedPopup({
    matchWidths: true,
    open: isOpen,
  });

  const itemPadding: Record<'text' | 'chevron', Padding> = useMemo(() => {
    return {
      text: {
        left: 'base',
        y: size === 'medium' ? 'baseNeg2' : 'basePos1',
      },
      chevron: {
        left: 'baseNeg3',
        right: 'baseNeg1',
      },
    };
  }, [size]);

  // large lists will spend a lot of time rendering, we can reduce rerender time
  // by memoizing
  const menuItems = useMemo(
    () =>
      items.map((item, index) => (
        <Box
          component="li"
          stack="row"
          alignItems={['full', 'center']}
          key={item.value}
          {...getItemProps({ item, index })}
          css={[highlightedIndex === index && styles.highlightedItem]}
        >
          {/**
           *  We only apply vertical padding to the text, if we added it to the
           *  Icon if would make the item too tall
           */}
          <Text variant="input" padding={itemPadding.text}>
            {item.label}
          </Text>

          <Box padding={itemPadding.chevron}>
            {selectedItem?.value === item.value ? <TickIcon /> : null}
          </Box>
        </Box>
      )),
    [getItemProps, highlightedIndex, itemPadding, items, selectedItem?.value],
  );

  return (
    <>
      <Box spacing="baseNeg4">
        {useLabelComponent ? (
          <Label {...labelProps}>{label}</Label>
        ) : (
          <Text {...labelProps} variant="caption">
            {label}
          </Text>
        )}
        <Box
          component="button"
          type="button"
          css={[inputStyles, containerStyles, stateStyles, styles.button]}
          disabled={disabled}
          stack="row"
          padding="none"
          alignItems={['full', 'center']}
          rounded
          // merge refs used by downshift and usePositionedPopup
          {...getToggleButtonProps({
            ref: reference,
          })}
          {...buttonEventHandlers}
        >
          {/**
           *  We only apply vertical padding to the text, if we added it to the
           *  chevron icons if would make the button too tall
           */}
          <Text
            truncate
            color="currentColor"
            variant="input"
            padding={itemPadding.text}
          >
            {selectedItem?.label || placeholder}
          </Text>

          <Box padding={itemPadding.chevron}>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Box>
        </Box>
      </Box>
      <GrandEntrance
        css={styles.dropdown}
        show={!disabled && isOpen}
        animateHeight={false}
        config={
          isOpen
            ? {
                mass: 1,
                tension: 260,
                friction: 10,
                precision: 0.01,
                clamp: true,
                velocity: 0.002, // seconds
              }
            : {
                duration: 0,
              }
        }
      >
        <Box
          ref={setFloating}
          css={[positionStyles]}
          padding={{ top: 'baseNeg6' }}
        >
          <Card
            component="ul"
            padding="none"
            {...getMenuProps()}
            css={[
              styles.menu,
              constrainStyles, // sets the max-height
            ]}
          >
            {menuItems}
          </Card>
        </Box>
      </GrandEntrance>
    </>
  );
};

DropdownWithoutMemo.displayName = 'Dropdown';

export const Dropdown = memo(DropdownWithoutMemo) as typeof DropdownWithoutMemo;

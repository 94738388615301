import { createContext, ElementType, ReactNode, useContext } from 'react';
import { Global, Theme, ThemeProvider } from '@emotion/react';

import { shieldpayTheme } from '../../themes/shieldpay';

import * as styles from '../../reset/reset.styles';
export interface BumblebeeTheme {
  name: string;
  tokens: Theme;
}

interface BumblebeeThemeContext extends BumblebeeTheme {
  NavLink: ElementType;
}

export interface BumblebeeProviderProps {
  children: ReactNode;
  theme?: BumblebeeTheme;
  includeReset?: boolean;
  NavLink: ElementType;
}

const defaultTheme: BumblebeeTheme = {
  name: 'shieldpay',
  tokens: shieldpayTheme,
};

const BumblebeeContext = createContext<BumblebeeThemeContext | undefined>(
  undefined,
);

export const useBumblebeeContext = (): BumblebeeThemeContext => {
  const context = useContext(BumblebeeContext);

  /* istanbul ignore next */
  if (context === undefined) {
    throw new Error(
      'useBumblebeeContext must be used within a BumblebeeProvider',
    );
  }

  /* istanbul ignore next */
  if (context.NavLink === undefined) {
    throw new Error('NavLink must be added to BumblebeeProvider');
  }

  return context;
};

export const BumblebeeProvider = ({
  theme = defaultTheme,
  children,
  NavLink,
  includeReset = true,
}: BumblebeeProviderProps) => {
  return (
    <BumblebeeContext.Provider value={{ ...theme, NavLink }}>
      <ThemeProvider theme={theme.tokens}>
        {includeReset ? <Global styles={Object.values(styles)} /> : null}
        {children}
      </ThemeProvider>
    </BumblebeeContext.Provider>
  );
};

import { Box, Heading, TextList } from '@shieldpay/bumblebee';
import BoxMarkdown from '@shieldpay/bumblebee/markdown/box.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const BoxComponentPage = () => {
  usePageTitle({ id: 'hive.components.box.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Box spacing="base">
          <Box stack="row" spacing="base" alignItems={['left', 'center']}>
            <Heading>Box 1</Heading>
            <TextList>
              <TextList.Item>stack: row</TextList.Item>
              <TextList.Item>spacing: base</TextList.Item>
              <TextList.Item>alignVertical: center</TextList.Item>
              <TextList.Item>alignHorizontal: left</TextList.Item>
            </TextList>
          </Box>
          <Box stack="row" spacing="base" alignItems={['right', 'center']}>
            <Heading>Box 2</Heading>
            <TextList>
              <TextList.Item>stack: row</TextList.Item>
              <TextList.Item>spacing: base</TextList.Item>
              <TextList.Item>alignVertical: center</TextList.Item>
              <TextList.Item>alignHorizontal: right</TextList.Item>
            </TextList>
          </Box>
          <Box stack="row" spacing="base" alignItems={['center', 'center']}>
            <Heading>Box 3</Heading>
            <TextList>
              <TextList.Item>stack: row</TextList.Item>
              <TextList.Item>spacing: base</TextList.Item>
              <TextList.Item>alignVertical: center</TextList.Item>
              <TextList.Item>alignHorizontal: center</TextList.Item>
            </TextList>
          </Box>
        </Box>
        <Box spacing="baseNeg3" padding="basePos5">
          <Box stack="column" spacing="base" alignItems={['left', 'center']}>
            <Heading>Box 4</Heading>
            <TextList>
              <TextList.Item>stack: column</TextList.Item>
              <TextList.Item>spacing: base</TextList.Item>
              <TextList.Item>alignVertical: center</TextList.Item>
              <TextList.Item>alignHorizontal: left</TextList.Item>
            </TextList>
          </Box>
          <Box stack="column" spacing="base" alignItems={['right', 'center']}>
            <Heading>Box 5</Heading>
            <TextList>
              <TextList.Item>stack: column</TextList.Item>
              <TextList.Item>spacing: base</TextList.Item>
              <TextList.Item>alignVertical: center</TextList.Item>
              <TextList.Item>alignHorizontal: right</TextList.Item>
            </TextList>
          </Box>
          <Box stack="column" spacing="base" alignItems={['center', 'center']}>
            <Heading>Box 6</Heading>
            <TextList>
              <TextList.Item>stack: column</TextList.Item>
              <TextList.Item>spacing: base</TextList.Item>
              <TextList.Item>alignVertical: center</TextList.Item>
              <TextList.Item>alignHorizontal: center</TextList.Item>
            </TextList>
          </Box>
        </Box>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <BoxMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { Box, Link, Text, TextList } from '@shieldpay/bumblebee';
import LinkMarkdown from '@shieldpay/bumblebee/markdown/link.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { ButtonAppearance } from '../../components/clickable-elements/button-appearance';
import { LinkAppearance } from '../../components/clickable-elements/link-appearance';
import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const LinkComponentPage = () => {
  usePageTitle({ id: 'hive.components.link.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview spacing="basePos5">
        <LinkAppearance Component={Link} />
        <ButtonAppearance Component={Link} />
      </ComponentPreview>
      <Design>
        <Text paragraph>
          The links come in two sizes, Medium and Small. They are made up of
          text styles, a bottom borders, and icons. Using the primary, lighter
          and darker versions of our primary color.
        </Text>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            The margin between the text and the underline is equal to 2 px.
          </Text>
          <Text paragraph>
            The text and the underline are always positioned in the center of
            the container.
          </Text>
          <Text paragraph>
            The height of the container for Medium Default and Medium Default
            underlined links equals 18 pixels. So when no underline — there are
            1px top and bottom margins.
          </Text>
          <Text paragraph>
            The height of the container for Small Default and Small Default
            underlined links equals 16 pixels. So when no underline — there are
            1px top and bottom margins.
          </Text>
          <Text paragraph>
            Text (with Underline) in Links with Icons is always centered
            according to the icon.
          </Text>
          <Text paragraph>
            The height of the container for Medium with Icon link equals 24
            pixels. So when no underline — there are 4px top and bottom margins.
          </Text>
          <Text paragraph>
            The height of the container for Small with Icon link equals 20
            pixels. So when no underline — there are 3px top and bottom margins.
          </Text>
        </Design.Section>
        <Design.Section heading="Usage">
          <Text paragraph>
            Links are used as a references to data that the user can follow by
            clicking or tapping.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Box>
            <Text paragraph>Links have 5 states:</Text>
            <TextList>
              <TextList.Item>
                Default (allow user to click this text link)
              </TextList.Item>
              <TextList.Item>Hover (usual hover state)</TextList.Item>
              <TextList.Item>
                Focus (for click the link using the keyboard buttons)
              </TextList.Item>
              <TextList.Item>
                Disabled (show the link but don’t allow to click on this
                according to some restrictions)
              </TextList.Item>
              <TextList.Item>Visited</TextList.Item>
            </TextList>
          </Box>

          <Text paragraph>
            The ‘Visited’ state should use the same values as the ‘Default’
            state, or it will be distracting in the context the links are being
            used.
          </Text>
        </Design.Section>

        <Design.Section heading="Hover/Focus transition">
          <Text paragraph>0.25 ease (To be reviewed in browser)</Text>
        </Design.Section>

        <Design.Section heading="Accessibility of disabled text link / button">
          <Text paragraph>
            Use the disabled or aria-disabled attributes to convey information
            to screen readers.
          </Text>

          <Text paragraph>
            Add to your disabled styles cursor: not-allowed this will serve as
            another visual indicator for mouse users.
          </Text>
          <Text paragraph>
            Note: We don’t foresee the need for the disabled link state to be
            used on it’s own, so the legibility of this state will need to be
            reviewed in a context basis, when we look at states of pagination
            links for instance.
          </Text>
        </Design.Section>
      </Design>
      <MDXCodeblockProvider>
        <LinkMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { ReactNode } from 'react';

import { Link } from '../link/link';
import { Text } from '../text/text';

import * as styles from './table-cell.styles';

export interface TableRowLinkDataCellProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * When the header applies to multiple columns.
   */
  colSpan?: number;
  /**
   * When the header applies to multiple rows.
   **/
  rowSpan?: number;
  /**
   * Navigating user to the given link.
   */
  to: string;
  /**
   * A descriptive message for the screen readers only for the given link.
   */
  screenReaderMessage: ReactNode;
}

export const TableRowLinkDataCell = ({
  colSpan = 1,
  rowSpan = 1,
  className,
  to,
  screenReaderMessage,
}: TableRowLinkDataCellProps) => {
  return (
    <td
      colSpan={colSpan}
      rowSpan={rowSpan}
      css={styles.link}
      className={className}
    >
      <Link to={to}>
        <Text screenReaderOnly>{screenReaderMessage}</Text>
      </Link>
    </td>
  );
};

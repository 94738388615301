import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgBankIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.23 13.976v9.23h-2v-9.23h2zM19.77 13.976v9.23h-2v-9.23h2zM25.308 13.976v9.23h-2v-9.23h2zM8.692 13.976v9.23h-2v-9.23h2zM26 10.13v-.413L16 5.1 6 9.717v.412h20zm1 2H5a1 1 0 01-1-1V9.076a1 1 0 01.58-.908l11-5.077a1 1 0 01.84 0l11 5.077a1 1 0 01.58.908v2.052a1 1 0 01-1 1zM28 26.976H4v-2h24v2z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgBankIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgCloseIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.414 16l7.778-7.778-1.414-1.414L16 14.586 8.222 6.808 6.808 8.222 14.586 16l-7.778 7.778 1.414 1.414L16 17.414l7.778 7.778 1.414-1.414L17.414 16z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgCloseIcon;

import { useMemo } from 'react';
import { Theme, useTheme } from '@emotion/react';

import { ResponsiveProps, Spacing, Style } from '../../themes/types';
import { isResponsive, responsivePropsToStyles } from '../../themes/utilities';

import * as styles from './use-box.styles';

interface XAxisNotLeftRight {
  x: Spacing;
  left?: never;
  right?: never;
}

type LeftRightNotXAxis = Partial<{
  left: Spacing;
  right: Spacing;
  x: never;
}>;

interface YAxisNotTopBottom {
  y: Spacing;
  top?: never;
  bottom?: never;
}

type TopBottomNotYAxis = Partial<{
  top: Spacing;
  bottom: Spacing;
  y: never;
}>;

type SpacingObject = (XAxisNotLeftRight | LeftRightNotXAxis) &
  (YAxisNotTopBottom | TopBottomNotYAxis);

export type Padding = Spacing | SpacingObject;

export interface UseBoxStyleProps {
  /**
   * Used to keep the box in the document flow.
   * This is generally used for text; inline elements do not
   * conform to general box layout behaviour (spacing, alignment.)
   */
  inline?: boolean;
  /**
   * Hides the element but retains information for screen readers.
   */
  screenReaderOnly?: boolean;
  /**
   * The direction of items within the box.
   */
  stack?: keyof typeof styles.stack;
  /**
   * Align items inside the box according to [X,Y], e.g. ['left', 'top']
   * will align items to the left on the X axis and top of the Y axis.
   *
   * `full` will spread items along the axis with space between them.
   */
  alignItems?:
    | [
        keyof typeof styles.stackXAlignment.row,
        keyof typeof styles.stackYAlignment.row,
      ]
    | []
    | false;
  /**
   * Spacing between items inside the box.
   */
  spacing?: Spacing;
  /**
   * Spacing around items inside the box.
   * Can be a single value: 'basePos3',
   * an array of two values: ['base','baseNeg1']
   * or a responsive object: {xs: 'base', m: ['basePos1','base]}
   */
  padding?: ResponsiveProps<Padding> | Padding;
  /**
   * Gives box a rounded appearance.
   */
  rounded?: boolean;
  /**
   * Give box a 'touch area' that expands beyond the box dimensions
   * without affecting layout.
   */
  touchArea?: boolean;
  /**
   * Allow the box to fill available space of the container
   */
  expand?: boolean;
}

type PaddingDirections = 'left' | 'right' | 'top' | 'bottom';

/**
 * matches up two parameters - one an object of padding props, and the other
 * an object of padding SerializedStyles to be used by the Emotion css prop
 */
const matchPaddingPropsWithStyles = (
  props: Partial<Record<PaddingDirections | 'x' | 'y', Spacing>>,
  style: Record<PaddingDirections, Style<Spacing>>,
) => {
  const left = props.left || props.x;
  const right = props.right || props.x;
  const top = props.top || props.y;
  const bottom = props.bottom || props.y;

  return [
    left && style.left[left],
    right && style.right[right],
    top && style.top[top],
    bottom && style.bottom[bottom],
  ];
};

/**
 * gets serialized styles from padding props.
 */
export const paddingPropsToStyles = (
  paddingProps: Padding | ResponsiveProps<Padding>,
  theme: Theme,
) => {
  if (!isResponsive(paddingProps)) {
    return typeof paddingProps === 'string'
      ? styles.padding(theme)[paddingProps]
      : matchPaddingPropsWithStyles(paddingProps, {
          left: styles.paddingLeft(theme),
          right: styles.paddingRight(theme),
          top: styles.paddingTop(theme),
          bottom: styles.paddingBottom(theme),
        });
  }

  return responsivePropsToStyles(paddingProps, ([breakpoint, padding]) =>
    typeof padding === 'string'
      ? styles.paddingResponsive(theme)[breakpoint][padding]
      : matchPaddingPropsWithStyles(padding, {
          left: styles.paddingLeftResponsive(theme)[breakpoint],
          right: styles.paddingRightResponsive(theme)[breakpoint],
          top: styles.paddingTopResponsive(theme)[breakpoint],
          bottom: styles.paddingBottomResponsive(theme)[breakpoint],
        }),
  );
};

export const useBoxStyles = ({
  inline = false,
  screenReaderOnly = false,
  stack = 'column',
  alignItems = [],
  spacing,
  padding,
  rounded = false,
  touchArea = false,
  expand = false,
}: UseBoxStyleProps) => {
  const theme = useTheme();

  // We encode objects to a string here so that useMemo can do
  // an equality check on the values, then decode inside useMemo.
  const stringifiedObjectValues = JSON.stringify({ alignItems, padding });

  return useMemo(() => {
    const { alignItems, padding } = JSON.parse(stringifiedObjectValues) as Pick<
      UseBoxStyleProps,
      'alignItems' | 'padding'
    >;

    return [
      styles.box[inline ? 'inline' : alignItems ? 'default' : 'noLayout'],
      alignItems && styles.stack[stack],
      screenReaderOnly && styles.screenReaderOnly,
      alignItems &&
        alignItems[0] &&
        styles.stackXAlignment[stack][alignItems[0]],
      alignItems &&
        alignItems[1] &&
        styles.stackYAlignment[stack][alignItems[1]],
      spacing && styles.spacing(theme)[spacing],
      padding && paddingPropsToStyles(padding, theme),
      touchArea && styles.touchArea(theme),
      rounded && styles.borderRadius(theme),
      expand && styles.expand,
    ];
  }, [
    inline,
    screenReaderOnly,
    stack,
    spacing,
    rounded,
    touchArea,
    expand,
    stringifiedObjectValues,
    theme,
  ]);
};

import { css } from '@emotion/react';

const V1_FIXED_USER_MENU_WIDTH = 360;

export const dialog = css({
  background: 'transparent',
  overflow: 'visible',
  '::backdrop': {
    background: 'transparent',
  },
});

export const menu = css({
  width: V1_FIXED_USER_MENU_WIDTH,
});

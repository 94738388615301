import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Heading,
  PaginatedTable,
  TableColumns,
  TableRowLink,
} from '@shieldpay/bumblebee';
import PaginatedTableMarkdown from '@shieldpay/bumblebee/markdown/paginated-table.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

const paginationMock = {
  offset: 0,
  pageSize: 10,
  limit: 10,
  total: 35,
};

const partners = Array.from({ length: paginationMock.total }, (e, i) => ({
  id: `${i + 1}`,
  displayName: 'Business Display Name',
  fullLegalName: `${i + 1}  Registered Business Name`,
  emails: [
    {
      emailAddress: 'cyp-891@gmail.com',
    },
  ],
}));

const columns: TableColumns = [
  {
    headerName: 'Registered business name',
    field: 'fullLegalName',
  },
  {
    headerName: 'Business display name',
    field: 'displayName',
  },
  {
    headerName: 'Contact Email',
    field: 'emailAddress',
  },
];

const columnsWithBoldText: TableColumns = [
  {
    headerName: 'Registered business name',
    field: 'fullLegalName',
    cellTextVariant: 'captionMedium150',
  },
  {
    headerName: 'Business display name',
    field: 'displayName',
    cellTextVariant: 'captionMedium150',
  },
  {
    headerName: 'Contact Email',
    field: 'emailAddress',
    cellTextVariant: 'captionMedium150',
  },
];

const rows = partners.map(({ fullLegalName, displayName, id, emails }) => ({
  id,
  fullLegalName,
  displayName,
  emailAddress: emails[0].emailAddress,
}));

const rowLink: TableRowLink = {
  headerName: 'Navigate to partners details',
  generatePathFromRowLink: (row) => `partners-details/${row.id}`,
  generateScreenReaderMessageFromRowLink: (row) =>
    `Navigate to ${row.displayName}`,
};

export const PaginatedTableComponentPage = () => {
  usePageTitle({ id: 'hive.components.paginatedTable.title' });

  const [pagination, setPagination] = useState(paginationMock);

  const currentList = rows.slice(
    pagination.offset,
    pagination.offset + pagination.pageSize,
  );

  const handleOffsetChange = (offset: number) => {
    setTimeout(() => {
      setPagination({ ...pagination, offset });
    }, 500);
  };

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Heading level="3" variant="title">
          PaginatedTable
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <PaginatedTable
            caption="Partners table"
            rows={currentList}
            columns={columns}
            fallbackMessage="Fallback message"
            rowLink={rowLink}
          >
            <PaginatedTable.PaginationLegacy
              {...pagination}
              onOffsetChange={handleOffsetChange}
              paginationPageResults={({ from, to, total }) => (
                <FormattedMessage
                  id="hive.components.pagination.results"
                  values={{
                    from: new Intl.NumberFormat().format(from),
                    to: new Intl.NumberFormat().format(to),
                    total: new Intl.NumberFormat().format(total),
                  }}
                />
              )}
            />
          </PaginatedTable>
        </Box>
        <Heading level="3" variant="title">
          PaginatedTable with variant options of text in columns
        </Heading>
        <Heading level="3" variant="bodyMedium150">
          The optional `variant` prop on a `ColItem` sets the text variant on
          all the data cells for that column. It is `caption150` by default
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <PaginatedTable
            caption="Partners table"
            rows={currentList}
            columns={columnsWithBoldText}
            fallbackMessage="Fallback message"
            rowLink={rowLink}
          >
            <PaginatedTable.PaginationLegacy
              {...pagination}
              onOffsetChange={handleOffsetChange}
              paginationPageResults={({ from, to, total }) => (
                <FormattedMessage
                  id="hive.components.pagination.results"
                  values={{
                    from: new Intl.NumberFormat().format(from),
                    to: new Intl.NumberFormat().format(to),
                    total: new Intl.NumberFormat().format(total),
                  }}
                />
              )}
            />
          </PaginatedTable>
        </Box>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <PaginatedTableMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { useState } from 'react';

import { Box, Button, Modal, Text } from '@shieldpay/bumblebee';
import ModalMarkdown from '@shieldpay/bumblebee/markdown/modal.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const ModalComponentPage = () => {
  usePageTitle({ id: 'hive.components.modal.title' });

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Box stack="row" spacing="basePos3">
          <Button
            onClick={() => {
              setOpen1(!open1);
            }}
          >
            Open dismissable modal
          </Button>

          <Modal
            closeDialogText="close"
            open={open1}
            onClose={() => setOpen1(false)}
            dismissable={true}
          >
            <Modal.Title>Modal with two buttons</Modal.Title>
            <Text paragraph>
              Modal dialog body text describing what the user needs to do next
              and why they need to do it.
            </Text>
            <Modal.Actions>
              <Modal.CloseButton>Close Button</Modal.CloseButton>
              <Button onClick={() => setOpen1(false)} variant="outline">
                Secondary
              </Button>
            </Modal.Actions>
          </Modal>

          <Button
            onClick={() => {
              setOpen2(!open2);
            }}
          >
            Open undismissable modal
          </Button>

          <Modal
            closeDialogText="close"
            open={open2}
            onClose={() => setOpen2(false)}
            dismissable={false}
          >
            <Modal.Title>Modal with one button</Modal.Title>
            <Text paragraph>
              Modal dialog body text describing what the user needs to do next
              and why they need to do it.
            </Text>
            <Modal.Actions>
              <Modal.CloseButton>Close Button</Modal.CloseButton>
            </Modal.Actions>
          </Modal>
        </Box>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <ModalMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgChevronRightEndIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.801 7.001l9.191 9.207L9.8 25.4l-1.414-1.414 7.779-7.779-7.78-7.793 1.416-1.413zM23 25.333h-2V7.193h2v18.14z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgChevronRightEndIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgProfileIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15.3a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 2a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM9.377 24.783A10.943 10.943 0 0016 27c2.488 0 4.78-.824 6.623-2.216a6.978 6.978 0 00-13.246 0zm15.479.734a8.976 8.976 0 00-17.712 0A12.954 12.954 0 0016 29c3.422 0 6.535-1.322 8.856-3.483z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 27c6.075 0 11-4.925 11-11S22.075 5 16 5 5 9.925 5 16s4.925 11 11 11zm0 2c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgProfileIcon;

import { responsiveStyle, themeifyStyle } from '../../themes/utilities';

export const link = themeifyStyle(({ palette }) => ({
  color: palette.neutral000,
}));

export const icon = themeifyStyle((theme) =>
  responsiveStyle(
    // TODO breakpoint show/hide props
    [
      {
        breakpoint: 'xs',
        styles: {
          display: 'block',
        },
      },
      {
        breakpoint: 'xxl',
        styles: {
          display: 'none',
        },
      },
    ],
    theme,
  ),
);

export const logo = themeifyStyle((theme) =>
  responsiveStyle(
    // TODO breakpoint show/hide props
    [
      {
        breakpoint: 'xs',
        styles: {
          display: 'none',
        },
      },
      {
        breakpoint: 'xxl',
        styles: {
          display: 'block',
        },
      },
    ],
    theme,
  ),
);

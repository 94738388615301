import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgCopyIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 1.851c-.472-.038-1.076-.038-1.925-.038H4.625a.563.563 0 010-1.125h5.35c.819 0 1.468 0 1.991.042.535.044.988.135 1.401.346.67.341 1.216.886 1.557 1.557.21.413.302.866.346 1.4.043.524.043 1.173.043 1.992v5.35a.562.562 0 11-1.126 0V6.05c0-.85 0-1.453-.038-1.925-.038-.465-.11-.753-.227-.982a2.438 2.438 0 00-1.065-1.065c-.229-.116-.517-.188-.982-.226zM3.627 3.313h6.12c.401 0 .736 0 1.009.022.285.023.552.073.805.202.388.198.704.514.902.902.129.253.179.52.202.805.023.273.023.608.023 1.008v6.12c0 .401 0 .736-.023 1.009-.023.285-.073.552-.202.805a2.063 2.063 0 01-.902.902c-.253.129-.52.179-.805.202-.273.023-.607.023-1.008.023h-6.12c-.401 0-.736 0-1.009-.023-.285-.023-.552-.073-.805-.202a2.063 2.063 0 01-.902-.902c-.129-.253-.179-.52-.202-.805a13.37 13.37 0 01-.022-1.008v-6.12c0-.401 0-.736.022-1.009.023-.285.073-.552.202-.805.198-.388.514-.704.902-.902.253-.129.52-.179.805-.202.273-.023.608-.023 1.008-.022zm-.916 1.143c-.216.018-.319.05-.387.084a.937.937 0 00-.41.41c-.034.067-.065.17-.083.386-.018.222-.018.51-.018.939v6.075c0 .43 0 .718.018.94.018.215.05.318.084.386.09.176.233.32.41.41.067.034.17.065.386.083.222.018.51.018.939.018h6.075c.43 0 .718 0 .94-.018.215-.018.318-.05.386-.084a.938.938 0 00.41-.41c.034-.067.065-.17.083-.386.018-.221.018-.51.018-.939V6.275c0-.43 0-.717-.018-.94-.018-.215-.05-.318-.084-.386a.938.938 0 00-.41-.41c-.067-.034-.17-.065-.386-.083-.221-.018-.51-.019-.939-.019H3.65c-.43 0-.717 0-.94.02z"
        fill="#97A1B4"
      />
    </svg>,
  );
};

export default SvgCopyIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgChevronUpIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.015 11.007l.707-.707-.707-.706-.707.706.707.707zm-.707.707l8.485 8.486 1.414-1.415-8.485-8.485-1.414 1.414zm0-1.414l-8.5 8.485L8.221 20.2l8.5-8.485-1.413-1.415z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgChevronUpIcon;

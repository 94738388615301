import { ElementType } from 'react';

import { useBumblebeeContext } from '../bumblebee-provider/bumblebee-provider';

import * as styles from './navigation-logo.styles';

interface NavigationLogoProps {
  to: string;
  icon: ElementType;
  logo: ElementType;
}

/**
 * MVP solution for switching between two white coloured logos at different
 * breakpoints which are wrapped in a link. I expect us to completely replace this
 * ASAP once we decide on a good technique to switch components based on screen size.
 */
export const NavigationLogo = ({
  to,
  icon: Icon,
  logo: Logo,
}: NavigationLogoProps) => {
  const { NavLink } = useBumblebeeContext();

  return (
    <NavLink css={styles.link} to={to}>
      <Logo css={styles.logo} />
      <Icon css={styles.icon} />
    </NavLink>
  );
};

import { ReactNode, useEffect, useState } from 'react';
import { animated, useSpring, UseSpringProps } from 'react-spring';
import { useMeasure } from 'react-use';

import * as styles from './grand-entrance.styles';

interface GrandEntranceProps {
  show: boolean;
  children: ReactNode;
  className?: string;
  config?: UseSpringProps['config'];
  fadeIn?: boolean;
  animateHeight?: boolean;
}

export const TEST_IDS = {
  CONTAINER: 'grand-entrance--container',
};

export const cubicOutEasing = (t: number): number => --t * t * t + 1;

const DEFAULT_CONFIG = {
  duration: 250,
  easing: cubicOutEasing,
};

export const GrandEntrance = ({
  children,
  show,
  config = DEFAULT_CONFIG,
  fadeIn = true,
  animateHeight = true,
  className,
}: GrandEntranceProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [contentHeight, setContentHeight] = useState(animateHeight ? 0 : '');
  const [isAnimating, setIsAnimating] = useState(false);

  /* istanbul ignore next */
  useEffect(() => {
    if (!animateHeight) return;
    setContentHeight(height);

    const resizeHandler = () => setContentHeight(height);

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [height, animateHeight]);

  const animatedStyles = useSpring({
    config,
    height: animateHeight ? (show ? contentHeight : 0) : undefined,
    opacity: show ? 1 : fadeIn ? 0 : 1,
    onStart: () => setIsAnimating(true),
    onRest: () => setIsAnimating(false),
  });

  return (
    <animated.div
      css={isAnimating ? styles.animating : !show && styles.hide}
      className={className}
      style={animatedStyles}
      data-testid={TEST_IDS.CONTAINER}
    >
      <div ref={animateHeight ? ref : undefined}>{children}</div>
    </animated.div>
  );
};

import { css } from '@emotion/react';

import { focusRingStyle, themeifyStyle } from '../../themes/utilities';

export const highlightedItem = themeifyStyle(({ palette }) => ({
  background: palette.primary200,
}));

export const button = themeifyStyle((theme) => ({
  background: theme.palette.neutral000,
  ':focus': focusRingStyle(theme).style,
}));

export const menu = themeifyStyle((theme) => ({
  ':focus-visible': focusRingStyle(theme).style,
  overflow: 'auto',
}));

export const dropdown = css({
  zIndex: 3, // TODO - Update when z-index system has been discussed. Currently 3 is highest in app
});

import { CSSObject } from '@emotion/react';

import { responsiveStyle, themeifyStyle } from '../../themes/utilities';

interface ProgressVendorAwareStyles {
  bar: CSSObject;
  value: CSSObject;
}

const progressVendorAwareStyles = ({
  bar,
  value,
}: ProgressVendorAwareStyles) => ({
  ...bar,
  '::-webkit-progress-bar': bar,
  // CSS selector list doesn't work here (webkit styles won't apply for some reason)
  // probably an Emotion bug
  '::-webkit-progress-value': value,
  '::-moz-progress-bar': value,
});

export const progressIndicator = themeifyStyle((theme) =>
  responsiveStyle(
    [
      {
        breakpoint: 'xs',
        styles: {
          appearance: 'none',
          width: 'auto', // reset UA styling
          height: theme.spacing.baseNeg3,
          border: 'none',
          ...progressVendorAwareStyles({
            bar: {
              borderRadius: 0,
            },
            value: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: theme.spacing.baseNeg5,
              borderBottomRightRadius: theme.spacing.baseNeg5,
              borderBottomLeftRadius: 0,
            },
          }),
        },
      },
      {
        breakpoint: 's',
        styles: {
          height: theme.spacing.baseNeg4,
          ...progressVendorAwareStyles({
            bar: {
              borderRadius: theme.spacing.baseNeg4,
            },
            value: {
              borderRadius: theme.spacing.baseNeg4,
            },
          }),
        },
      },
    ],
    theme,
  ),
);

export const variant = {
  primary: themeifyStyle(({ palette }) => ({
    ...progressVendorAwareStyles({
      bar: {
        backgroundColor: palette.primary200,
      },
      value: {
        backgroundColor: palette.primary400,
      },
    }),
  })),
};

import { ReactNode } from 'react';

import { Text } from '../text/text';

import * as styles from './table-cell.styles';

export interface TableHeaderCellProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Child text/nodes.
   */
  children: ReactNode;
  /**
   * When the header applies to multiple columns.
   */
  colSpan?: number;
  /**
   * When the header applies to multiple rows.
   **/
  rowSpan?: number;
  /**
   * To help screen readers interpret tables.
   * In the header row, the col value for scope associates each header cell with the data cells in the column.
   * In the header column, the row value associates the individual headers with their rows.
   */
  scope?: 'row' | 'col';
  /**
   * Aligning value based on the value type. E.g. Left align text values and right align number values.
   */
  align?: keyof typeof styles.textAlign;
  /**
   * Adds extra padding on cells which are either first and last child of a row.
   * This is true by default. Setting to false will be needed for tables with cells that
   * expand over multiple rows to prevent misalignment due to the use of selectors
   * :first-of-type and :last-child used to add extra padding on table left and right
   */
  indent?: boolean;
}

export const TableHeaderCell = ({
  children,
  colSpan = 1,
  rowSpan = 1,
  scope = 'col',
  align = 'left',
  className,
  indent = true,
}: TableHeaderCellProps) => (
  <th
    colSpan={colSpan}
    rowSpan={rowSpan}
    scope={scope}
    css={[styles.base, indent && styles.indent, styles.textAlign[align]]}
    className={className}
  >
    <Text variant="captionMedium150">{children}</Text>
  </th>
);

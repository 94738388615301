import { Box, Heading, List, Text } from '@shieldpay/bumblebee';
import {
  ChevronRightIcon,
  CloseIcon,
  EmptyCheckIcon,
  FilledCheckIcon,
} from '@shieldpay/bumblebee/icons';
import Monzo from '@shieldpay/bumblebee/images/bank-monzo.svg';
import DocumentUpload from '@shieldpay/bumblebee/images/document-upload.svg';
import ListMarkdown from '@shieldpay/bumblebee/markdown/list.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const ListComponentPage = () => {
  usePageTitle({ id: 'hive.components.list.title' });

  return (
    <>
      <ContentsTable />

      <ComponentPreview alignItems={['left', 'top']}>
        <Box css={{ width: '440px' }} spacing="basePos5">
          <Box>
            <Heading variant="headline2">Default</Heading>
            <List>
              <List.Item label="Label">Text Example</List.Item>
              <List.Item
                label="Label with is rather rather rather rather rather rather rather rather rather rather rather rather rather rather long"
                RightIcon={<ChevronRightIcon />}
              >
                Text Example with is rather rather rather rather rather rather
                rather rather rather rather rather rather rather rather long
              </List.Item>
              <List.Item LeftIcon={<EmptyCheckIcon />} label="Label">
                Text Example
              </List.Item>
            </List>
          </Box>
          <Box>
            <Heading variant="headline2">Display</Heading>
            <List>
              <List.Item
                LeftIcon={
                  <img
                    src={Monzo}
                    alt="Monzo Bank Logo"
                    width="40px"
                    height="40px"
                  />
                }
                variant="display"
                label="Label"
              >
                Text Example
              </List.Item>

              <List.Item
                LeftIcon={
                  <img
                    src={Monzo}
                    alt="Monzo Bank Logo"
                    width="40px"
                    height="40px"
                  />
                }
                variant="display"
                RightIcon={<ChevronRightIcon color="neutral250" />}
                label="Label with is rather rather rather rather rather rather rather rather long"
              >
                <Text variant="body">
                  Text Example with is rather rather rather rather long
                </Text>
              </List.Item>

              <List.Item
                LeftIcon={
                  <img
                    src={DocumentUpload}
                    alt="Monzo Bank Logo"
                    width="40px"
                    height="40px"
                  />
                }
                RightIcon={<CloseIcon color="neutral150" />}
                variant="display"
              >
                Document title
              </List.Item>
            </List>
          </Box>
          <Box>
            <Heading variant="headline2">Timeline</Heading>
            <List>
              <List.Item
                LeftIcon={<FilledCheckIcon />}
                variant="timeline"
                position="first"
              >
                <Text variant="body150">Item 1</Text>
              </List.Item>

              <List.Item LeftIcon={<EmptyCheckIcon />} variant="timeline">
                <Text variant="captionMedium">Label</Text>
                <Text variant="body150">Item 2</Text>
              </List.Item>
              <List.Item
                LeftIcon={<EmptyCheckIcon size="xl" color="success500" />}
                variant="timeline"
                RightIcon={<ChevronRightIcon color="success500" />}
              >
                <Text variant="captionMedium">Label</Text>
                <Text variant="body150">
                  Item 3 Lists are a continuous group of text or images. They
                  are composed of items which are represented by icons and text.
                  The list item component is used to display a list of user
                  details. Item 3 Lists are a continuous group of text or
                  images. They are composed of items which are represented by
                  icons and text. The list item component is used to display a
                  list of user details.
                </Text>
              </List.Item>
              <List.Item LeftIcon={<FilledCheckIcon />} variant="timeline">
                <Text variant="body150">Item 1</Text>
              </List.Item>

              <List.Item LeftIcon={<EmptyCheckIcon />} variant="timeline">
                <Text variant="captionMedium">Label</Text>
                <Text variant="body150">
                  Item 3 Lists are a continuous group of text or images. They
                  are composed of items which are represented by icons and text.
                  The list item component is used to display a list of user
                  details.
                </Text>
              </List.Item>
              <List.Item
                LeftIcon={<EmptyCheckIcon />}
                variant="timeline"
                position="last"
              >
                <Text variant="body150">
                  Item 3 Lists are a continuous group of text or images. They
                  are composed of items which are represented by icons and text.
                  The list item component is used to display a list of user
                  details.
                </Text>
              </List.Item>
            </List>
          </Box>
        </Box>
      </ComponentPreview>
      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            Lists are a continuous group of text or images. They are composed of
            items which are represented by icons and text. The list item
            component is used to display a list of user details.
          </Text>
        </Design.Section>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            The anatomy depends of the list item variant. There are 3 variants:
            default, display and timeline.
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            This component sits in the review page on the onboarding flow and
            also as a way of displaying content inside the drawers.
          </Text>
        </Design.Section>

        <Design.Section heading="Accessibility">
          <Text paragraph>
            The ‘Text’ style colour used on entered text in the field, is
            comfortably within the AAA contrast ratio for legibility.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <ListMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, Heading, UseBoxStyleProps } from '@shieldpay/bumblebee';

import { CONTENT_IDS } from '../contents-table/contents-table';

export interface ComponentPreviewProps
  extends Pick<UseBoxStyleProps, 'alignItems' | 'spacing' | 'padding'> {
  children: ReactNode;
}

export const ComponentPreview = ({
  children,
  alignItems = [],
  spacing = 'base',
  padding,
}: ComponentPreviewProps) => {
  return (
    <>
      <Heading level="2" id={CONTENT_IDS.LIVE_DEMO} variant="headline2">
        <FormattedMessage id="hive.titles.liveDemo" />
      </Heading>
      <Card
        css={{
          position: 'relative', // To contain absolutely positioned components, e.g. TopBar
        }}
        alignItems={alignItems}
        spacing={spacing}
        padding={padding}
      >
        {children}
      </Card>
    </>
  );
};

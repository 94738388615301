import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgChevronDownIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.206 14.2L16 23.392 6.808 14.2l1.414-1.414L16 20.564l7.793-7.779 1.412 1.415z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgChevronDownIcon;

import { animated, SpringValue } from 'react-spring';

import { Card } from '../card/card';
import { Text } from '../text/text';

import { ToastContent } from './types';
import { isDecoratedToastContent } from './utilities';

import * as styles from './notification.styles';

interface NotificationProps {
  children: ToastContent;
  style: Record<string, SpringValue>;
}

export const Notification = ({ children, style }: NotificationProps) => {
  const { text, icon } = isDecoratedToastContent(children)
    ? children
    : { text: children, icon: null };

  return (
    <Card
      css={[
        styles.notification,
        !isDecoratedToastContent(children) && styles.noIcon,
      ]}
      component={animated.div}
      role="alert"
      spacing="baseNeg3"
      padding={{ x: 'basePos3', y: 'basePos1' }}
      stack="row"
      style={style}
      variant="darkShadow"
      background="neutral150"
    >
      {icon}
      <Text paragraph>{text}</Text>
    </Card>
  );
};

import { Box, Text } from '@shieldpay/bumblebee';
import TextMarkdown from '@shieldpay/bumblebee/markdown/text.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const TextComponentPage = () => {
  usePageTitle({ id: 'hive.components.text.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Box spacing="basePos5" alignItems={['left', 'top']}>
          <Text paragraph variant="bodyMedium150">
            bodyMedium150: That’s what it said on ‘Ask Jeeves’. He’ll want to
            use your yacht, and I don’t want this thing smelling like fish. Now,
            when you do this without getting punched in the chest, you’ll have
            more fun. I don’t understand the question, and I won’t respond to
            it.
          </Text>
          <Text paragraph variant="body">
            body: That’s what it said on ’Ask Jeeves.’ He’ll want to use your
            yacht, and I don’t want this thing smelling like fish. Now, when you
            do this without getting punched in the chest, you’ll have more fun.
            I don’t understand the question, and I won’t respond to it.
          </Text>
          <Text paragraph variant="section">
            section: I don’t understand the question, and I won’t respond to it.
            I’m a monster. There’s so many poorly chosen words in that sentence.
            I’m a monster. Get me a vodka rocks. And a piece of toast. That’s
            why you always leave a note!
          </Text>
          <Text paragraph variant="title">
            title: Not tricks, Michael, illusions. No, I did not kill Kitty.
            However, I am going to oblige and answer the nice officer’s
            questions because I am an honest man with no secrets to hide. I’m a
            monster. Not tricks, Michael, illusions.
          </Text>
          <Text paragraph variant="inherit">
            inherit: I care deeply for nature. Oh, you’re gonna be in a coma,
            all right. Say goodbye to these, because it’s the last time! Now,
            when you do this without getting punched in the chest, you’ll have
            more fun. I’m afraid I just blue myself.
          </Text>
          <Text expand align="left">
            Align: left
          </Text>
          <Text expand align="center">
            Align: center
          </Text>
          <Text expand align="right">
            Align: right
          </Text>
        </Box>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <TextMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { Heading, Text } from '@shieldpay/bumblebee';
import { usePageTitle } from '@shieldpay/utilities-ui';

export const Home = () => {
  usePageTitle({ id: 'hive.titles.bumblebee' });

  return (
    <>
      <Heading>Introduction</Heading>
      <Text paragraph>The library is open</Text>
    </>
  );
};

import { Box } from '../box/box';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';

import {
  CheckboxItem,
  RadioButtonItem,
  ValuesItem,
} from './choice-list-components';

interface RadioButtonVariant<Value> {
  variant: 'radiobutton';
  value?: Value | null;
  onChange: (value: Value) => void;
}

interface CheckboxVariant<Value> {
  variant: 'checkbox';
  value?: Array<Value> | null;
  onChange: (values: Array<Value>) => void;
}

export type ChoiceListProps<Value> = FieldContainerBaseProps &
  (RadioButtonVariant<Value> | CheckboxVariant<Value>) & {
    name: string;
    options: Array<ValuesItem<Value>>;
  };

export const ChoiceList = <Value extends string>({
  value,
  name,
  options,
  onChange,
  label,
  helpText,
  disabled = false,
  id,
  validation,
  variant,
  showValidation = false,
  showRequiredIndicator = false,
}: ChoiceListProps<Value>) => {
  return (
    <FieldContainer
      variant="fieldGroup"
      id={id}
      label={label}
      labelVariant="body150"
      validation={validation}
      showValidation={showValidation}
      disabled={disabled}
      showRequiredIndicator={showRequiredIndicator}
      helpText={helpText}
    >
      <Box alignItems={['full', 'top']} spacing="baseNeg1">
        {options.map(({ label, value: option }, index) => {
          const sharedProps = {
            disabled,
            id: `${id}-${option}`,
            name,
            label,
          };

          return variant === 'checkbox' ? (
            <CheckboxItem<Value>
              {...sharedProps}
              listValues={value || []} // short-circuit to empty array in case Blurr provides a null value
              key={sharedProps.id}
              onChange={onChange}
              value={option}
            />
          ) : (
            <RadioButtonItem<Value>
              {...sharedProps}
              checked={value === option}
              key={index}
              onChange={onChange}
              value={option}
            />
          );
        })}
      </Box>
    </FieldContainer>
  );
};

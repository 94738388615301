import { Box } from '../box/box';
import { UserMenu, UserMenuProps } from '../user-menu/user-menu';
import {
  UserMenuLegacy,
  UserMenuLegacyProps,
} from '../user-menu/user-menu-legacy';

import * as styles from './top-bar.styles';

export type TopBarProps = UserMenuLegacyProps | UserMenuProps;

export const TopBar = ({
  email,
  group,
  logOutText,
  logOutCallback,
  logOutHref,
}: TopBarProps) => (
  <Box
    css={styles.topBar}
    alignItems={['center', 'center']}
    padding={{ x: 'basePos4', y: 'basePos3' }}
    spacing="baseNeg2"
    role="complementary"
  >
    <Box
      alignItems={['right', 'center']}
      css={styles.inner}
      stack="row"
      spacing="baseNeg2"
    >
      {logOutHref ? (
        <UserMenu
          group={group}
          logOutText={logOutText}
          logOutHref={logOutHref}
          email={email}
        />
      ) : logOutCallback ? (
        <UserMenuLegacy
          group={group}
          logOutText={logOutText}
          logOutCallback={logOutCallback}
          email={email}
        />
      ) : null}
    </Box>
  </Box>
);

import { themeifyStyle } from '../../themes/utilities';

export const variant = {
  info: themeifyStyle(({ palette }) => ({
    background: palette.info200,
  })),
  error: themeifyStyle(({ palette }) => ({
    background: palette.alert200,
  })),
};

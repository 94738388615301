import { memo, ReactNode } from 'react';
import { Interpolation, Theme } from '@emotion/react';

import {
  CheckboxControl,
  CheckboxControlProps,
} from '../checkbox/checkbox-control';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';

export interface CheckboxProps
  extends Omit<FieldContainerBaseProps, 'label'>,
    Omit<CheckboxControlProps, 'checked' | 'onClick' | 'value'> {
  onChange: (value: boolean) => void;
  onRemove?: () => void;
  className?: string;
  extraContainerStyles?: Interpolation<Theme>;
  value?: boolean;
  'data-testid'?: string;
  children?: ReactNode;
  validating?: boolean;
}

/**
 * Do not use the CheckboxWithoutMemo export in the platform, use the Checkbox export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const CheckboxWithoutMemo = ({
  onChange,
  label,
  helpText,
  value,
  disabled = false,
  id,
  validation,
  showValidation = false,
  showRequiredIndicator = false,
}: CheckboxProps) => {
  return (
    <FieldContainer
      id={id}
      validation={validation}
      showValidation={showValidation}
      disabled={disabled}
      showRequiredIndicator={showRequiredIndicator}
      helpText={helpText}
    >
      <CheckboxControl
        id={id}
        label={label}
        checked={value}
        disabled={disabled}
        onClick={() => onChange(!value)}
      />
    </FieldContainer>
  );
};

// Manually reset the displayName in the component tree.
CheckboxWithoutMemo.displayName = 'Checkbox';

export const Checkbox = memo(CheckboxWithoutMemo);

import { css } from '@emotion/react';

import {
  createThemedStyleObjects,
  focusRingStyle,
  themeifyStyle,
} from '../../themes/utilities';

export const button = themeifyStyle((theme) => ({
  border: `${theme.spacing.baseNeg8}px solid transparent`,
  borderRadius: `${theme.borderRadius}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  gap: `${theme.spacing.base}px`,
  height: 'min-content',
  transition: `background-color ${theme.transitions.standard}, ${
    focusRingStyle(theme).transition
  }`,
  whiteSpace: 'nowrap',
  ':hover': {
    cursor: 'pointer',
  },
  ':focus': {
    ...focusRingStyle(theme).style,
  },
  ':disabled': {
    cursor: 'default',
  },
}));

export const [disabled] = createThemedStyleObjects((theme) => ({
  solid: {
    '&, :hover, :focus': {
      backgroundColor: theme.palette.primary400,
    },
  },
  outline: {
    '&, :hover, :focus': {
      background: 'transparent',
      borderColor: theme.palette.primary400,
      color: theme.palette.primary400,
    },
  },
  icon: {
    '&, :hover, :focus': { color: theme.palette.primary400 },
  },
}));

export const [variant] = createThemedStyleObjects((theme) => ({
  solid: {
    color: theme.palette.neutral000,
    backgroundColor: theme.palette.primary500,
    ':hover': {
      backgroundColor: theme.palette.primary600,
    },
    ':focus': {
      backgroundColor: theme.palette.primary600,
    },
  },
  outline: {
    background: 'transparent',
    borderColor: theme.palette.primary500,
    color: theme.palette.primary500,
    ':hover': {
      color: theme.palette.primary600,
      backgroundColor: theme.palette.primary200,
    },
    ':focus': {
      backgroundColor: theme.palette.primary200,
    },
  },
  icon: {
    background: 'none',
    width: 'max-content',
    padding: 0,
    border: '0 none',
    outline: '0 none',
    color: 'currentColor',
    gap: 0, // allows us to use screenReader text without extra width being added via default gap
    ':hover, :focus': {
      background: 'none',
    },
  },
}));

export const buttonAsLinkResets = css({
  textTransform: 'inherit',
  font: 'inherit',
  margin: 0,
  background: 'none',
  padding: 0,
  border: '0 none',
  textAlign: 'left',
  ':hover': {
    cursor: 'pointer',
    background: 'none',
  },
  ':focus': {
    background: 'none',
  },
});

import { useTheme } from '@emotion/react';

import { Box, Card, Heading, Table, Text } from '@shieldpay/bumblebee';
import {
  AddPersonIcon,
  AlertCircleIcon,
  AlertIcon,
  AlertsIcon,
  BankIcon,
  BankWithBgIcon,
  BumblebeeIcon,
  BurgerIcon,
  BusinessIcon,
  CardIcon,
  ChevronDownIcon,
  ChevronLeftEndIcon,
  ChevronLeftIcon,
  ChevronRightBreadcrumbIcon,
  ChevronRightEndIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
  CopyIcon,
  CorrectIcon,
  DeleteIcon,
  DocumentIcon,
  EditIcon,
  EmptyCheck,
  EmptyCheckIcon,
  FilledCheckIcon,
  HideIcon,
  IncorrectIcon,
  InfoIcon,
  KebabIcon,
  LogOutIcon,
  MeatballsIcon,
  MinusCircleIcon,
  PersonIcon,
  ProfileIcon,
  QuestionIcon,
  SafetyIcon,
  SearchIcon,
  ShowIcon,
  TickIcon,
  VirtualCardIcon,
} from '@shieldpay/bumblebee/icons';
import Bank from '@shieldpay/bumblebee/images/bank.svg';
import Atom from '@shieldpay/bumblebee/images/bank-atom.svg';
import BankOfScotland from '@shieldpay/bumblebee/images/bank-bank-of-scotland.svg';
import Barclays from '@shieldpay/bumblebee/images/bank-barclays.svg';
import Halifax from '@shieldpay/bumblebee/images/bank-halifax.svg';
import Hsbc from '@shieldpay/bumblebee/images/bank-hsbc.svg';
import Lloyds from '@shieldpay/bumblebee/images/bank-lloyds.svg';
import Metro from '@shieldpay/bumblebee/images/bank-metro.svg';
import Monzo from '@shieldpay/bumblebee/images/bank-monzo.svg';
import N26 from '@shieldpay/bumblebee/images/bank-n26.svg';
import Nationwide from '@shieldpay/bumblebee/images/bank-nationwide.svg';
import Natwest from '@shieldpay/bumblebee/images/bank-natwest.svg';
import BankPlaceholderSrc from '@shieldpay/bumblebee/images/bank-placeholder.svg';
import Rbs from '@shieldpay/bumblebee/images/bank-rbs.svg';
import Revolut from '@shieldpay/bumblebee/images/bank-revolut.svg';
import Santander from '@shieldpay/bumblebee/images/bank-santander.svg';
import StandardCharteredBank from '@shieldpay/bumblebee/images/bank-standard-chartered-bank.svg';
import Starling from '@shieldpay/bumblebee/images/bank-starling.svg';
import Tandem from '@shieldpay/bumblebee/images/bank-tandem.svg';
import Transferwise from '@shieldpay/bumblebee/images/bank-transferwise.svg';
import Tsb from '@shieldpay/bumblebee/images/bank-tsb.svg';
import Virgin from '@shieldpay/bumblebee/images/bank-virgin.svg';
import DirectID from '@shieldpay/bumblebee/images/directId.svg';
import Identity from '@shieldpay/bumblebee/images/identity.svg';
import Money from '@shieldpay/bumblebee/images/money.svg';
import IconMarkdown from '@shieldpay/bumblebee/markdown/icon.md';
import ImageMarkdown from '@shieldpay/bumblebee/markdown/image.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

const images = [
  { name: 'Bank', src: Bank },
  { name: 'Identity', src: Identity },
  { name: 'Money', src: Money },
  { name: 'DirectID', src: DirectID },
  { name: 'Atom', src: Atom },
  { name: 'BankOfScotland', src: BankOfScotland },
  { name: 'Barclays', src: Barclays },
  { name: 'Halifax', src: Halifax },
  { name: 'Hsbc', src: Hsbc },
  { name: 'Lloyds', src: Lloyds },
  { name: 'Metro', src: Metro },
  { name: 'Monzo', src: Monzo },
  { name: 'N26', src: N26 },
  { name: 'Nationwide', src: Nationwide },
  { name: 'Natwest', src: Natwest },
  { name: 'BankPlaceholderSrc', src: BankPlaceholderSrc },
  { name: 'Rbs', src: Rbs },
  { name: 'Revolut', src: Revolut },
  { name: 'Santander', src: Santander },
  { name: 'StandardCharteredBank', src: StandardCharteredBank },
  { name: 'Starling', src: Starling },
  { name: 'Tandem', src: Tandem },
  { name: 'Transferwise', src: Transferwise },
  { name: 'Tsb', src: Tsb },
  { name: 'Virgin', src: Virgin },
];

const icons = [
  { name: 'AddIcon', component: AlertIcon },
  { name: 'AddPersonIcon', component: AddPersonIcon },
  { name: 'AlertCircleIcon', component: AlertCircleIcon },
  { name: 'AlertIcon', component: AlertIcon },
  { name: 'AlertsIcon', component: AlertsIcon },
  { name: 'BankIcon', component: BankIcon },
  { name: 'BankWithBgIcon', component: BankWithBgIcon },
  { name: 'BumblebeeIcon', component: BumblebeeIcon },
  { name: 'BurgerIcon', component: BurgerIcon },
  { name: 'BusinessIcon', component: BusinessIcon },
  { name: 'CardIcon', component: CardIcon },
  { name: 'ChevronDownIcon', component: ChevronDownIcon },
  { name: 'ChevronLeftEndIcon', component: ChevronLeftEndIcon },
  { name: 'ChevronLeftIcon', component: ChevronLeftIcon },
  { name: 'ChevronRightBreadcrumbIcon', component: ChevronRightBreadcrumbIcon },
  { name: 'ChevronRightEndIcon', component: ChevronRightEndIcon },
  { name: 'ChevronRightIcon', component: ChevronRightIcon },
  { name: 'ChevronUpIcon', component: ChevronUpIcon },
  { name: 'CloseIcon', component: CloseIcon },
  { name: 'CopyIcon', component: CopyIcon },
  { name: 'CorrectIcon', component: CorrectIcon },
  { name: 'DeleteIcon', component: DeleteIcon },
  { name: 'DocumentIcon', component: DocumentIcon },
  { name: 'EditIcon', component: EditIcon },
  { name: 'EmptyCheck', component: EmptyCheck },
  { name: 'EmptyCheckIcon', component: EmptyCheckIcon },
  { name: 'FilledCheckIcon', component: FilledCheckIcon },
  { name: 'HideIcon', component: HideIcon },
  { name: 'IncorrectIcon', component: IncorrectIcon },
  { name: 'InfoIcon', component: InfoIcon },
  { name: 'KebabIcon', component: KebabIcon },
  { name: 'LogOutIcon', component: LogOutIcon },
  { name: 'MeatballsIcon', component: MeatballsIcon },
  { name: 'MinusCircleIcon', component: MinusCircleIcon },
  { name: 'PersonIcon', component: PersonIcon },
  { name: 'ProfileIcon', component: ProfileIcon },
  { name: 'QuestionIcon', component: QuestionIcon },
  { name: 'SafetyIcon', component: SafetyIcon },
  { name: 'SearchIcon', component: SearchIcon },
  { name: 'ShowIcon', component: ShowIcon },
  { name: 'TickIcon', component: TickIcon },
  { name: 'VirtualCardIcon', component: VirtualCardIcon },
];
export const IconComponentPage = () => {
  usePageTitle({ id: 'hive.components.icon.title' });
  const theme = useTheme();

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Table caption="icon examples">
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell>Icon name</Table.HeaderCell>
              <Table.HeaderCell>xsmall</Table.HeaderCell>
              <Table.HeaderCell>small</Table.HeaderCell>
              <Table.HeaderCell>medium</Table.HeaderCell>
              <Table.HeaderCell>large</Table.HeaderCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            <Table.Row>
              <Table.DataCell>AlertIcon</Table.DataCell>
              <Table.DataCell>
                <AlertIcon size="xs" color="primary200" />
              </Table.DataCell>
              <Table.DataCell>
                <AlertIcon size="s" color="primary300" />
              </Table.DataCell>
              <Table.DataCell>
                <AlertIcon size="m" color="primary400" />
              </Table.DataCell>
              <Table.DataCell>
                <AlertIcon size="l" color="primary500" />
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>InfoIcon</Table.DataCell>
              <Table.DataCell>
                <InfoIcon size="xs" />
              </Table.DataCell>
              <Table.DataCell>
                <InfoIcon size="s" />
              </Table.DataCell>
              <Table.DataCell>
                <InfoIcon size="m" />
              </Table.DataCell>
              <Table.DataCell>
                <InfoIcon size="l" />
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>ChevronDownIcon</Table.DataCell>
              <Table.DataCell>
                <ChevronDownIcon size="xs" />
              </Table.DataCell>
              <Table.DataCell>
                <ChevronDownIcon size="s" />
              </Table.DataCell>
              <Table.DataCell>
                <ChevronDownIcon size="m" />
              </Table.DataCell>
              <Table.DataCell>
                <ChevronDownIcon size="l" />
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table caption="icon examples">
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell>Icon type</Table.HeaderCell>
              <Table.HeaderCell>error</Table.HeaderCell>
              <Table.HeaderCell>warning</Table.HeaderCell>
              <Table.HeaderCell>default</Table.HeaderCell>
              <Table.HeaderCell>info</Table.HeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>InfoIcon Type</Table.DataCell>
              <Table.DataCell>
                <InfoIcon type="error" />
              </Table.DataCell>
              <Table.DataCell>
                <InfoIcon type="warning" />
              </Table.DataCell>
              <Table.DataCell>
                <InfoIcon type="default" />
              </Table.DataCell>
              <Table.DataCell>
                <InfoIcon type="info" />
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </ComponentPreview>
      <Heading>All Bumblebee Icons</Heading>
      <Box
        stack="row"
        spacing="base"
        css={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fit, minmax(${theme.breakpoints.xs}px,  1fr))`,
        }}
      >
        {icons.map(({ name, component }) => {
          const IconComponent = component as React.ElementType;

          return (
            <Card alignItems={['center', 'center']} key={name}>
              <IconComponent />
              <Text>{name}</Text>
            </Card>
          );
        })}
      </Box>
      <Heading>All Bumblebee Images</Heading>
      <Box
        stack="row"
        spacing="base"
        css={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fit, minmax(${theme.breakpoints.xs}px,  1fr))`,
        }}
      >
        {images.map(({ name, src }) => {
          return (
            <Card alignItems={['center', 'center']} key={name}>
              <img src={src} alt={name} />
              <Text>{name}</Text>
            </Card>
          );
        })}
      </Box>

      <Heading variant="headline2">Image import example</Heading>

      <MDXCodeblockProvider>
        <ImageMarkdown />
      </MDXCodeblockProvider>

      <Heading variant="headline2">Icon import example</Heading>

      <MDXCodeblockProvider>
        <IconMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

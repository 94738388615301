import { ElementType } from 'react';

import { Box, Heading, Table, Text } from '@shieldpay/bumblebee';
import { InfoIcon } from '@shieldpay/bumblebee/icons';

export const LinkAppearance = ({ Component }: { Component: ElementType }) => (
  <>
    <Box spacing="base">
      <Heading level="3" variant="title">
        Inherited text size links
      </Heading>
      <Text paragraph variant="section">
        This is a{' '}
        <Component href="#" variant="text-underline">
          “text-underline” variant
        </Component>
        , hope you have enjoyed the show.
      </Text>
      <Text paragraph>
        This is a{' '}
        <Component href="#" variant="text-link">
          “text-link” variant
        </Component>
        , hope you have enjoyed the show.
      </Text>
      <Text paragraph>
        This is a{' '}
        <Component href="#" disabled variant="text-link">
          disabled link(text-link)
        </Component>
        , soz.
      </Text>
    </Box>
    <Box spacing="base">
      <Heading level="3" variant="title">
        Fixed size links
      </Heading>
      <Table caption="links styles">
        <Table.Head>
          <Table.Row>
            <Table.HeaderCell>Small link</Table.HeaderCell>
            <Table.HeaderCell>Medium link</Table.HeaderCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          <Table.Row>
            <Table.DataCell>
              <Component
                href="#"
                LeftIcon={InfoIcon}
                size="small"
                variant="text-with-icon"
              >
                Small link with icon on left
              </Component>
            </Table.DataCell>
            <Table.DataCell>
              <Component
                href="#"
                LeftIcon={InfoIcon}
                size="medium"
                variant="text-with-icon"
              >
                Medium link with icon on left
              </Component>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <Component
                href="#"
                RightIcon={InfoIcon}
                size="small"
                variant="text-with-icon"
              >
                Small link with icon on right
              </Component>
            </Table.DataCell>
            <Table.DataCell>
              <Component
                href="#"
                RightIcon={InfoIcon}
                size="medium"
                variant="text-with-icon"
              >
                Medium link with icon on right
              </Component>
            </Table.DataCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Box>
  </>
);

import { ReactNode } from 'react';
import { Theme } from '@emotion/react';

import { Text } from '../text/text';

import * as styles from './label.styles';

// We could just list some string literals here, but the thinking
// is that picking from an existing type will show errors quicker
export type LabelVariant = keyof Pick<
  Theme['typography']['variant'],
  'caption' | 'body150'
>;

export interface LabelProps {
  htmlFor?: string;
  children: ReactNode;
  disabled?: boolean;
  showRequiredIndicator?: boolean;
  variant?: LabelVariant;
  id?: string;
}

export const Label = ({
  htmlFor,
  children,
  id,
  disabled = false,
  showRequiredIndicator = false,
  variant = 'caption',
}: LabelProps) => (
  <Text
    component="label"
    htmlFor={htmlFor}
    id={id}
    variant={variant}
    css={[
      disabled && styles.disabled,
      showRequiredIndicator && styles.required,
    ]}
  >
    {children}
  </Text>
);

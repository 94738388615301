import { ReactNode } from 'react';

import { Text } from '../text/text';

export interface InputHelpProps {
  children?: ReactNode;
}

export const InputHelp = ({ children }: InputHelpProps) => {
  return (
    <Text component="span" variant="caption150">
      {children}
    </Text>
  );
};

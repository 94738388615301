import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgBurgerIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 17H4v-2h24v2zM28 26H4v-2h24v2zM28 8H4V6h24v2z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgBurgerIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgAlertsIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.812 22.62a.53.53 0 01-.199-.414v-8.36a8.615 8.615 0 00-17.23 0v8.36a.53.53 0 01-.2.415l-2.22 1.776a.728.728 0 00.454 1.295h21.161a.727.727 0 00.454-1.295l-2.22-1.776zm-1.717 1.072a2.53 2.53 0 01-.482-1.486v-8.36a6.615 6.615 0 10-13.23 0v8.36a2.53 2.53 0 01-.483 1.486h14.195z"
        fill="#353C4A"
      />
      <path
        d="M13.844 4.154a2.154 2.154 0 014.307 0v2.154h-4.307V4.154zM13.305 26.77h5.385v.538a2.692 2.692 0 11-5.385 0v-.539z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgAlertsIcon;

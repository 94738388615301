import { FormattedMessage } from 'react-intl';
import { MDXComponents } from 'mdx/types';
import { MDXProvider } from '@mdx-js/react';

import { Heading, Text } from '@shieldpay/bumblebee';

import { CONTENT_IDS } from '../contents-table/contents-table';

import { Codeblock } from './code-block';

export interface MDXCodeblockProviderProps {
  children: React.ReactNode;
}

/*
  MDXCodeblock provider uses this object to identify what component to map to the `code` mdx data type,
  code used in MD files will be wrapped in our Codeblock component for syntax highlighting 
  */
const components: MDXComponents = {
  code: Codeblock,
  // @ts-expect-error children is optional mismatch
  h1: (props) => <Heading variant="headline1" {...props} />,
  // @ts-expect-error children is optional mismatch
  h2: (props) => <Heading variant="headline2" {...props} />,
  // @ts-expect-error children is optional mismatch
  h3: (props) => <Heading variant="section" {...props} />,
  // @ts-expect-error children is optional mismatch
  h4: (props) => <Heading variant="title" {...props} />,
  // @ts-expect-error color prop mismatch
  p: Text,
  bold: (props) => <Text paragraph variant="bodyMedium150" {...props} />,
};

export const MDXCodeblockProvider = ({
  children,
}: MDXCodeblockProviderProps) => {
  return (
    <>
      <Heading level="2" variant="headline2" id={CONTENT_IDS.CODE}>
        <FormattedMessage id="hive.titles.code" />
      </Heading>
      <MDXProvider components={components}>{children}</MDXProvider>
    </>
  );
};

import { ReactNode } from 'react';
export interface TableBodyProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Child nodes (e.g. TableDataCell, TableHeaderCell).
   */
  children: ReactNode;
}

export const TableBody = ({ children, className }: TableBodyProps) => {
  return <tbody className={className}>{children}</tbody>;
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgDeleteIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.757 8H7.243L9.54 28h12.92l2.297-20zM7.243 6a2 2 0 00-1.987 2.228l2.297 20A2 2 0 009.54 30h12.92a2 2 0 001.987-1.772l2.297-20A2 2 0 0024.757 6H7.243z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4h-8v2h8V4zm-8-2a2 2 0 00-2 2v2a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2h-8z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 7a1 1 0 011-1h25a1 1 0 110 2h-25a1 1 0 01-1-1zM12.964 10a1 1 0 011.035.964l.5 14a1 1 0 01-1.998.072l-.5-14A1 1 0 0112.964 10zM18.464 26a1 1 0 01-.963-1.036l.5-14a1 1 0 011.998.072l-.5 14a1 1 0 01-1.035.963z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgDeleteIcon;

import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export const required = css({
  '::after': {
    content: '"*"',
  },
});

export const disabled = themeifyStyle((theme) => ({
  color: theme.palette.neutral250,
}));

import { ReactNode, TableHTMLAttributes } from 'react';

import { Table } from '../table/table';
import { CellTextVariantType } from '../table/table-data-cell';

export interface RowItem {
  id: string | number;
  /**
   * The renderable node to map up to each headerName in ColItems
   */
  [headerName: string]: ReactNode;
}

export type DataType = 'text' | 'number' | 'date' | 'dateTime' | 'boolean';
interface ColItem {
  /**
   * Representing the header/title of that table column.
   */
  headerName: ReactNode;
  /**
   * The column identifier. It's used to map with row[field] values.
   */
  field: string;
  /**
   * Aligning cell value based on the type. E.g. Left align text values and right align number values.
   */
  type?: DataType;
  /**
   * Set the text variant for this cell. This is `caption150` by default
   */
  cellTextVariant?: CellTextVariantType;
}

export interface RowLink {
  /**
   * Representing the header/title of the link column. Visually hidden but available for screen readers.
   */
  headerName: ReactNode;
  /**
   * Path to navigate on row click.
   */
  generatePathFromRowLink: (row: RowItem) => string;
  /**
   * A descriptive message for the screen readers only for the given link.
   */
  generateScreenReaderMessageFromRowLink: (row: RowItem) => ReactNode;
}

export type Columns = Array<ColItem>;

export type Rows = Array<RowItem>;

export interface DataTableProps extends TableHTMLAttributes<HTMLTableElement> {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Each object is a table row and each object key represents a table cell.
   */
  rows: Rows;
  /**
   * Each object is a table column.
   */
  columns: Columns;
  /**
   * Table heading. Visually hidden but accessible to screen readers.
   */
  caption: ReactNode;
  /**
   * Configure whether the table should have a border, defaults to 'bordered'.
   */
  variant?: 'bordered' | 'borderless';
  /**
   * Adds a visually hidden table column with path as cell value to make entire row clickable.
   */
  rowLink?: RowLink;
  'data-testid'?: string;
}

const mapDataTypeToDisplayType = (type: DataType) => {
  switch (type) {
    case 'text':
      return 'left';
    case 'number':
    case 'date':
    case 'dateTime':
      return 'right';
    default:
      return 'left';
  }
};

export const TEST_IDS = {
  TABLE: 'table-component',
};

export const DataTable = ({
  rows,
  columns,
  caption,
  className,
  rowLink,
  'data-testid': testId = TEST_IDS.TABLE,
  variant = 'bordered',
}: DataTableProps) => {
  return (
    <Table
      className={className}
      caption={caption}
      variant={variant}
      data-testid={testId}
      interactive={!!rowLink}
    >
      <Table.Head>
        <Table.Row>
          {columns.map(({ field, headerName, type = 'text' }) => (
            <Table.HeaderCell
              key={field}
              align={mapDataTypeToDisplayType(type)}
            >
              {headerName}
            </Table.HeaderCell>
          ))}
          {rowLink ? (
            <Table.RowLinkHeaderCell linkHeader={rowLink.headerName} />
          ) : null}
        </Table.Row>
      </Table.Head>
      {rows.length ? (
        <Table.Body>
          {rows.map((row) => (
            <Table.Row key={row.id}>
              {columns.map(({ field, cellTextVariant, type = 'text' }) => (
                <Table.DataCell
                  cellTextVariant={cellTextVariant}
                  key={field}
                  align={mapDataTypeToDisplayType(type)}
                >
                  {row[field]}
                </Table.DataCell>
              ))}
              {rowLink ? (
                <Table.RowLinkDataCell
                  screenReaderMessage={rowLink.generateScreenReaderMessageFromRowLink(
                    row,
                  )}
                  to={rowLink.generatePathFromRowLink(row)}
                />
              ) : null}
            </Table.Row>
          ))}
        </Table.Body>
      ) : null}
    </Table>
  );
};

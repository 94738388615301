import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgBusinessIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 5.5h-7v2h7v-2zm2 0a2 2 0 00-2-2h-7a2 2 0 00-2 2v4h11v-4z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.5v16h22v-16H5zm-1-2a1 1 0 00-1 1v18a1 1 0 001 1h24a1 1 0 001-1v-18a1 1 0 00-1-1H4z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.106 19.17L3.553 13.395l.894-1.789L16 17.383l11.553-5.777.894 1.79-11.553 5.776a2 2 0 01-1.788 0z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgBusinessIcon;

import { ReactNode, TableHTMLAttributes } from 'react';

import { Box } from '../box/box';
import { Card } from '../card/card';
import { Text } from '../text/text';

import { TableBody } from './table-body';
import { TableDataCell } from './table-data-cell';
import { TableHead } from './table-head';
import { TableHeaderCell } from './table-header-cell';
import { TableRow } from './table-row';
import { TableRowLinkDataCell } from './table-row-link-data-cell';
import { TableRowLinkHeaderCell } from './table-row-link-header-cell';

import * as styles from './table.styles';

export interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Child nodes (e.g. TableHead, TableBody).
   */
  children: ReactNode;
  /**
   * Table heading. Visually hidden but accessible to screen readers.
   */
  caption: ReactNode;
  /**
   * Borderless changes whether the component uses a box or a card as a container.
   */
  variant?: 'bordered' | 'borderless';
  /**
   * Applies hover and focus states to the row.
   */
  interactive?: boolean;
  'data-testid'?: string;
}

export const TEST_IDS = {
  TABLE: 'table-component',
};

export const Table = ({
  children,
  caption,
  'data-testid': testId = TEST_IDS.TABLE,
  className,
  variant = 'bordered',
  interactive = false,
}: TableProps) => {
  const Component = variant === 'bordered' ? Card : Box;
  return (
    <Component padding="none" data-testid={testId}>
      <table
        css={[
          styles.table,
          variant === 'bordered' && styles.tableHasBorder,
          interactive && styles.tableInteractive,
        ]}
        className={className}
      >
        <Text component="caption" screenReaderOnly>
          {caption}
        </Text>
        {children}
      </table>
    </Component>
  );
};

Table.Body = TableBody;
Table.Head = TableHead;
Table.HeaderCell = TableHeaderCell;
Table.DataCell = TableDataCell;
Table.Row = TableRow;
Table.RowLinkDataCell = TableRowLinkDataCell;
Table.RowLinkHeaderCell = TableRowLinkHeaderCell;

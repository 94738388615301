import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgIncorrectIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} r={12} stroke="#353C4A" strokeWidth={2} />
      <path
        d="M11.757 20.243l8.486-8.486M11.757 11.757l8.486 8.486"
        stroke="#353C4A"
        strokeWidth={2}
      />
    </svg>,
  );
};

export default SvgIncorrectIcon;

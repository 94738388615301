import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export const topBar = themeifyStyle(({ spacing }) => ({
  height: `${spacing.basePos7}px`,
  width: '100%',
  position: 'absolute',
  right: '0',
}));

export const inner = css({
  width: '100%',
});

import { memo, ReactNode } from 'react';

import { ValidationProps } from '@shieldpay/blurr';

import { Spacing } from '../../themes/types';
import { Box } from '../box/box';
import { Infoblock } from '../infoblock/infoblock';
import { InputHelp } from '../input-help/input-help';
import { Label, LabelVariant } from '../label/label';
import { TextList } from '../text-list/text-list';

export interface FieldContainerBaseProps {
  helpText?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  id: string;
  variant?: keyof typeof variantConfig;
  /**
   * Validation is only displayed when showValidation is true
   * in combination with validation messages. Use this prop to
   * override and force errors to be hidden or shown based on
   * external state,e.g. to hide the invalid state again once
   * a field has been interacted with
   */
  showValidation?: boolean;
  /**
   * @deprecated
   * If true, a required indicator will be visible on the label.
   */
  showRequiredIndicator?: boolean;
  labelVariant?: LabelVariant;
  validation?: ValidationProps;
}

export interface FieldContainerProps
  extends Omit<FieldContainerBaseProps, 'validation'> {
  children: ReactNode;
  validation?: ValidationProps | Array<ValidationProps | undefined>;
}

const variantConfig: Record<
  string,
  { spacing: Spacing; isFieldGroup: boolean }
> = {
  default: { spacing: 'baseNeg4', isFieldGroup: false },
  fieldGroup: { spacing: 'basePos1', isFieldGroup: true },
  cardControls: { spacing: 'basePos4', isFieldGroup: true },
};

const mergeValidation = (validation: Array<ValidationProps | undefined>) => {
  const onlyProps = validation.filter(
    (props): props is ValidationProps => props !== undefined,
  );

  return {
    valid: onlyProps.every(({ valid }) => valid) || false,
    messages: onlyProps
      .map(({ valid, messages }) => (valid ? [] : messages))
      .flat(),
  };
};

export const ValidationMessages = ({
  validation,
  showValidation,
}: Pick<FieldContainerProps, 'validation' | 'showValidation'>) => {
  if (!showValidation || !validation) return null;

  const { messages } = Array.isArray(validation)
    ? mergeValidation(validation)
    : validation;

  if (messages.length === 0) return null;

  return (
    <Infoblock variant="error">
      {messages.length === 1 ? (
        messages[0]
      ) : (
        <TextList variant="caption" color="alert500">
          {messages.map((message, index) => (
            <TextList.Item key={index}>{message}</TextList.Item>
          ))}
        </TextList>
      )}
    </Infoblock>
  );
};

export const FieldContainer = memo(function FieldContainer({
  children,
  label,
  labelVariant,
  helpText,
  id,
  variant = 'default',
  disabled = false,
  validation,
  showValidation = false,
  showRequiredIndicator = false,
}: FieldContainerProps) {
  const { isFieldGroup, spacing } = variantConfig[variant];

  const labelledBy = isFieldGroup ? `label-for-${id}` : undefined;

  return (
    <Box
      spacing={spacing}
      // Because <fieldset /> and <legend /> have some interesting display quirks
      // use role and aria-labelledby attributes to get the same semantic behaviour.
      role={isFieldGroup ? 'group' : undefined}
      aria-labelledby={labelledBy}
    >
      {label ? (
        <Label
          htmlFor={id}
          id={labelledBy}
          disabled={disabled}
          showRequiredIndicator={showRequiredIndicator}
          variant={labelVariant}
        >
          {label}
        </Label>
      ) : null}
      {children}
      {helpText ? <InputHelp>{helpText}</InputHelp> : null}
      <ValidationMessages
        validation={validation}
        showValidation={showValidation}
      />
    </Box>
  );
});

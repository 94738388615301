import { Box, Heading, Table, Text } from '@shieldpay/bumblebee';
import TableMarkdown from '@shieldpay/bumblebee/markdown/table.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const TableComponentPage = () => {
  usePageTitle({ id: 'hive.components.table.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Heading level="3" variant="title">
          Standard table with one Header
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <Table caption="Books">
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Author</Table.HeaderCell>
                <Table.HeaderCell>Genre</Table.HeaderCell>
                <Table.HeaderCell align="right">Published</Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.DataCell>A Wizard of Earthsea</Table.DataCell>
                <Table.DataCell>Ursula K. Le Guin</Table.DataCell>
                <Table.DataCell>Fantasy</Table.DataCell>
                <Table.DataCell align="right">1968</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>{`Pandora's Star`}</Table.DataCell>
                <Table.DataCell>Peter F. Hamilton</Table.DataCell>
                <Table.DataCell>Science fiction</Table.DataCell>
                <Table.DataCell align="right">2004</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>Good Omens</Table.DataCell>
                <Table.DataCell>Terry Pratchett - Neil Gaiman</Table.DataCell>
                <Table.DataCell>Fantasy</Table.DataCell>
                <Table.DataCell align="right">1990</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>The Diary of a Madman</Table.DataCell>
                <Table.DataCell>Gogol</Table.DataCell>
                <Table.DataCell>Short stories</Table.DataCell>
                <Table.DataCell align="right">1835</Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>

        <Heading level="3" variant="title">
          Standard table with bold text in last column
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <Table caption="Books">
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Author</Table.HeaderCell>
                <Table.HeaderCell>Genre</Table.HeaderCell>
                <Table.HeaderCell align="right">Published</Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.DataCell>A Wizard of Earthsea</Table.DataCell>
                <Table.DataCell>Ursula K. Le Guin</Table.DataCell>
                <Table.DataCell>Fantasy</Table.DataCell>
                <Table.DataCell
                  cellTextVariant="captionMedium150"
                  align="right"
                >
                  1968
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>{`Pandora's Star`}</Table.DataCell>
                <Table.DataCell>Peter F. Hamilton</Table.DataCell>
                <Table.DataCell>Science fiction</Table.DataCell>
                <Table.DataCell
                  cellTextVariant="captionMedium150"
                  align="right"
                >
                  2004
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>Good Omens</Table.DataCell>
                <Table.DataCell>Terry Pratchett - Neil Gaiman</Table.DataCell>
                <Table.DataCell>Fantasy</Table.DataCell>
                <Table.DataCell
                  cellTextVariant="captionMedium150"
                  align="right"
                >
                  1990
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>The Diary of a Madman</Table.DataCell>
                <Table.DataCell>Gogol</Table.DataCell>
                <Table.DataCell>Short stories</Table.DataCell>
                <Table.DataCell
                  cellTextVariant="captionMedium150"
                  align="right"
                >
                  1835
                </Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>

        <Heading level="3" variant="title">
          Interactive Table row
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <Table caption="Interactive table" interactive>
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Author</Table.HeaderCell>
                <Table.RowLinkHeaderCell linkHeader="Link to book details" />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.DataCell>A Wizard of Earthsea</Table.DataCell>
                <Table.DataCell>Ursula K. Le Guin</Table.DataCell>
                <Table.RowLinkDataCell
                  to="details/1"
                  screenReaderMessage="A Wizard of Earthsea details"
                />
              </Table.Row>
              <Table.Row>
                <Table.DataCell>Good Omens</Table.DataCell>
                <Table.DataCell>Terry Pratchett - Neil Gaiman</Table.DataCell>
                <Table.RowLinkDataCell
                  to="details/2"
                  screenReaderMessage="Good Omens details"
                />
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>

        <Heading level="3" variant="title">
          Table with two Headers
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <Table caption="Delivery slots">
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Monday</Table.HeaderCell>
                <Table.HeaderCell>Tuesday</Table.HeaderCell>
                <Table.HeaderCell>Wednesday</Table.HeaderCell>
                <Table.HeaderCell>Thursday</Table.HeaderCell>
                <Table.HeaderCell>Friday</Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell scope="row">09.00 - 11.00</Table.HeaderCell>
                <Table.DataCell>Open</Table.DataCell>
                <Table.DataCell>Closed</Table.DataCell>
                <Table.DataCell>Closed</Table.DataCell>
                <Table.DataCell>Open</Table.DataCell>
                <Table.DataCell>Open</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell scope="row">09.00 - 11.00</Table.HeaderCell>
                <Table.DataCell>Open</Table.DataCell>
                <Table.DataCell>Closed</Table.DataCell>
                <Table.DataCell>Closed</Table.DataCell>
                <Table.DataCell>Open</Table.DataCell>
                <Table.DataCell>Open</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell scope="row">09.00 - 11.00</Table.HeaderCell>
                <Table.DataCell>Open</Table.DataCell>
                <Table.DataCell>Closed</Table.DataCell>
                <Table.DataCell>Closed</Table.DataCell>
                <Table.DataCell>Open</Table.DataCell>
                <Table.DataCell>Open</Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>

        <Heading level="3" variant="title">
          Table with irregular Headers
        </Heading>

        <Box spacing="basePos3" padding="basePos3">
          <Table caption="Delivery slots">
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell colSpan={2} align="center">
                  Mars
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={2} align="center">
                  Venus
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell align="right">Produced</Table.HeaderCell>
                <Table.HeaderCell align="right">Sold</Table.HeaderCell>
                <Table.HeaderCell align="right">Produced</Table.HeaderCell>
                <Table.HeaderCell align="right">Sold</Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell scope="row">Teddy Bears</Table.HeaderCell>
                <Table.DataCell align="right">60,000</Table.DataCell>
                <Table.DataCell align="right">30,000</Table.DataCell>
                <Table.DataCell align="right">100,000</Table.DataCell>
                <Table.DataCell align="right">80,000</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell scope="row">Board Games</Table.HeaderCell>
                <Table.DataCell align="right">10,000</Table.DataCell>
                <Table.DataCell align="right">5,000</Table.DataCell>
                <Table.DataCell align="right">12,000</Table.DataCell>
                <Table.DataCell align="right">9,000</Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>

        <Heading level="3" variant="title">
          Table with no borders
        </Heading>

        <Box spacing="basePos3" padding="basePos3">
          <Table caption="Delivery slots" variant="borderless">
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell colSpan={2} align="center">
                  Mars
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={2} align="center">
                  Venus
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell align="right">Produced</Table.HeaderCell>
                <Table.HeaderCell align="right">Sold</Table.HeaderCell>
                <Table.HeaderCell align="right">Produced</Table.HeaderCell>
                <Table.HeaderCell align="right">Sold</Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell scope="row">Teddy Bears</Table.HeaderCell>
                <Table.DataCell align="right">60,000</Table.DataCell>
                <Table.DataCell align="right">30,000</Table.DataCell>
                <Table.DataCell align="right">100,000</Table.DataCell>
                <Table.DataCell align="right">80,000</Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell scope="row">Board Games</Table.HeaderCell>
                <Table.DataCell align="right">10,000</Table.DataCell>
                <Table.DataCell align="right">5,000</Table.DataCell>
                <Table.DataCell align="right">12,000</Table.DataCell>
                <Table.DataCell align="right">9,000</Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The table is used to show multiple items in a list.
          </Text>
          <Text paragraph>
            This applies to large lists of info that requires multiple columns
            like financial information such as transactions, lists of users etc
          </Text>
          <Text paragraph>
            It will be clarified in the page whether each line item is to be
            clickable or not.
          </Text>
          <Text paragraph>There will be 2 types of tables:</Text>
          <Heading level="4" variant="title">
            Payment tables
          </Heading>
          <Text paragraph>
            To be used for when there needs to be a table within a card, for
            example an account details card. This type has a 32px padding on the
            left and right to give it roomin the card.
          </Text>
          <Heading level="4" variant="title">
            Display tables
          </Heading>
          <Text paragraph>
            To be used for as a free standing table to go along with content on
            a webpage, for example on a component page in Bumblebee for showing
            states and when they get used. This type does not have any padding
            on the left and right so it can sit fully justified with other
            content.
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            The table will consist of line tites, line items and pagination.
          </Text>
          <Heading level="4" variant="title">
            Line titles
          </Heading>
          <Text paragraph>
            Line titles have a slightly heavier text style to differentiate them
            from the items.
          </Text>
          <Text paragraph>
            Some line titles will be clickable in the future to help the user
            filter.
          </Text>
          <Heading level="4" variant="title">
            Line items
          </Heading>
          <Text paragraph>
            Depending on the context and usage, line items can have hover, focus
            and disabled states.
          </Text>
          <Text paragraph>
            They can alternate between left aligned and right aligned.
          </Text>
          <Heading level="4" variant="title">
            Standard
          </Heading>
          <Text paragraph>
            These are regular items and titles that are used to show most of the
            information within a table.
          </Text>
          <Heading level="4" variant="title">
            Bold
          </Heading>
          <Text paragraph>
            These are items and titles that have been given a heavier text style
            because they classify as the most important information within the
            table for the user. Not every table will have use for this type of
            items, but on the ones that do, we should make sure we are not
            hiding this bold column at smaller viewports. They can alternate
            between left aligned and right aligned.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            The table can be interacted with in multiple ways.
          </Text>
          <Text paragraph>
            If a line item can be opened, the user can hover and click.
          </Text>
          <Text paragraph>
            Focus states will also be outlined so that users can tab through
            interactive options.
          </Text>
          <Text paragraph>
            If some of the columns in the table will be hidden on smaller
            viewports, guidelines around these rules should be outlined at
            Organism level.
          </Text>
          <Text paragraph>
            Transition to display a state change with a 0.25 ease.
          </Text>
          <Text paragraph>
            Please refer to Behaviour and Prototype for examples
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            Please refer to Placement in page designs for examples
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the appropriate ARIA attributes for screen readers where
            applicable.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <TableMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

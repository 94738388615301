import { ReactNode } from 'react';

import { Pagination as ApiPagination } from '@shieldpay/steamhammer-ui';

import { Box } from '../box/box';
import { Text } from '../text/text';

import {
  IconType,
  PaginationItem,
  PaginationItemLegacy,
} from './pagination-item';

interface PaginationSharedProps extends ApiPagination {
  paginationPageResults: (paginationTextInfo: {
    from: number;
    to: number;
    total: number;
  }) => ReactNode;
  'data-testid'?: string;
}

export interface PaginationProps extends PaginationSharedProps {
  createTo: (offset: number) => string;
}

export interface PaginationLegacyProps extends PaginationSharedProps {
  onOffsetChange: (offset: number) => void;
}

export const TEST_IDS = {
  PAGINATION: 'pagination-component',
};

const iconTypeList: IconType[] = ['first', 'previous', 'next', 'last'];

export const Pagination = ({
  createTo,
  pageSize,
  limit,
  offset,
  total,
  paginationPageResults,
  'data-testid': testId = TEST_IDS.PAGINATION,
}: PaginationProps) => {
  const currentPage = offset / limit + 1;

  // If total is 0, we should still have 1 page.
  const pageCount = Math.max(1, Math.ceil(total / limit));

  return (
    <Box
      data-testid={testId}
      alignItems={['full', 'center']}
      stack="row"
      padding={{ x: 'basePos4', top: 'basePos3', bottom: 'basePos4' }}
    >
      <Text paragraph variant="input">
        {paginationPageResults({
          from: offset + 1,
          to: offset + pageSize,
          total,
        })}
      </Text>
      <Box stack="row" spacing="baseNeg4">
        {iconTypeList.map((type) => (
          <PaginationItem
            key={type}
            currentPage={currentPage}
            pageCount={pageCount}
            type={type}
            createTo={(page) => createTo((page - 1) * limit)}
          />
        ))}
      </Box>
    </Box>
  );
};

/**
 * @deprecated
 * onOffsetChange handler called when the offset is changed. [Legacy - SPA]
 * Remove when all apps migrated to Remix.
 */
export const PaginationLegacy = ({
  onOffsetChange,
  pageSize,
  limit,
  offset,
  total,
  paginationPageResults,
  'data-testid': testId = TEST_IDS.PAGINATION,
}: PaginationLegacyProps) => {
  const currentPage = offset / limit + 1;

  // If total is 0, we should still have 1 page.
  const pageCount = Math.max(1, Math.ceil(total / limit));

  return (
    <Box
      data-testid={testId}
      alignItems={['full', 'center']}
      stack="row"
      padding={{ x: 'basePos4', top: 'basePos3', bottom: 'basePos4' }}
    >
      <Text paragraph variant="input">
        {paginationPageResults({
          from: offset + 1,
          to: offset + pageSize,
          total,
        })}
      </Text>
      <Box stack="row" spacing="baseNeg4">
        {iconTypeList.map((type) => (
          <PaginationItemLegacy
            key={type}
            currentPage={currentPage}
            pageCount={pageCount}
            type={type}
            onPageChange={(page) => onOffsetChange((page - 1) * limit)}
          />
        ))}
      </Box>
    </Box>
  );
};

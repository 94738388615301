import { useState } from 'react';

import { Box, Button, Heading, Text, TextList } from '@shieldpay/bumblebee';
import ButtonMarkdown from '@shieldpay/bumblebee/markdown/button.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { ButtonAppearance } from '../../components/clickable-elements/button-appearance';
import { LinkAppearance } from '../../components/clickable-elements/link-appearance';
import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

const DEFAULT_LOADING = {
  small: false,
  medium: false,
  large: false,
};

export const ButtonComponentPage = () => {
  usePageTitle({ id: 'hive.components.button.title' });

  const [buttonsLoading, setButtonsLoading] = useState(DEFAULT_LOADING);

  const createHandleButtonClick = (size: keyof typeof DEFAULT_LOADING) => () =>
    setButtonsLoading((state) => ({ ...state, [size]: true }));

  return (
    <>
      <ContentsTable />

      <ComponentPreview spacing="basePos5">
        <ButtonAppearance Component={Button} />
        <Heading level="3" variant="title">
          Loading buttons
        </Heading>
        <Box
          spacing="basePos3"
          padding={{ y: 'basePos3' }}
          stack="row"
          alignItems={['left', 'center']}
        >
          <Button
            size="small"
            onClick={createHandleButtonClick('small')}
            loading={buttonsLoading.small}
          >
            Click to see me Load
          </Button>
          <Button
            size="medium"
            onClick={createHandleButtonClick('medium')}
            loading={buttonsLoading.medium}
          >
            Click to see me Load
          </Button>
          <Button
            size="large"
            variant="outline"
            onClick={createHandleButtonClick('large')}
            loading={buttonsLoading.large}
          >
            Click to see me Load
          </Button>
        </Box>

        <Button
          variant="text-underline"
          onClick={() => setButtonsLoading(DEFAULT_LOADING)}
        >
          Reset loading state
        </Button>

        <LinkAppearance Component={Button} />
      </ComponentPreview>
      <Design>
        <Design.Section heading={<>Usage</>}>
          <Text paragraph>
            Secondary buttons to be used alongside Primary buttons when there is
            a secondary action. In some instances secondary buttons can also be
            used as a standalone button.
          </Text>
          <Text paragraph>
            Buttons come in two sizes, large to use as secondary CTAs Small
            buttons to be used where space is restricted, such as in dashboard
            controls and dialogs.
          </Text>
        </Design.Section>
        <Design.Section heading={<>Specs</>}>
          <Text paragraph>
            The buttons are constructed using master primitive components, based
            on colour and specific text styles. If the master primitive
            component is updated, the other instances will be updated too.
          </Text>
          <Text paragraph>
            The text is positioned to be optically centered in the buttons. The
            buttons have 4 states:
          </Text>
          <TextList>
            <TextList.Item>Default</TextList.Item>
            <TextList.Item>Hover </TextList.Item>
            <TextList.Item>
              Focus (the style of this will apply to all other interactive
              components)
            </TextList.Item>
            <TextList.Item>Disabled</TextList.Item>
          </TextList>
          <Heading level="4" variant="title">
            Button large:
          </Heading>
          <Text paragraph>
            The large button is constructed from the ‘Button large‘ text style
            combined with horizontal and vertical padding values. The button
            width is determined by the width of the text, plus left/right
            padding, unless specified to be set a specific width, i.e. a to
            match a field set width. The button height works out at 56px tall.
          </Text>

          <Heading level="4" variant="title">
            Button small:
          </Heading>
          <Text paragraph>
            The large button is constructed from the ‘Button small‘ text style
            combined with horizontal and vertical padding values. The button
            width is determined by the width of the text, plus left/right
            padding, unless specified to be set a specific width, i.e. a to
            match a field set width. The button height works out at 44px tall.
          </Text>
        </Design.Section>
        <Design.Section heading={<>Hover/Focus transition</>}>
          <Text paragraph>0.25 ease (To be reviewed in browser)</Text>
        </Design.Section>
        <Design.Section heading={<>Accessibility of disabled button</>}>
          <Text paragraph>
            Use the disabled or aria-disabled attributes to convey information
            to screen readers.
          </Text>
          <Text paragraph>
            Add to your disabled styles cursor: not-allowed this will serve as
            another visual indicator for mouse users.
          </Text>
        </Design.Section>
      </Design>
      <MDXCodeblockProvider>
        <ButtonMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { useLayoutEffect, useRef } from 'react';

import {
  sortCodeToFormattedValue,
  stringToDigitsOnly,
} from '@shieldpay/utility-functions-ui';

import { Box } from '../box/box';
import { InputField, InputFieldProps } from '../input-field/input-field';

import { BankLogoFromSortcode } from './bank-logo-from-sortcode';

export interface SortCodeInputProps
  extends Omit<
    InputFieldProps,
    'type' | 'disabled' | 'className' | 'extraContainerStyles'
  > {
  /**
   * required string to label the placeholder icon
   */
  bankPlaceholderIconAlt: string;
}

type SelectionDirection = 'forward' | 'backward' | 'none';

interface Selection {
  selectionStart: number;
  selectionEnd: number;
  selectionDirection: SelectionDirection;
}

/**
 * Function to compute adjusted selection range handling
 * the hyphenated display value.
 */
const adjustSelectionRange = (
  { selectionStart, selectionEnd, selectionDirection }: Selection,
  hyphenatedDisplayValue: string,
): Parameters<HTMLInputElement['setSelectionRange']> => {
  const adjustSelectionForHyphen =
    hyphenatedDisplayValue?.slice(selectionStart - 1, selectionEnd) === '-';

  return [
    adjustSelectionForHyphen ? selectionStart + 1 : selectionStart,
    adjustSelectionForHyphen ? selectionEnd + 1 : selectionEnd,
    selectionDirection,
  ];
};

export const SortCodeInput = ({
  value = '',
  onFocus,
  onBlur,
  onChange,
  label,
  helpText,
  'data-testid': testId,
  id,
  validation,
  showValidation = false,
  name,
  validating,
  showVisualErrorStyles = false,
  bankPlaceholderIconAlt,
}: SortCodeInputProps) => {
  // inputRef used to get and then set adjusted selection values
  // to handle cursor placement.
  const inputRef = useRef<HTMLInputElement>(null);
  // nextSelectionRef used to store selection values to be applied when
  // controlled value is updated
  const nextSelectionArgumentsRef = useRef<Parameters<
    HTMLInputElement['setSelectionRange']
  > | null>(null);

  const handleChange = (value: string) => {
    // remove non-digits and constrains to 6 character length
    const sanitisedValue = stringToDigitsOnly(value).slice(0, 6);

    nextSelectionArgumentsRef.current = adjustSelectionRange(
      inputRef.current as Selection,
      sortCodeToFormattedValue(sanitisedValue),
    );

    onChange(sanitisedValue);
  };

  // Update the selected range when the controlled value updates
  useLayoutEffect(() => {
    if (!nextSelectionArgumentsRef.current) return;

    inputRef?.current?.setSelectionRange(...nextSelectionArgumentsRef.current);
  }, [value]);

  return (
    <InputField
      id={id}
      name={name}
      helpText={helpText}
      label={label}
      value={value && sortCodeToFormattedValue(value)}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      validation={validation}
      validating={validating}
      showValidation={showValidation}
      showVisualErrorStyles={showVisualErrorStyles}
      data-testid={testId}
      ref={inputRef}
      type="tel"
    >
      <Box alignItems={['center', 'center']} padding={{ right: 'baseNeg5' }}>
        <BankLogoFromSortcode
          sortCodePartial={value}
          bankPlaceholderIconAlt={bankPlaceholderIconAlt}
        />
      </Box>
    </InputField>
  );
};

export { BankLogoFromSortcode };

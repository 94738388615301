import { ReactNode } from 'react';

import { Box } from '../box/box';
import { AlertIcon, InfoIcon } from '../icons';
import { Text } from '../text/text';
import { Palette } from '../text/types';

import * as styles from './infoblock.styles';

type Variant = keyof typeof styles.variant;

interface InfoblockProps {
  children: ReactNode;
  variant?: Variant;
}

export const TEST_IDS = {
  INFO_BLOCK: 'infoblock',
  ICON_INFO: 'infoblockIconInfo',
  ICON_ERROR: 'infoblockIconError',
};

const variants = {
  info: {
    Component: InfoIcon,
    textColor: 'info600',
    testId: TEST_IDS.ICON_INFO,
  },
  error: {
    Component: AlertIcon,
    textColor: 'alert600',
    testId: TEST_IDS.ICON_ERROR,
  },
} satisfies Record<Variant, Record<string, unknown> & { textColor: Palette }>;

export const Infoblock = ({ children, variant = 'info' }: InfoblockProps) => {
  const { Component, textColor, testId } = variants[variant];
  return (
    <Box
      stack="row"
      alignItems={['left', 'top']}
      padding={{ x: 'base', y: 'baseNeg2' }}
      spacing="baseNeg3"
      css={styles.variant[variant]}
      data-testid={TEST_IDS.INFO_BLOCK}
      color={textColor}
      rounded
    >
      <Component data-testid={testId} type={variant} size="s" />

      {/* TODO - investigate how to replace component prop and create an API that accepts an array of error messages. */}
      <Text variant="caption150" color={textColor} component="div">
        {children}
      </Text>
    </Box>
  );
};

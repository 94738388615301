import { Heading } from '@shieldpay/bumblebee';
import HeadingMarkdown from '@shieldpay/bumblebee/markdown/heading.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const HeadingComponentPage = () => {
  usePageTitle({ id: 'hive.components.heading.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Heading variant="headline1">
          headline1: Jinxed wizards pluck ivy from the big quilt.
        </Heading>
        <Heading variant="headline2">
          headline2: Jinxed wizards pluck ivy from the big quilt.
        </Heading>
        <Heading variant="title">
          title: Jinxed wizards pluck ivy from the big quilt.
        </Heading>
        <Heading variant="section">
          section: Jinxed wizards pluck ivy from the big quilt.
        </Heading>
        <Heading variant="bodyMedium150">
          bodyMedium150: Jinxed wizards pluck ivy from the big quilt.
        </Heading>
        <Heading variant="captionMedium150">
          captionMedium150: Jinxed wizards pluck ivy from the big quilt.
        </Heading>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <HeadingMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

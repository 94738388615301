import atom from '../../images/bank-atom.svg';
import bankOfScotland from '../../images/bank-bank-of-scotland.svg';
import barclays from '../../images/bank-barclays.svg';
import halifax from '../../images/bank-halifax.svg';
import hsbc from '../../images/bank-hsbc.svg';
import lloyds from '../../images/bank-lloyds.svg';
import metro from '../../images/bank-metro.svg';
import monzo from '../../images/bank-monzo.svg';
import n26 from '../../images/bank-n26.svg';
import nationwide from '../../images/bank-nationwide.svg';
import natwest from '../../images/bank-natwest.svg';
import bankPlaceholderSrc from '../../images/bank-placeholder.svg';
import rbs from '../../images/bank-rbs.svg';
import revolut from '../../images/bank-revolut.svg';
import santander from '../../images/bank-santander.svg';
import standardCharteredBank from '../../images/bank-standard-chartered-bank.svg';
import starling from '../../images/bank-starling.svg';
import tandem from '../../images/bank-tandem.svg';
import transferwise from '../../images/bank-transferwise.svg';
import tsb from '../../images/bank-tsb.svg';
import virgin from '../../images/bank-virgin.svg';

interface BankLogoFromSortcodeProps {
  sortCodePartial?: string;
  bankPlaceholderIconAlt: string;
}

// If we grow to international scale and require a set of banks for different locales
// investigate setting a set of banks for that location.
const ukBanks = [
  { logoSrc: atom, name: 'Atom Bank', uniqueSortCodeIdentifier: '608312' },
  { logoSrc: halifax, name: 'Halifax Bank', uniqueSortCodeIdentifier: '1100' },
  { logoSrc: lloyds, name: 'Lloyds Bank', uniqueSortCodeIdentifier: '7749' },
  { logoSrc: hsbc, name: 'HSBC', uniqueSortCodeIdentifier: '4012' },
  {
    logoSrc: bankOfScotland,
    name: 'Bank of Scotland',
    uniqueSortCodeIdentifier: '80',
  },
  { logoSrc: monzo, name: 'Monzo Bank', uniqueSortCodeIdentifier: '040004' },
  { logoSrc: natwest, name: 'Natwest Bank', uniqueSortCodeIdentifier: '6060' },
  { logoSrc: rbs, name: 'RBS', uniqueSortCodeIdentifier: '1663' },
  { logoSrc: revolut, name: 'Revolut', uniqueSortCodeIdentifier: '040075' },
  {
    logoSrc: santander,
    name: 'Santander Bank',
    uniqueSortCodeIdentifier: '0901',
  },
  {
    logoSrc: starling,
    name: 'Starling Bank',
    uniqueSortCodeIdentifier: '608371',
  },
  { logoSrc: tandem, name: 'Tandem', uniqueSortCodeIdentifier: '608363' },
  { logoSrc: tsb, name: 'TSB', uniqueSortCodeIdentifier: '8779' },
  { logoSrc: virgin, name: 'Virgin Money', uniqueSortCodeIdentifier: '0860' },
  { logoSrc: nationwide, name: 'Nationwide', uniqueSortCodeIdentifier: '0704' },
  { logoSrc: metro, name: 'Metro', uniqueSortCodeIdentifier: '2305' },
  { logoSrc: barclays, name: 'Barclays', uniqueSortCodeIdentifier: '2026' },
  {
    logoSrc: standardCharteredBank,
    name: standardCharteredBank,
    uniqueSortCodeIdentifier: '6091',
  },
  {
    logoSrc: transferwise,
    name: transferwise,
    uniqueSortCodeIdentifier: '2314',
  },
  { logoSrc: n26, name: n26, uniqueSortCodeIdentifier: '040026' },
];

const findBank = (
  sortCodePartial = '',
  placeholderName: string,
  banks = ukBanks,
) => {
  // TODO write tests for zero, one and more than match
  const [match, ...[another]] = banks.filter(({ uniqueSortCodeIdentifier }) =>
    sortCodePartial.startsWith(uniqueSortCodeIdentifier),
  );

  if (match && !another) {
    return match;
  }

  return {
    name: placeholderName,
    logoSrc: bankPlaceholderSrc,
  };
};

export const BankLogoFromSortcode = ({
  sortCodePartial,
  bankPlaceholderIconAlt,
}: BankLogoFromSortcodeProps) => {
  const { name, logoSrc } = findBank(sortCodePartial, bankPlaceholderIconAlt);
  // TODO replace when bumblebee supports Image component [SPT-716]
  return <img src={logoSrc} alt={name} height="40" width="40" />;
};

BankLogoFromSortcode.findBank = findBank;

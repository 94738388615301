interface DayMonthYear {
  day: string;
  month: string;
  year: string;
}

export const dayIsValid = ({
  day: dayString,
  month,
  year,
}: Partial<DayMonthYear>) => {
  // Do not run check if no values because we can’t work out how many days are valid
  // Instead this assumes that the required validator message will show.
  if (!month || !year || !dayString) return true;

  // Incrementing the month by one and then requesting day 0 gets the last day of the previous month.
  const daysInMonth = new Date(Number(year), Number(month) + 1, 0).getDate();

  const day = Number(dayString);

  return day > 0 && day <= daysInMonth;
};

/**
 * Creates a validator for a maximum date based on a minimumAge
 */
export const createMinimumAge = (minimumAge: number) => {
  const maximumDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - minimumAge),
  );

  return ({ day, month, year }: Partial<DayMonthYear>) => {
    // Do not run check if no values because we can’t work out the date.
    // Instead this assumes that the required validator message will show.
    if (!month || !year || !day) return true;

    const dateOfBirth = new Date(Number(year), Number(month), Number(day));

    return dateOfBirth < maximumDate;
  };
};

/**
 * Validates a year is no earlier than 1865 and no later than the current year
 */
export const yearInRange = (year: string) => {
  const yearNumber = Number(year);

  return yearNumber >= 1865 && yearNumber <= new Date().getFullYear();
};

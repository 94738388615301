import { css, Theme } from '@emotion/react';

import { transformValues } from '@shieldpay/utility-functions-ui';

import {
  createThemedStyleObjects,
  spacingStyle,
  themeifyStyle,
} from '../../themes/utilities';

export const box = {
  default: css({
    display: 'flex',
  }),
  noLayout: css({
    display: 'block',
  }),
  inline: css({
    display: 'inline',
  }),
};

export const stack = {
  row: css({
    flexDirection: 'row',
  }),
  column: css({
    flexDirection: 'column',
  }),
};

export const stackXAlignment = {
  column: {
    left: css({
      alignItems: 'flex-start',
    }),
    right: css({
      alignItems: 'flex-end',
    }),
    center: css({
      alignItems: 'center',
    }),
    full: css({
      alignItems: 'stretch',
    }),
  },
  row: {
    left: css({
      justifyContent: 'flex-start',
    }),
    right: css({
      justifyContent: 'flex-end',
    }),
    center: css({
      justifyContent: 'center',
    }),
    full: css({
      justifyContent: 'space-between',
    }),
  },
};

export const stackYAlignment = {
  column: {
    top: css({
      justifyContent: 'flex-start',
    }),
    bottom: css({
      justifyContent: 'flex-end',
    }),
    center: css({
      justifyContent: 'center',
    }),
    full: css({
      justifyContent: 'space-between',
    }),
  },
  row: {
    top: css({
      alignItems: 'flex-start',
    }),
    bottom: css({
      alignItems: 'flex-end',
    }),
    center: css({
      alignItems: 'center',
    }),
    full: css({
      alignItems: 'stretch',
    }),
  },
};

export const expand = css({
  alignSelf: 'stretch',
  flexGrow: 1,
});

/**
 * Returns a style map using spacing tokens as keys, with a gap property set
 * to the theme token value, e.g:
 *
 * {
 *  base: {
 *    gap: '16px'
 *  },
 *  basePos1: {
 *    gap: '20px'
 *  }
 *  // ...
 * }
 */
export const [spacing] = createThemedStyleObjects((theme) =>
  spacingStyle(theme.spacing),
);

/**
 * Returns a style map for given padding property, keyed by size and using
 * spacing theme tokens e.g:
 *
 * {
 *  xsmall: {
 *    paddingLeft: '16px',
 *  },
 *  small: {
 *    paddingLeft: '20px'
 *  }
 * }
 */
const generatePaddingStyleMap = (
  paddingProperties: (
    | 'padding'
    | 'paddingLeft'
    | 'paddingRight'
    | 'paddingTop'
    | 'paddingBottom'
  )[],
  { spacing }: Theme,
) =>
  transformValues(spacing, (pixels) =>
    Object.fromEntries(
      paddingProperties.map((property) => [[property], `${pixels}px`]),
    ),
  );

export const [padding, paddingResponsive] = createThemedStyleObjects((theme) =>
  generatePaddingStyleMap(['padding'], theme),
);

export const [paddingLeft, paddingLeftResponsive] = createThemedStyleObjects(
  (theme) => generatePaddingStyleMap(['paddingLeft'], theme),
);
export const [paddingRight, paddingRightResponsive] = createThemedStyleObjects(
  (theme) => generatePaddingStyleMap(['paddingRight'], theme),
);
export const [paddingTop, paddingTopResponsive] = createThemedStyleObjects(
  (theme) => generatePaddingStyleMap(['paddingTop'], theme),
);
export const [paddingBottom, paddingBottomResponsive] =
  createThemedStyleObjects((theme) =>
    generatePaddingStyleMap(['paddingBottom'], theme),
  );

export const borderRadius = themeifyStyle(({ borderRadius }) => ({
  borderRadius,
}));

export const screenReaderOnly = css({
  border: '0 !important',
  clip: 'rect(1px, 1px, 1px, 1px) !important',
  clipPath: 'inset(50%) !important',
  height: '1px !important',
  margin: '-1px !important',
  overflow: 'hidden !important',
  padding: '0 !important',
  position: 'absolute',
  width: '1px !important',
  whiteSpace: 'nowrap',
});

export const touchArea = themeifyStyle((theme) => ({
  padding: `${theme.spacing.baseNeg4}px`,
  margin: `-${theme.spacing.baseNeg4}px`,
  cursor: 'pointer',
  '& > *': {
    cursor: 'pointer',
  },
}));

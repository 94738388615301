import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Text } from '../text/text';

import * as styles from './pill.styles';

export interface PillProps {
  variant?: keyof typeof styles.variant;
  children: ReactNode;
  'data-testid'?: string;
}

export const TEST_IDS = {
  PILL: 'pill-component',
};

export const Pill = ({
  variant = 'default',
  children,
  'data-testid': testId = TEST_IDS.PILL,
}: PillProps) => (
  <Box
    css={[styles.pill, styles.variant[variant]]}
    data-testid={testId}
    padding={{ x: 'baseNeg3', y: 'baseNeg5' }}
  >
    <Text paragraph truncate variant="caption">
      {children}
    </Text>
  </Box>
);

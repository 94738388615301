import { ReactNode } from 'react';

import { CheckboxControl } from '../checkbox/checkbox-control';
import { RadioButton } from '../radio-button/radio-button';

export interface ValuesItem<Value> {
  value: Value;
  label: ReactNode;
}

interface ItemProps<Value> extends ValuesItem<Value> {
  disabled?: boolean;
  id: string;
  name: string;
  label: ReactNode;
}

interface RadioButtonItemProps<Value> extends ItemProps<Value> {
  checked: boolean;
  onChange: (value: Value) => void;
}

interface CheckBoxItemProps<Value> extends ItemProps<Value> {
  listValues: Array<Value>;
  onChange: (values: Array<Value>) => void;
}

export const RadioButtonItem = <Value,>({
  checked,
  disabled,
  name,
  id,
  onChange,
  label,
  value,
}: RadioButtonItemProps<Value>) => {
  const handleClick = () => {
    if (!checked) {
      onChange(value);
    }
  };

  return (
    <RadioButton
      disabled={disabled}
      checked={checked}
      label={label}
      id={id}
      name={name}
      onClick={handleClick}
      value={value as string}
    />
  );
};

export const CheckboxItem = <Value,>({
  disabled,
  label,
  value,
  listValues,
  name,
  id,
  onChange,
}: CheckBoxItemProps<Value>) => {
  const isChecked = listValues?.includes(value);

  const handleClick = () => {
    onChange(
      isChecked
        ? listValues.filter((val) => val !== value)
        : [...listValues, value],
    );
  };

  return (
    <CheckboxControl
      disabled={disabled}
      checked={isChecked}
      label={label}
      id={id}
      name={name}
      onClick={handleClick}
    />
  );
};

import { Box, PrimaryNav, Text, TextList } from '@shieldpay/bumblebee';
import PrimaryNavMarkdown from '@shieldpay/bumblebee/markdown/primary-nav.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const PrimaryNavComponentPage = () => {
  usePageTitle({ id: 'hive.components.primaryNav.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Box css={{ width: 264 }}>
          <PrimaryNav>
            <PrimaryNav.Item to="/">Current page</PrimaryNav.Item>
            <PrimaryNav.Item to="/yo">Another page</PrimaryNav.Item>
            <PrimaryNav.Item disabled to="/forbidden">
              Forbidden page
            </PrimaryNav.Item>
          </PrimaryNav>
        </Box>
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The Navigation bar is to be used as the primary mode of navigating
            through the platform.
          </Text>
          <Text paragraph>
            Users should be able to easily understand in which section they are
            in and which sections are available to them.
          </Text>
          <Text paragraph>
            As default the first section would be selected upon access to the
            platform.
          </Text>
          <Text paragraph>
            As per requirements of out personas, we are only going to be
            providing a solution for XL and L viewports. The M, S and XS will
            have a hidden navigation and will be a separate component.
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            The Navigation bar is made up of a Primary-base background with the
            Paycast logo at the top. This is fixed to the left at 264px at XL to
            L and shrinks on smaller viewports.
          </Text>
          <Text paragraph>
            The components within the navigation bar are the navigation buttons
            that span the full width of the bar at 264px. The navigation buttons
            are all 48px in height and sit flush to each other with the first
            button being 40px from the logo.
          </Text>
          <Text paragraph>
            The states of the buttons are as follows:
            <br />
            Default, Hover, Focus, Selected, Selected focus, Disabled
          </Text>
          <Text paragraph>
            Please refer to Components and Specs for examples
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            The interactivity of the Nav buttons are as follows:
          </Text>
          <TextList>
            <TextList.Item>Default - idle state, no interaction</TextList.Item>
            <TextList.Item>Hover - user hovers over the button</TextList.Item>
            <TextList.Item>
              Focus - user tabs over a default button
            </TextList.Item>
            <TextList.Item>
              Selected - this button indicates the page you are on
            </TextList.Item>
            <TextList.Item>
              Selected focus - user tabs over a selected button
            </TextList.Item>
            <TextList.Item>
              Disabled - button is inactive, no hover or focus states
            </TextList.Item>
          </TextList>
          <Text paragraph>
            Transition between states should be set to a 0.25 ease (To be
            reviewed in browser)
          </Text>
          <Text paragraph>Focus states should animate just like buttons</Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            The components within the navigation bar are the navigation buttons
            that span the full width of the bar at 264px. Please refer to
            Placement for examples of this.
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the appropriate ARIA attributes for screen readers where
            applicable.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <PrimaryNavMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { ReactNode } from 'react';

import { Box } from '../box/box';
import { useBoxStyles } from '../box/use-box-styles';
import { useBumblebeeContext } from '../bumblebee-provider/bumblebee-provider';
import { Text } from '../text/text';
import { useTextStyles } from '../text/use-text-styles';

import * as styles from './primary-nav.styles';

export interface PrimaryNavProps {
  className?: string;
  children: ReactNode;
}

interface NavItem {
  children: ReactNode;
  disabled?: boolean;
  to: string;
}

interface NavHeadingProps {
  children: ReactNode;
}

const PrimaryNavHeading = ({ children }: NavHeadingProps) => (
  <Text
    component="li"
    variant="section"
    color="neutral250"
    padding={{ left: 'basePos3', y: 'base' }}
  >
    {children}
  </Text>
);

const PrimaryNavItem = ({ disabled = false, children, to }: NavItem) => {
  const { NavLink } = useBumblebeeContext();

  // We are using the style hooks directly here because these styles
  // are applied to two different components depending on link state.
  const css = [
    ...useBoxStyles({ padding: { left: 'basePos3', y: 'base' } }),
    ...useTextStyles({
      color: disabled ? 'neutral250' : 'neutral000',
      variant: 'bodyMedium',
    }).styles,
    styles.link,
    !disabled && styles.enabled,
  ];

  return (
    <Box component="li">
      {disabled ? (
        <Text css={css}>{children}</Text>
      ) : (
        <NavLink to={to} css={css}>
          {children}
        </NavLink>
      )}
    </Box>
  );
};

export const PrimaryNav = ({ children, className }: PrimaryNavProps) => {
  return (
    <Box className={className} component="nav" aria-label="Primary navigation">
      <Box component="ul">{children}</Box>
    </Box>
  );
};

PrimaryNav.Item = PrimaryNavItem;
PrimaryNav.Heading = PrimaryNavHeading;

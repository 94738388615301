import { css } from '@emotion/react';

import { focusRingStyle, themeifyStyle } from '../../themes/utilities';

export const table = css({
  borderCollapse: 'collapse',
  width: '100%',
});

/**
 * When a table has a border remove the unneeded border on the last row
 */
export const tableHasBorder = css({
  'tbody tr:last-of-type': {
    borderBottom: 'transparent',
  },
});

export const tableInteractive = themeifyStyle((theme) => ({
  'tbody tr': {
    ':hover': {
      color: theme.palette.primary600,
      backgroundColor: theme.palette.primary200,
    },
    ':focus, :focus-within, :focus-visible': focusRingStyle(theme).style,
  },
  // `TableRowLinkHeaderCell` and `TableRowLinkDataCell` are on the last column of the table and have absolute positioning.
  // The indent we add to the last cell of the row should now be applied to the cell before last as absolute positioning cannot have padding.
  'th, td:nth-last-of-type(2)': {
    paddingRight: `${theme.spacing.basePos4}px`,
  },
}));

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgAlertIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.241 5.147c.757-1.397 2.761-1.397 3.518 0l11.32 20.9C29.803 27.38 28.838 29 27.322 29H4.68c-1.516 0-2.48-1.62-1.759-2.953l11.321-20.9zM16 6.1L4.679 27H27.32L16 6.1z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 20v-8h2v8h-2z"
        fill="#353C4A"
      />
      <circle cx={16} cy={23.5} r={1.5} fill="#353C4A" />
    </svg>,
  );
};

export default SvgAlertIcon;

import {
  Box,
  Button,
  Text,
  ToastRackProvider,
  useToasty,
} from '@shieldpay/bumblebee';
import {
  AlertCircleIcon,
  AlertIcon,
  InfoIcon,
} from '@shieldpay/bumblebee/icons';
import ToastRackMarkdown from '@shieldpay/bumblebee/markdown/toast-rack.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

const stringToasts = [
  'Ninja crumpet!',
  'Mmm, cinnamon bagels',
  'Calling Powdered Toast Man, please respond',
];

const iconToasts = [
  {
    icon: <AlertIcon />,
    text: 'This is a toast, to the host, with the most.',
  },
  {
    icon: <InfoIcon />,
    text: 'This is a toast to the holiest ghost.',
  },
  {
    icon: <AlertCircleIcon />,
    text: 'How d’ya like your toast in the morning?',
  },
];

const randoToast = (allToasts: Array<(typeof iconToasts)[number] | string>) =>
  allToasts[Math.floor(Math.random() * allToasts.length)];

const ToastAddifier = () => {
  usePageTitle({ id: 'hive.components.toast.title' });

  const { addToast } = useToasty();

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Box stack="row" spacing="basePos3">
          <Button onClick={() => addToast(randoToast(stringToasts))}>
            Show a toast notification
          </Button>
          <Button onClick={() => addToast(randoToast(iconToasts))}>
            Show an icon toast notification
          </Button>
        </Box>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <ToastRackMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

export const ToastRackComponentPage = () => {
  return (
    <ToastRackProvider>
      <ToastAddifier />
      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The toast notification should only be used when showing passive
            notifications without any actions (For example - when an action has
            been completed i.e. Password created)
          </Text>
        </Design.Section>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            The toast comes in a single size both with and without an icon. It
            is made up of the main plate and a text component. The icon should
            be pulled from our icon library only. If the master primitive
            component is updated, the other instances will be updated too.
          </Text>
          <Text paragraph>
            The text and icon is positioned to be optically centered in the
            toast.
          </Text>
          <Text paragraph>
            The spacing should adhere to the guidelines outlined.
          </Text>
          <Text paragraph>Drop shadow should be as follows:</Text>
          <Text paragraph>Y: 2px / Blur: 6px / Spread: 2px</Text>
          <Text paragraph>
            It should always be positioned the allocated spacing from the
            bottom. The padding is outlined for each viewport.
          </Text>
          <Text paragraph>
            In the instance of multiple toasts, they should stack on top of each
            other using the allocated spacing measurements. Each consecutive
            toast should appear on top with the earliest still at the bottom.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            Toasts appear temporarily, and disappear on their own without
            requiring user input to be dismissed. Toasts cannot be clicked or
            tapped.
          </Text>
          <Text paragraph>
            The toast should appear with a Fade up transition with an Ease Out
            animation at 300 ms.
          </Text>
          <Text paragraph>
            The time that the toast should remain on screen will depend on the
            content of the message. As a rule of thumb we should be or 100ms per
            character (including spaces).
          </Text>
          <Text paragraph>
            The toast should disappear with a reversed fade up with an Ease Out
            animation at 600 ms.
          </Text>
          <Text paragraph>Animation example (see Fade up):</Text>
          <Text paragraph>https://semantic-ui.com/modules/transition.html</Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the disabled or aria-disabled attributes to convey information
            to screen readers.
          </Text>
        </Design.Section>
      </Design>
    </ToastRackProvider>
  );
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgChevronRightIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M14.001 6.791l9.191 9.207-9.191 9.192-1.415-1.414 7.78-7.779-7.78-7.793L14 6.791z"
      />
    </svg>,
  );
};

export default SvgChevronRightIcon;

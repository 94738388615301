import { ReactNode } from 'react';

import { Box } from '../box/box';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';
import { InputField, InputFieldProps } from '../input-field/input-field';

import * as styles from './phone-number-input.styles';

export interface PhoneNumberInputProps
  extends Pick<
    FieldContainerBaseProps,
    'label' | 'id' | 'helpText' | 'showRequiredIndicator' | 'disabled'
  > {
  dialCodeLabel: ReactNode;
  dialCodeInputProps: Omit<InputFieldProps, 'label'>;
  numberLabel: ReactNode;
  numberInputProps: Omit<InputFieldProps, 'label'>;
  validationVisibilityProps?: Record<
    string,
    { showValidation: boolean; onBlur: () => void }
  >;
}

export const PhoneNumberInput = ({
  dialCodeLabel,
  numberLabel,
  dialCodeInputProps: { validation: dialCodeValidation, ...dialCodeProps },
  numberInputProps: { validation: numberValidation, ...numberProps },
  helpText,
  id,
  label,
  showRequiredIndicator = false,
  validationVisibilityProps,
  disabled,
}: PhoneNumberInputProps) => {
  return (
    <FieldContainer
      id={id}
      label={label}
      labelVariant="body150"
      disabled={disabled}
      validation={[dialCodeValidation, numberValidation]}
      showValidation={
        validationVisibilityProps
          ? [dialCodeProps.id, numberProps.id].some(
              (id) => validationVisibilityProps[id]?.showValidation,
            )
          : true
      }
      showRequiredIndicator={showRequiredIndicator}
      helpText={helpText}
      variant="fieldGroup"
    >
      <Box spacing="baseNeg4" stack="row">
        <Box css={styles.dialCode}>
          <InputField
            {...dialCodeProps}
            label={dialCodeLabel}
            css={styles.dialCodeInput}
            disabled={disabled}
            showRequiredIndicator={showRequiredIndicator}
            type="tel"
            {...validationVisibilityProps?.[dialCodeProps.id]}
          />
        </Box>
        <Box css={[styles.phoneNumber]}>
          <InputField
            {...numberProps}
            label={numberLabel}
            disabled={disabled}
            showRequiredIndicator={showRequiredIndicator}
            type="tel"
            {...validationVisibilityProps?.[numberProps.id]}
          />
        </Box>
      </Box>
    </FieldContainer>
  );
};

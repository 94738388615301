import {
  createContext,
  MouseEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { animated, useSpring } from 'react-spring';

import { Box, Button, Card, Heading, useAriaId } from '@shieldpay/bumblebee';

import { CloseIcon } from '../icons';

import * as styles from './modal.style';

interface ModalCompoundProps {
  children: ReactNode;
}

export interface ModalProps {
  /**
   * When dismissable is true, adds a close button in the top-right and
   * also closes when clicking outside of the modal.
   * When dismissable is false, can only be closed by actions added
   * to the modal children, or alternatively by pressing the Escape key.
   */
  dismissable?: boolean;
  onClose: () => void;
  /**
   * Text to assist users with screen readers when the close button is focussed
   */
  closeDialogText: string;
  open: boolean;
  size?: keyof typeof styles.size;
  children: ReactNode;
}

export const TEST_IDS = {
  DIALOG: 'modal-dialog',
};

const IdContext = createContext('');

/**
 * For use as first child of <Modal />, gives the modal an accessible title
 */
const ModalTitle = ({ children }: ModalCompoundProps) => {
  const id = useContext(IdContext);

  return (
    <Heading id={id} level="2" variant="bodyMedium150">
      {children}
    </Heading>
  );
};

/**
 * For use as final child of <Modal /> to contain all action buttons
 */
const ModalActions = ({ children }: ModalCompoundProps) => (
  <Box stack="row" spacing="base" css={styles.modalActions}>
    {children}
  </Box>
);

/**
 * For use inside <ModalActions />, closes the dialog using the form
 */
const ModalCloseButton = ({ children }: ModalCompoundProps) => (
  <Button type="submit" value="close">
    {children}
  </Button>
);

export const Modal = ({
  dismissable = false,
  children,
  open,
  onClose,
  closeDialogText,
  size = 'large',
}: ModalProps) => {
  // The headingId links the modal with the heading for good a11y
  const headingId = useAriaId('modal-heading');
  const dialogRef = useRef<HTMLDialogElement>(null);

  const animatedStyles = useSpring({
    config: open
      ? {
          mass: 1,
          tension: 150,
          friction: 18,
          clamp: true,
          velocity: 0.001, // seconds
        }
      : {
          velocity: 0, // seconds
        },
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0.9)',
  });

  useEffect(() => {
    if (open) dialogRef.current?.showModal();

    // We need to track if outside state is declaring the modal closed, if so we
    // need to manually close the html dialog if open
    if (!open && dialogRef.current?.open) dialogRef.current?.close();
  }, [open]);

  const closeOnOutsideClick: MouseEventHandler<HTMLDialogElement> | undefined =
    dismissable
      ? ({ currentTarget, target }) => {
          const hasClickedOutsideModal =
            target === currentTarget && target === dialogRef.current;

          hasClickedOutsideModal && dialogRef.current?.close();
        }
      : undefined;

  return (
    <IdContext.Provider value={headingId}>
      <Box
        component={animated.dialog}
        aria-labelledby={headingId}
        css={[styles.dialog, size && styles.size[size]]}
        style={animatedStyles}
        ref={dialogRef}
        onClose={onClose}
        onClick={closeOnOutsideClick}
        data-testid={TEST_IDS.DIALOG}
      >
        <Card
          component="form"
          method="dialog"
          variant="lightShadow"
          spacing="base"
        >
          {children}
          {dismissable ? (
            <Button
              aria-label={closeDialogText}
              css={styles.close}
              variant="icon"
              value="cancel"
              type="submit"
            >
              <CloseIcon size="m" />
            </Button>
          ) : null}
        </Card>
      </Box>
    </IdContext.Provider>
  );
};

Modal.Actions = ModalActions;
Modal.Title = ModalTitle;
Modal.CloseButton = ModalCloseButton;

import { useState } from 'react';

import { DateInput, Text } from '@shieldpay/bumblebee';
import DateInputMarkdown from '@shieldpay/bumblebee/markdown/date-input.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { dayIsValid, noop, yearInRange } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const DateInputComponentPage = () => {
  usePageTitle({ id: 'hive.components.phoneNumberInput.title' });

  const [dateValues, setDateValues] = useState({
    day: '',
    month: '',
    year: '',
  });

  const invalidDay = !!dateValues.day && !dayIsValid(dateValues);
  const invalidYear = !!dateValues.year && !yearInRange(dateValues.year);

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Text paragraph>Date input</Text>
        <DateInput
          locale="en-GB"
          id="date-input-1"
          label="Date (interactive) will not show validation until values entered"
          dayInputProps={{
            id: 'day1',
            value: dateValues.day,
            onChange: (day) => setDateValues((values) => ({ ...values, day })),
            validation: {
              valid: !invalidDay,
              messages: ['Day is not valid'],
            },
          }}
          dayLabel="Day"
          dayPlaceholder="DD"
          monthInputProps={{
            id: 'month1',
            value: dateValues.month,
            onChange: (month) =>
              setDateValues((values) => ({ ...values, month })),
          }}
          monthLabel="Month"
          monthPlaceholder="MM"
          yearInputProps={{
            id: 'year1',
            value: dateValues.year,
            onChange: (year) =>
              setDateValues((values) => ({ ...values, year })),
            validation: {
              valid: !invalidYear,
              messages: ['Year is not valid'],
            },
          }}
          yearLabel="Year"
          yearPlaceholder="YY"
          validationVisibilityProps={{
            day1: {
              showValidation: invalidDay,
              onBlur: noop,
            },
            month1: { showValidation: false, onBlur: noop },
            year1: { showValidation: invalidYear, onBlur: noop },
          }}
        />

        <Text paragraph>Date input with a validation message</Text>
        <DateInput
          locale="en-GB"
          id="date-input-2"
          label="Date with validation errors (non-interactive)"
          dayInputProps={{
            id: 'day2',
            value: '44',
            onChange: noop,
            validation: {
              valid: false,
              messages: ['Day is not valid'],
            },
          }}
          dayLabel="Day"
          dayPlaceholder="DD"
          monthInputProps={{
            id: 'month2',
            value: '3',
            onChange: noop,
          }}
          monthLabel="Month"
          monthPlaceholder="MM"
          yearInputProps={{
            id: 'year2',
            value: '25BC',
            onChange: noop,
            validation: {
              valid: false,
              messages: ['Year is not valid'],
            },
          }}
          yearLabel="Year"
          yearPlaceholder="YY"
          validationVisibilityProps={{
            day2: { showValidation: true, onBlur: noop },
            month2: { showValidation: false, onBlur: noop },
            year2: { showValidation: true, onBlur: noop },
          }}
        />
      </ComponentPreview>

      <MDXCodeblockProvider>
        <DateInputMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import {
  Box,
  DataTable,
  Heading,
  TableColumns,
  TableRowLink,
  TableRows,
} from '@shieldpay/bumblebee';
import DataTableMarkdown from '@shieldpay/bumblebee/markdown/data-table.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

const rows: TableRows = [
  {
    id: 1,
    name: "Paddy's Irish Pub",
    displayName: "Paddy's",
    email: 'sunny@phili.com',
    number: '+1-555-555-5555',
  },
  {
    id: 2,
    name: 'Martian Congressional Republic Navy',
    displayName: 'MCRN',
    email: 'bobbie@rocinante.com',
    number: '+1-555-555-1234',
  },
  {
    id: 3,
    name: 'Cyberdyne Systems Corporation',
    displayName: 'Cyberdyne Systems',
    email: 'jconnor@skynet.com',
    number: '+1-555-555-3577',
  },
  {
    id: 4,
    name: 'Sirius Cybernetics Corporation',
    displayName: 'Sirius Corp.',
    email: 'complaints@sirius.com',
    number: '+1-555-555-3346',
  },

  {
    id: 5,
    name: 'Paper Street Soap Company',
    displayName: 'Paper Street',
    email: 'tdurden@soap.com',
    number: '+1-555-555-6864',
  },
  {
    id: 6,
    name: 'Combine Honnete Ober Mercantiles',
    displayName: 'CHOAM',
    email: 'melange@dune.com',
    number: '+1-555-555-4723',
  },
  {
    id: 7,
    name: "Weasleys' Wizard Wheezes",
    displayName: 'Weasleys',
    email: 'pygmypuffs@weasleys.com',
    number: '+1-555-555-8932',
  },
];

const columns: TableColumns = [
  { headerName: 'Company Name', field: 'name' },
  { headerName: 'Display Name', field: 'displayName' },
  { headerName: 'Contact email', field: 'email' },
  {
    headerName: 'Phone number',
    field: 'number',
    type: 'number',
  },
];

const columnsWithBoldTextInCell: TableColumns = [
  {
    headerName: 'Company Name',
    field: 'name',
    cellTextVariant: 'captionMedium150',
  },
  {
    headerName: 'Display Name',
    field: 'displayName',
    cellTextVariant: 'captionMedium150',
  },
  {
    headerName: 'Contact email',
    field: 'email',
    cellTextVariant: 'captionMedium150',
  },
  {
    headerName: 'Phone number',
    field: 'number',
    type: 'number',
    cellTextVariant: 'captionMedium150',
  },
];

const columnsWithLink: TableColumns = [
  { headerName: 'Company Name', field: 'name' },
  { headerName: 'Display Name', field: 'displayName' },
  { headerName: 'Contact email', field: 'email' },
  { headerName: 'Phone number', field: 'number', type: 'number' },
];

const rowLink: TableRowLink = {
  headerName: 'Take me to somewhere else',
  generatePathFromRowLink: (row) => `takeMeTo/${row.id}`,
  generateScreenReaderMessageFromRowLink: (row) =>
    `comeWithMe/${row.displayName}`,
};

export const DataTableComponentPage = () => {
  usePageTitle({ id: 'hive.components.dataTable.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Heading level="3" variant="title">
          Data table
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <DataTable
            caption="Simple Data table"
            rows={rows}
            columns={columns}
          />
        </Box>
        <Heading level="3" variant="title">
          Data table with variant options of text in columns
        </Heading>
        <Heading level="3" variant="bodyMedium150">
          The optional `variant` prop on a `ColItem` sets the text variant on
          all the data cells for that column. It is `caption150` by default
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <DataTable
            caption="Simple Data table"
            rows={rows}
            columns={columnsWithBoldTextInCell}
          />
        </Box>
        <Heading level="3" variant="title">
          Data table with interactive rows
        </Heading>
        <Box spacing="basePos3" padding="basePos3">
          <DataTable
            caption="Data table with interactive rows"
            rows={rows}
            columns={columnsWithLink}
            rowLink={rowLink}
          />
        </Box>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <DataTableMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

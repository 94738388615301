import { useState } from 'react';

import { PhoneNumberInput, Text } from '@shieldpay/bumblebee';
import PhoneNumberInputMarkdown from '@shieldpay/bumblebee/markdown/phone-number-input.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const PhoneNumberInputComponentPage = () => {
  usePageTitle({ id: 'hive.components.phoneNumberInput.title' });

  const [codeValue1, setCodeValue1] = useState('');
  const [numberValue1, setNumberValue1] = useState('');
  const [codeValue2, setCodeValue2] = useState('abc');
  const [numberValue2, setNumberValue2] = useState(
    'this is not a phone number',
  );

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Text paragraph>Phone number input with help</Text>
        <PhoneNumberInput
          dialCodeLabel="Country code"
          numberLabel="Phone number"
          dialCodeInputProps={{
            id: 'code1',
            value: codeValue1,
            onChange: setCodeValue1,
          }}
          numberInputProps={{
            id: 'number1',
            value: numberValue1,
            onChange: setNumberValue1,
          }}
          id="phone-number"
          helpText="This is some help; how can I help you?"
        />
        <Text paragraph>Phone number input with a validation message</Text>

        <PhoneNumberInput
          dialCodeLabel="Country code"
          numberLabel="Phone number"
          dialCodeInputProps={{
            id: 'code2',
            value: codeValue2,
            onChange: setCodeValue2,
          }}
          numberInputProps={{
            id: 'number2',
            value: numberValue2,
            onChange: setNumberValue2,
            validation: {
              messages: ['Oh no you put something wrong in the field'],
              valid: false,
            },
          }}
          id="phone-number2"
          validationVisibilityProps={{
            code2: { showValidation: false, onBlur: noop },
            number2: { showValidation: true, onBlur: noop },
          }}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The phone number input component is the interactive component used
            to capture a user’s phone number.
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            The form field height is constructed based on the ‘Field input‘ text
            style and padding.
          </Text>
          <Text paragraph>
            The width of the form field is flexible, unless specifically
            constrained by a defined value or alignment. The width of the form
            field should match the width of the field label text box across all
            viewport sizes, unless otherwise specified.
          </Text>
          <Text paragraph>
            The form field has a 1px border with 4px rounded corners to be
            consistent with the buttons.
          </Text>
          <Text paragraph>
            When text is entered/displayed in the form field it uses the ‘Field
            input’ style (16px) across all viewports.
          </Text>
          <Text paragraph>
            It is not proposed to display a disabled state for an empty field.
            If a field is not required it should be hidden.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            The state of the form field changes depending on the actions of the
            user. The structure of how the instances behave are outlined, right.
          </Text>
          <Text paragraph>
            Transition to display a state change with a 0.25 ease.
          </Text>
          <Text paragraph>
            In the idle state, the dropdown should only display the country code
            to save space. However in the dropdown option list, we should have a
            full standardised list of countries in alphabetical order.
          </Text>
          <Text paragraph>Future considerations:</Text>
          <Text paragraph>Searching by country name</Text>
          <Text paragraph>Flags</Text>
          <Text paragraph>UK and US at the top of the list</Text>
          <Text paragraph>Defaulting yb IP address location</Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            The focused state is used to help accessibility and is consistent
            with the focus state used on other components. Use the appropriate
            aria attributes for screen readers where applicable.
          </Text>
          <Text paragraph>
            The ‘Text’ style colour used on entered text in the field, is
            comfortably within the AAA contrast ratio for legibility.
          </Text>
          <Text paragraph>
            Placeholder text will not be used within text fields. Placeholder
            text has to be dark enough to pass AA rating for legibility. It can
            confuse users who may think the field is already completed. When a
            detailed description or an example is required to complement a field
            label, ‘Helper text’ will be used.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <PhoneNumberInputMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Heading,
  Pagination,
  PaginationLegacy,
  TextList,
} from '@shieldpay/bumblebee';
import PaginationMarkdown from '@shieldpay/bumblebee/markdown/pagination.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

const paginationMock = {
  pageSize: 5,
  total: 20,
  offset: 0,
  limit: 5,
};

const mockData = [...Array(paginationMock.total + 1).keys()].slice(1);

export const PaginationComponentPage = () => {
  usePageTitle({ id: 'hive.components.pagination.title' });

  const [pagination, setPagination] = useState(paginationMock);

  const currentTextList = mockData.slice(
    pagination.offset,
    pagination.offset + pagination.pageSize,
  );

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <TextList>
          {currentTextList.map((item, index) => {
            return <TextList.Item key={index}>{item}</TextList.Item>;
          })}
        </TextList>
        <Heading level="3" variant="headline2">
          Buttons
        </Heading>
        <PaginationLegacy
          {...pagination}
          onOffsetChange={(offset) =>
            setPagination((pagination) => ({ ...pagination, offset }))
          }
          paginationPageResults={({ from, to, total }) => (
            <FormattedMessage
              id="hive.components.pagination.results"
              values={{
                from: new Intl.NumberFormat().format(from),
                to: new Intl.NumberFormat().format(to),
                total: new Intl.NumberFormat().format(total),
              }}
            />
          )}
        />
        <Heading level="3" variant="headline2">
          Anchors
        </Heading>
        <Pagination
          {...pagination}
          createTo={(offset) => `#${offset}`}
          paginationPageResults={({ from, to, total }) => (
            <FormattedMessage
              id="hive.components.pagination.results"
              values={{
                from: new Intl.NumberFormat().format(from),
                to: new Intl.NumberFormat().format(to),
                total: new Intl.NumberFormat().format(total),
              }}
            />
          )}
        />
      </ComponentPreview>

      <MDXCodeblockProvider>
        <PaginationMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

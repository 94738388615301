import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgLogOutIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6a3 3 0 013-3h12v2H7a1 1 0 00-1 1v20a1 1 0 001 1h12v2H7a3 3 0 01-3-3V6z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 17H13v-2h15v2z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.586 16l-4.793-4.793 1.414-1.414L29.414 16l-6.207 6.207-1.414-1.414L26.586 16z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgLogOutIcon;

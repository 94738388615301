import { useMemo } from 'react';
import { css, CSSObject, Theme, useTheme } from '@emotion/react';

import {
  Breakpoint,
  Spacing,
  Template,
  TemplateItems,
  ThemeTokens,
} from '../../themes/types';
import {
  isResponsive,
  isSpacing,
  responsiveStyle,
} from '../../themes/utilities';

import * as styles from './use-grid-styles.styles';

export interface useGridStylesProps {
  /**
   * Temporary static layouts to be replaced by a dynamic solution.
   */
  layout: keyof ThemeTokens['layout'];
  /**
   * Spacing between items inside the grid.
   */
  spacing?: Spacing;
  /**
   * Make the item at least the height of parent
   */
  fullHeight?: boolean;
}

/*
 * Accepts either a Template type:
 *   ['1fr', 440, 'min-content]
 *
 * or a responsive version:
 *   {
 *      xs: ['1fr', '1fr'],
 *      m: [200, 'min-content']
 *   }
 *
 * …and returns a CSSObject.
 */
export const templateItemsToCSSObject = (
  theme: Theme,
  template: TemplateItems,
  axis: 'gridTemplateColumns' | 'gridTemplateRows',
): CSSObject => {
  if (isResponsive<Template[]>(template)) {
    /* if responsive, call function recursively */
    return responsiveStyle(
      (Object.entries(template) as [Breakpoint, TemplateItems][]).map(
        ([breakpoint, items]) => ({
          breakpoint,
          styles: templateItemsToCSSObject(theme, items, axis),
        }),
      ),
      theme,
    );
  }

  return {
    [axis]: template
      .map((item) => {
        if (typeof item === 'number') return `${item}px`;
        if (isSpacing(item, theme)) return `${theme.spacing[item]}px`;

        // If none of the above, return the string-like value (e.g. 'min-content')
        // or Fractional Unit (e.g. 1FR)
        return item;
      })
      .join(' '),
  };
};

export const convertLayoutToSerializedStyles = (
  theme: Theme,
  layout: keyof ThemeTokens['layout'],
) => {
  const { columns, rows } = theme.layout[layout] || {
    columns: undefined,
    rows: undefined,
  };
  return [
    columns &&
      css(templateItemsToCSSObject(theme, columns, 'gridTemplateColumns')),
    rows && css(templateItemsToCSSObject(theme, rows, 'gridTemplateRows')),
  ];
};

export const useGridStyles = ({
  spacing,
  fullHeight = false,
  layout,
}: useGridStylesProps) => {
  const theme = useTheme();

  return useMemo(
    () => [
      styles.base,
      fullHeight && styles.fullHeight,
      layout && convertLayoutToSerializedStyles(theme, layout),
      spacing && styles.spacing(theme)[spacing],
    ],
    [fullHeight, layout, spacing, theme],
  );
};

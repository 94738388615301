import { useMemo } from 'react';
import { Interpolation, Theme, useTheme } from '@emotion/react';

import { IconSize } from '../../components/icon/icon';
import { Spacing } from '../../themes/types';

import * as buttonStyles from './button.styles';
import * as linkStyles from './link.styles';

type TextVariant = keyof Theme['typography']['variant'] | 'inherit';

export type LinkVariant = keyof ReturnType<typeof linkStyles.variant>;
export type ButtonSize = 'small' | 'medium' | 'large';

export type LinkSize = 'inherit' | 'small' | 'medium';

interface Button {
  variant: keyof ReturnType<typeof buttonStyles.variant>;
  size: ButtonSize;
}

interface Link {
  variant: LinkVariant;
  size: LinkSize;
}

export type LinkOrButton = Button | Link;

export type UseClickableElementStylesOptions = LinkOrButton & {
  disabled?: boolean;
};

/**
 * When the variant is not a 'link' we need to map the button size to correct text element.
 */
export const textVariant: Record<string, TextVariant> = {
  small: 'captionMedium',
  medium: 'bodyMedium',
  large: 'title',
};

export const linkTextVariant: Record<string, TextVariant> = {
  small: 'caption',
  large: 'body',
};

const isButton = (type: LinkOrButton, theme: Theme): type is Button =>
  Object.keys(buttonStyles.variant(theme)).includes(type.variant);

const iconSizeMap: Record<ButtonSize | LinkSize, IconSize> = {
  inherit: 's',
  small: 's',
  medium: 'm',
  large: 'l',
};

const buttonPaddingMap: Record<ButtonSize, { x: Spacing; y: Spacing }> = {
  small: { x: 'base', y: 'baseNeg3' },
  medium: { x: 'basePos2', y: 'baseNeg1' },
  large: { x: 'basePos3', y: 'basePos1' },
};

/**
 * Hook that returns a combination of
 * - styles
 * - padding separated into x and y values
 * - reset styles, either for anchor or button
 * - textVariant to be passed to the <Text /> component
 * to be used on either button or anchor elements.
 */
export const useClickableElementStyles = ({
  size,
  variant,
  disabled = false,
}: UseClickableElementStylesOptions): {
  styles: Interpolation<Theme>;
  padding: { x: Spacing; y: Spacing };
  iconSize: IconSize;
  /** For use when link styles applied to a button */
  buttonResetStyles?: Interpolation<Theme>;
  /** For use when button styles applied to a link */
  linkResetStyles?: Interpolation<Theme>;
  textVariant: TextVariant;
} => {
  const theme = useTheme();

  return useMemo(() => {
    const variantAndSize: Button | Link = {
      variant,
      size,
    } as LinkOrButton;
    const iconSize = iconSizeMap[variantAndSize.size];

    if (isButton(variantAndSize, theme)) {
      return {
        styles: [
          buttonStyles.button,
          buttonStyles.variant(theme)[variantAndSize.variant],
          disabled && buttonStyles.disabled(theme)[variantAndSize.variant],
        ],
        padding: buttonPaddingMap[variantAndSize.size],
        iconSize,
        textVariant: textVariant[variantAndSize.size],
        linkResetStyles: [linkStyles.linkAsButtonResets],
      };
    }

    return {
      styles: [
        variant && linkStyles.variant(theme)[variantAndSize.variant],
        disabled && linkStyles.linkState(theme).disabled,
      ],
      padding: { x: 'none', y: 'none' },
      iconSize,
      textVariant:
        variantAndSize.size === 'inherit'
          ? 'inherit'
          : linkTextVariant[variantAndSize.size ?? 'small'],
      buttonResetStyles: [buttonStyles.buttonAsLinkResets],
    };
  }, [variant, theme, disabled, size]);
};

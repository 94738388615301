import {
  darken as polishedDarken,
  lighten as polishedLighten,
  transparentize as polishedTransparentize,
} from 'polished';
import { css, CSSObject, Interpolation, Theme } from '@emotion/react';

import { transformValues } from '@shieldpay/utility-functions-ui';

import {
  Breakpoint,
  NestedCSSObject,
  Responsive,
  ResponsiveProps,
  ResponsiveStyles,
  Spacing,
  Style,
  ThemeCallback,
} from './types';

/**
 * Lighten a hex colour using an integer that corresponds with design scales (e.g. 20, 40, 65)
 */
export const lighten = (hex: string, amount: number): string =>
  polishedLighten(amount / 100, hex);

/**
 * Darken a hex colour using an integer that corresponds with design scales (e.g. 20, 40, 65)
 */
export const darken = (hex: string, amount: number): string =>
  polishedDarken(amount / 100, hex);

/**
 * Set the transparency of a hex colour using an integer that corresponds with design scales (e.g. 20, 40, 65)
 */
export const transparentize = (hex: string, amount: number): string =>
  polishedTransparentize((100 - amount) / 100, hex);

/**
 * Generate a style inside a media query using a `min-width` breakpoint value.
 */
export const mediaQueryStyle = (breakpoint: number, style: CSSObject) => ({
  [`@media screen and (min-width: ${breakpoint}px)`]: style,
});

/**
 * Generate a set of responsive styles using breakpoint values from theme.
 * smallest breakpoint is set without a media query
 */
export const responsiveStyle = (
  styles: ResponsiveStyles<CSSObject>,
  { breakpoints }: Theme,
) =>
  styles.reduce(
    (responsiveStyles, { breakpoint, styles }) => ({
      ...responsiveStyles,
      ...(breakpoint === 'xs'
        ? styles
        : mediaQueryStyle(breakpoints[breakpoint], styles)),
    }),
    {},
  );

export const focusRingShadowProperty = (
  { spacing, palette }: Theme,
  size: Spacing = 'baseNeg6',
  inset = false,
) => `${inset ? 'inset ' : ''}0 0 0 ${spacing[size]}px ${palette.primary400}`;

export const focusRingStyle = (theme: Theme) => ({
  style: {
    outline: 'none',
    boxShadow: focusRingShadowProperty(theme),
  },
  transition: `box-shadow ${theme.transitions.standard}`,
});

export const spacingStyle = (spacing: Theme['spacing']) =>
  transformValues(spacing, (value) =>
    css({
      gap: `${value}px`,
    }),
  );

/**
 * Accepts a callback function for theming css objects; this curries
 * in the Emotion css function to automatically convert to SerializedStyles
 */
export const themeifyStyle =
  (callback: ThemeCallback<CSSObject>) => (theme: Theme) =>
    css(callback(theme));

/**
 * Accepts a callback function for theming objects of css objects; this curries
 * in the emotion css function to automatically convert to SerializedStyles
 * returning an array containing the standard and responsive style objects.
 *
 *   const [style, styleResponsive] = createThemedStyleObjects((theme) => ({
 *     enabled: { color: theme.palette.primary },
 *     disabled: { color: theme.palette.primaryLighten50}
 *   }));
 *
 *   <Component css={
 *      disabled ?
 *        styleResponsive(theme)[breakpoint].disabled
 *        styleResponsive(theme)[breakpoint].enabled
 *      }
 *   />
 */
export const createThemedStyleObjects = <Key extends string>(
  cssObjects: ThemeCallback<NestedCSSObject<Key>>,
): [ThemeCallback<Style<Key>>, ThemeCallback<Responsive<Style<Key>>>] => [
  (theme) => nestedCSSObjectToStyles(cssObjects(theme)),
  (theme) =>
    responsiveNestedCSSObjectToStyles(theme, cssObjects(theme)) as Responsive<
      Style<Key>
    >,
];

export const breakpoints: Breakpoint[] = ['xs', 's', 'm', 'l', 'xl', 'xxl'];

/**
 * typeguard for responsive values
 */
export const isResponsive = <Value>(
  value: Value | Partial<Responsive<Value>>,
): value is Partial<Responsive<Value>> =>
  typeof value !== 'string' &&
  Object.keys(value as Responsive<Value>).some((key) =>
    breakpoints.includes(key as Breakpoint),
  );

/**
 * Iterates over a nested object of Emotion CSSObjects and runs them through Emotion’s css function
 * to return nested SerializedStyles
 */
const nestedCSSObjectToStyles = <Keys extends string>(
  object: NestedCSSObject<Keys>,
): Style<Keys> => transformValues(object, css);

/**
 * Iterates over a nested object of Emotion CSSObjects and runs them through Emotion’s css function
 * returning nested SerializedStyles for all media breakpoints
 */
export const responsiveNestedCSSObjectToStyles = <Keys extends string>(
  theme: Theme,
  styles: NestedCSSObject<Keys>,
): Responsive<Style<Keys>> =>
  breakpoints.reduce(
    (acc, breakpoint) => ({
      ...acc,
      [breakpoint]: transformValues(styles, (styleOrObject) => {
        const mediaQuery = theme.breakpoints[breakpoint];

        return css(mediaQueryStyle(mediaQuery, styleOrObject));
      }),
    }),
    {} as Responsive<Style<Keys>>,
  );

/**
 * Type friendly function to help use responsiveProps to get SerializedStyles
 */
export const responsivePropsToStyles = <T>(
  responsiveProps: ResponsiveProps<T>,
  callback: (prop: [Breakpoint, T]) => Interpolation<Theme>,
) => (Object.entries(responsiveProps) as [Breakpoint, T][]).map(callback);

/**
 * typeguard for spacing values
 */
export const isSpacing = (
  value: string,
  { spacing }: Theme,
): value is Spacing => Object.keys(spacing).includes(value);

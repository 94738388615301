import { FormControl, FormControlProps } from '../form-control/form-control';

import * as styles from '../form-control/form-control.styles';

export interface RadioButtonProps
  extends Omit<FormControlProps, 'children' | 'type' | 'value'> {
  value: string;
}

export const TEST_IDS = {
  OVERLAY_SVG: 'radio-button-overlay-svg',
};

export const RadioButton = ({
  id,
  onClick,
  label,
  name,
  checked = false,
  'data-testid': testId,
  disabled = false,
  value,
}: RadioButtonProps) => {
  return (
    <FormControl
      data-testid={testId}
      id={id}
      name={name}
      onClick={onClick}
      label={label}
      checked={checked}
      disabled={disabled}
      type="radio"
      value={value}
    >
      <svg
        css={styles.fauxInputSvg}
        data-testid={TEST_IDS.OVERLAY_SVG}
        width="10"
        height="10"
        viewBox="0 0 10 10"
      >
        <path d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5Z" />
      </svg>
    </FormControl>
  );
};

import { useState } from 'react';

import { CardControls, Text, TextList } from '@shieldpay/bumblebee';
import { AlertIcon } from '@shieldpay/bumblebee/icons';
import CardControlsMarkdown from '@shieldpay/bumblebee/markdown/card-controls.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

const options = [
  {
    label: (
      <>
        <CardControls.Heading Icon={AlertIcon}>juan</CardControls.Heading>
        <Text paragraph>Choose this variant if you want one</Text>
      </>
    ),
    value: 'one',
  },
  {
    label: (
      <>
        <CardControls.Heading Icon={AlertIcon}>double</CardControls.Heading>
        <TextList>
          <TextList.Item>Choose this if…</TextList.Item>
          <TextList.Item>You want two</TextList.Item>
        </TextList>
      </>
    ),
    value: 'two',
  },
];

export const CardControlsComponentPage = () => {
  usePageTitle({ id: 'hive.components.cardControls.title' });

  const [value, setValue] = useState<string | undefined>();
  const [value2, setValue2] = useState(options[1].value);

  return (
    <>
      <ContentsTable />
      <ComponentPreview alignItems={['left', 'top']} spacing="basePos7">
        <CardControls
          value={value}
          options={options}
          id="micCheckUhUhUh"
          name="no-initial"
          label="No initial selection"
          onChange={setValue}
        />
        <CardControls
          value={value2}
          options={options}
          id="micDrop"
          name="initial"
          label="Value selected initially"
          onChange={setValue2}
        />
        <CardControls
          options={options}
          id="mic"
          name="error"
          label="Validation error"
          onChange={noop}
          validation={{
            messages: ['Intentionally unselectable to illustrate validation'],
            valid: false,
          }}
          showValidation={true}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading={<>Usage</>}>
          <Text paragraph>Uses in desktop and mobile.</Text>
          <Text paragraph>
            Only to be used when only a single option can be selected from a
            TextList.
          </Text>
          <Text paragraph>
            Focus state has the same 4px outline as per the buttons.
          </Text>
          <Text paragraph>
            Hover state has card shadow and purple stroke, selected - a tick.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <CardControlsMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { ElementType, ReactNode } from 'react';

import { Box } from '../box/box';
import { Card } from '../card/card';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';
import { TickIcon } from '../icons';
import { Text } from '../text/text';

import * as styles from './card-controls.styles';

export interface CardControlsProps<Value = string>
  extends FieldContainerBaseProps {
  value?: Value;
  onChange: (value: Value) => void;
  options: Array<{ value: Value; label: ReactNode }>;
  name: string;
}

interface CardControlsHeadingProps {
  children: ReactNode;
  Icon: ElementType;
  'data-testid'?: string;
}

export const CardControlsHeading = ({
  children,
  Icon,
  'data-testid': testId,
}: CardControlsHeadingProps) => (
  <Box stack="row" alignItems={['left', 'center']} spacing="baseNeg4">
    <Icon size="m" css={styles.icon} data-testid={testId} />
    <Text paragraph variant="bodyMedium150">
      {children}
    </Text>
  </Box>
);

export const CardControls = <Value extends string>({
  value,
  name,
  options,
  onChange,
  label,
  helpText,
  disabled = false,
  id,
  validation,
  showValidation = false,
}: CardControlsProps<Value>) => (
  <FieldContainer
    id={id}
    label={label}
    labelVariant="body150"
    variant="cardControls"
    validation={validation}
    showValidation={showValidation}
    disabled={disabled}
    helpText={helpText}
  >
    <Box alignItems={['full', 'top']} spacing="basePos1">
      {options.map(({ label, value: option }) => {
        const inputId = `${id}-${option}`;
        return (
          <Box key={option} alignItems={['full', 'top']}>
            <Box
              component="input"
              screenReaderOnly
              css={styles.input}
              id={inputId}
              checked={value === option}
              name={name}
              value={value}
              type="radio"
              onChange={() => onChange(option)}
            />
            <Card
              padding={{ y: 'basePos3', left: 'basePos3', right: 'baseNeg1' }}
              spacing="baseNeg6"
              component="label"
              alignItems={['full', 'top']}
              stack="row"
              css={[styles.label]}
              htmlFor={inputId}
              variant="lightShadow"
              onClick={
                // TODO - why do we need to stop propagation here?
                /* istanbul ignore next */
                (e) => {
                  e.stopPropagation();
                }
              }
            >
              <Box spacing="baseNeg1" alignItems={['left', 'top']}>
                {label}
              </Box>
              <TickIcon css={[styles.tick]} color="primary500" />
            </Card>
          </Box>
        );
      })}
    </Box>
  </FieldContainer>
);

CardControls.Heading = CardControlsHeading;

import { ElementType } from 'react';

import { Box, Heading, Table, Text } from '@shieldpay/bumblebee';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  InfoIcon,
  ShowIcon,
} from '@shieldpay/bumblebee/icons';

export const ButtonAppearance = ({ Component }: { Component: ElementType }) => (
  <Box spacing="base">
    <Heading level="3" variant="title">
      Button styled links
    </Heading>
    <Table caption="buttons styles" css={{ tableLayout: 'fixed' }}>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell>Small button</Table.HeaderCell>
          <Table.HeaderCell>Medium button</Table.HeaderCell>
          <Table.HeaderCell>Large button</Table.HeaderCell>
        </Table.Row>
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component href="#" variant="solid" size="small">
                Solid button
              </Component>
            </Box>
          </Table.DataCell>

          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component href="#" variant="solid" size="medium">
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component href="#" variant="solid" size="large">
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="solid"
                size="small"
                LeftIcon={InfoIcon}
                RightIcon={ChevronRightIcon}
              >
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="solid"
                size="medium"
                LeftIcon={InfoIcon}
                RightIcon={ChevronRightIcon}
              >
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="solid"
                size="large"
                LeftIcon={InfoIcon}
                RightIcon={ChevronRightIcon}
              >
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="solid"
                size="small"
                RightIcon={InfoIcon}
              >
                Solid button
              </Component>
            </Box>
          </Table.DataCell>

          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="solid"
                size="medium"
                RightIcon={InfoIcon}
              >
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="solid"
                size="large"
                RightIcon={InfoIcon}
              >
                Solid button
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                disabled
                href="#"
                variant="solid"
                size="small"
                LeftIcon={InfoIcon}
              >
                Solid disabled
              </Component>
            </Box>
          </Table.DataCell>

          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                disabled
                href="#"
                variant="solid"
                size="medium"
                LeftIcon={InfoIcon}
              >
                Solid disabled
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                disabled
                href="#"
                variant="solid"
                size="large"
                LeftIcon={InfoIcon}
              >
                Solid disabled
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell align="left">
            <Box alignItems={['left', 'top']}>
              <Component href="#" variant="outline" size="small">
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component href="#" variant="outline" size="medium">
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component href="#" variant="outline" size="large">
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="outline"
                size="small"
                LeftIcon={InfoIcon}
                RightIcon={ChevronRightIcon}
              >
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="outline"
                size="medium"
                LeftIcon={InfoIcon}
                RightIcon={ChevronRightIcon}
              >
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="outline"
                size="large"
                LeftIcon={InfoIcon}
                RightIcon={ChevronRightIcon}
              >
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="outline"
                size="small"
                RightIcon={InfoIcon}
              >
                Outline button
              </Component>
            </Box>
          </Table.DataCell>

          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="outline"
                size="medium"
                RightIcon={InfoIcon}
              >
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                href="#"
                variant="outline"
                size="large"
                RightIcon={InfoIcon}
              >
                Outline button
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                disabled
                href="#"
                variant="outline"
                size="small"
                LeftIcon={InfoIcon}
              >
                Outline disabled
              </Component>
            </Box>
          </Table.DataCell>

          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                disabled
                href="#"
                variant="outline"
                size="medium"
                LeftIcon={InfoIcon}
              >
                Outline disabled
              </Component>
            </Box>
          </Table.DataCell>
          <Table.DataCell>
            <Box alignItems={['left', 'top']}>
              <Component
                disabled
                href="#"
                variant="outline"
                size="large"
                LeftIcon={InfoIcon}
              >
                Outline disabled
              </Component>
            </Box>
          </Table.DataCell>
        </Table.Row>
      </Table.Body>
    </Table>

    <Heading level="3" variant="title">
      Icon buttons
    </Heading>
    <Box
      spacing="basePos3"
      padding={{ y: 'basePos3' }}
      stack="row"
      alignItems={['left', 'center']}
    >
      <Component href="#" size="small" variant="icon" aria-label="show more">
        <ChevronDownIcon />
      </Component>
      <Component href="#" size="large" variant="icon">
        <Text paragraph screenReaderOnly>
          Show password
        </Text>
        <ShowIcon />
      </Component>
      <Component href="#" size="large" variant="icon" aria-label="close">
        <CloseIcon />
      </Component>
    </Box>
  </Box>
);

import { Card } from '@shieldpay/bumblebee';
import {
  Box,
  Heading,
  LoadingSpinner,
  Text,
  TextList,
} from '@shieldpay/bumblebee';
import LoadingSpinnerMarkdown from '@shieldpay/bumblebee/markdown/loading-spinner.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const LoadingSpinnerComponentPage = () => {
  usePageTitle({ id: 'hive.components.loadingSpinner.title' });

  return (
    <>
      <ComponentPreview>
        <Heading level="3" variant="title">
          Standard spinner
        </Heading>

        <Box
          stack="row"
          spacing="base"
          padding="basePos3"
          alignItems={['full', 'center']}
        >
          <LoadingSpinner size="xxlarge" />
          <LoadingSpinner size="xlarge" />
          <LoadingSpinner size="large" />
          <LoadingSpinner />
          <LoadingSpinner size="small" />
        </Box>

        <Heading level="3" variant="title">
          Secondary spinner
        </Heading>

        <Box
          stack="row"
          spacing="base"
          padding="basePos3"
          alignItems={['full', 'center']}
        >
          <LoadingSpinner color="secondary" size="xxlarge" />
          <LoadingSpinner color="secondary" size="xlarge" />
          <LoadingSpinner color="secondary" size="large" />
          <LoadingSpinner color="secondary" />
          <LoadingSpinner color="secondary" size="small" />
        </Box>

        <Heading level="3" variant="title">
          White spinner
        </Heading>
        <Card
          stack="row"
          spacing="base"
          padding="basePos3"
          alignItems={['full', 'center']}
          background="primary500"
        >
          <LoadingSpinner color="white" size="xxlarge" />
          <LoadingSpinner color="white" size="xlarge" />
          <LoadingSpinner color="white" size="large" />
          <LoadingSpinner color="white" />
          <LoadingSpinner color="white" size="small" />
        </Card>

        <Heading level="3" variant="title">
          Centered spinner
        </Heading>

        <Card
          alignItems={['full', 'center']}
          css={{ height: 200 }}
          background="primary500"
        >
          <LoadingSpinner size="xxlarge" color="white" center />
        </Card>
      </ComponentPreview>

      <Design>
        <Design.Section heading={<>Usage</>}>
          <Text paragraph>
            The loading spinner should only be used when the data, content of
            action is loading. For example, when:
          </Text>
          <TextList>
            <TextList.Item>
              The action after component interaction is loading (i.e. form
              submit)
            </TextList.Item>
            <TextList.Item>
              A full page is loading (i.e. project details){' '}
            </TextList.Item>
            <TextList.Item>
              The secondary content of the page is loading (i.e. a table in
              project details)
            </TextList.Item>
            <TextList.Item>
              The content of a page needs to fully reload due to a modal action
              (i.e. document upload)
            </TextList.Item>
            <TextList.Item>
              The content of a modal is loading (i.e. Are you sure)
            </TextList.Item>
            <TextList.Item>
              The secondary content of the page is updating (i.e. hammering next
              on the pagination)
            </TextList.Item>
          </TextList>
        </Design.Section>
        <Design.Section heading={<>Anatomy</>}>
          <Text paragraph>
            The loading spinner consist of 1 line with a smooth gradient
            applied. This should spin clockwise as per the example in the
            prototype. The spinner is positioned to be optically centered.{' '}
          </Text>
          <Text paragraph>There are 5 sizes:.</Text>
          <TextList>
            <TextList.Item>
              80px x 80px to be used on pages, panels and page content{' '}
            </TextList.Item>
            <TextList.Item>
              48px x 48px to be used on modal dialogs{' '}
            </TextList.Item>
            <TextList.Item>
              32px x 32px to be used on Large buttons and inputs
            </TextList.Item>
            <TextList.Item>
              24px x 24px to be used on Medium buttons and inputs
            </TextList.Item>
            <TextList.Item>
              20px x 20px to be used on Small buttons and inputs
            </TextList.Item>
          </TextList>
        </Design.Section>
        <Design.Section heading={<>Behaviour</>}>
          <Text paragraph>
            The loading spinner appear temporarily, and disappear on their own
            without requiring user input to be dismissed. The loading spinner
            cannot be clicked or tapped.
          </Text>
          <Text paragraph>Time</Text>
          <Text paragraph>
            The loading spinner should only be displayed if the data, content or
            action is taking l between 1 and 4 seconds to load.
          </Text>
          <Text paragraph>Animation</Text>
          <Text paragraph>
            The loading spinner should spin clockwise so that one rotation takes
            1 second.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <LoadingSpinnerMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

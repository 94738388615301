import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgPersonIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.527 26.498c2.555 0 7.032-.002 10.498 0 3.477.003 7.939.002 10.475.001v-3.143a3.01 3.01 0 00-.248-.384 10.007 10.007 0 00-1.072-1.187c-.926-.895-2.13-1.845-3.294-2.612-1.38-.911-3.506-1.673-5.85-1.673s-4.47.762-5.85 1.673c-1.184.78-2.41 1.75-3.34 2.658-.468.455-.825.861-1.062 1.19a2.765 2.765 0 00-.216.343l-.041 3.134zm-1.955-3.429l-.059 4.416a.998.998 0 00.998 1.013c2.196 0 7.524-.002 11.513 0 4 .003 9.302.002 11.479.001a.998.998 0 00.997-1v-4.357a.717.717 0 00-.012-.144c-.284-1.393-2.995-3.842-5.5-5.495-1.697-1.119-4.203-2.003-6.952-2.003-2.749 0-5.255.884-6.951 2.003-2.55 1.681-5.31 4.187-5.513 5.566zM20.073 8.587A4.102 4.102 0 0015.987 4.5 4.102 4.102 0 0011.9 8.587a4.102 4.102 0 004.087 4.086 4.102 4.102 0 004.086-4.086zM15.987 2.5c3.35 0 6.086 2.737 6.086 6.087 0 3.35-2.737 6.086-6.086 6.086-3.35 0-6.087-2.737-6.087-6.086 0-3.35 2.737-6.087 6.087-6.087z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgPersonIcon;

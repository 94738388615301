import { themeifyStyle } from '../../themes/utilities';

export const tag = themeifyStyle(({ spacing }) => ({
  // TODO: min/max width on box prop perhaps?
  minWidth: `${spacing.basePos9}px`,
  maxWidth: `${spacing.basePos12}px`,
}));

export const variant = {
  primary: themeifyStyle(({ palette }) => ({
    backgroundColor: `${palette.primary200}`,
  })),
  warning: themeifyStyle(({ palette }) => ({
    backgroundColor: `${palette.warning200}`,
  })),
  alert: themeifyStyle(({ palette }) => ({
    backgroundColor: `${palette.alert200}`,
  })),
  success: themeifyStyle(({ palette }) => ({
    backgroundColor: `${palette.success200}`,
  })),
};

import { css, Theme } from '@emotion/react';

import {
  createThemedStyleObjects,
  focusRingStyle,
} from '../../themes/utilities';

const commonLinkStyles = (theme: Theme) => ({
  color: theme.palette.primary500,
  borderRadius: theme.borderRadius,
  display: 'inline-flex',
  ':focus': {
    ...focusRingStyle(theme).style,
    transition: focusRingStyle(theme).transition,
  },
});

export const [variant] = createThemedStyleObjects((theme) => ({
  'text-link': {
    textDecoration: 'none',
    transition: `color ${theme.transitions.standard}`,
    ':hover,:focus': {
      color: theme.palette.primary600,
    },
    ...commonLinkStyles(theme),
  },
  'text-underline': {
    textDecoration: 'underline',
    textUnderlineOffset: `${theme.spacing.baseNeg7}px`,
    textDecorationColor: theme.palette.primary400,
    transition: `text-decoration-color ${theme.transitions.standard}, color ${theme.transitions.standard}`,
    ':hover,:focus': {
      textDecorationColor: theme.palette.primary600,
      color: theme.palette.primary600,
    },
    ...commonLinkStyles(theme),
  },
  'text-with-icon': {
    textDecoration: 'none',
    gap: `${theme.spacing.baseNeg5}px`,
    transition: `color ${theme.transitions.standard}`,
    ':hover,:focus': {
      color: theme.palette.primary600,
    },
    ...commonLinkStyles(theme),
  },
}));

export const linkAsButtonResets = css({
  textDecoration: 'none',
  textAlign: 'center',
});

export const [linkState] = createThemedStyleObjects((theme) => ({
  disabled: {
    '&,&:focus,&:visited,&:hover': {
      color: theme.palette.primary400,
      textDecorationColor: theme.palette.primary400,
    },
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
}));

import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill'; // polyfill for smooth-scrolling on safari

import { Heading, LoadingSpinner } from '@shieldpay/bumblebee';

import { Layout } from './components/layout/layout';
import { componentsPath, routes } from './routes';

smoothscroll.polyfill();

const {
  home: { Component: HomeComponent, path: homePath },
} = routes;

export const App = () => (
  <Layout>
    <Suspense fallback={<LoadingSpinner size="xxlarge" center />}>
      <Routes>
        <Route path={homePath} element={<HomeComponent />} />
        <Route path={componentsPath}>
          <Route path="hello" element={<p>hello</p>} />
          {Object.entries(routes.components).map(
            ([key, { path, title, Component }]) => (
              <Route
                key={key}
                path={`${componentsPath}/${path}`}
                element={
                  <>
                    <Heading>{title}</Heading>
                    <Component />
                  </>
                }
              ></Route>
            ),
          )}
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  </Layout>
);

import { useState } from 'react';

import { PasswordInput, Text } from '@shieldpay/bumblebee';
import PasswordInputMarkdown from '@shieldpay/bumblebee/markdown/password-input.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const PasswordInputComponentPage = () => {
  usePageTitle({ id: 'hive.components.passwordInput.title' });

  const [value, setValue] = useState('');

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <PasswordInput
          id="passwordInput1"
          label="Enter your super-secret password"
          showPasswordText="Show password"
          hidePasswordText="Hide password"
          value={value}
          onChange={(value) => setValue(value)}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The show / hide password is used for password creation (at first log
            in or when requesting to reset password) and password entry, (at log
            in, or whenever password is required to perform a task).
          </Text>
        </Design.Section>
        <Design.Section heading="Structure">
          <Text paragraph>
            The Show / Hide button sits within the form field and lets the user
            show and hide their entered password. The control is only visible
            when the field is active.
          </Text>
          <Text paragraph>
            This has been ignored for now (COM-331). To get it over the line, we
            have decided to keep this control visible at all times within the
            field.
          </Text>
          <Text paragraph>
            Structure It is constructed using two SVG icons drawn at size L (32
            px) and displayed at size M (24px).
          </Text>
        </Design.Section>
        <Design.Section heading="Interaction">
          <Text paragraph>
            The state of the form field changes depending on the actions of the
            user. The structure of how the instances behave are outlined, right.
          </Text>
          <Text paragraph>
            Upon losing focus the password should retain the masked/unmasked
            state that the user has set using the Show/hide button.
          </Text>
          <Text paragraph>
            Transition to display a state change with a 0.25 ease.
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            The icons for show and hide rely on visual icons. If there are
            specific aria attributes to help users, these should be applied.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <PasswordInputMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

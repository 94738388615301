import {
  AllHTMLAttributes,
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  SyntheticEvent,
} from 'react';
import { Interpolation, Theme } from '@emotion/react';

import { Box } from '../box/box';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';

import { useInputFieldStyles } from './use-input-field-styles';

export interface InputFieldProps
  extends FieldContainerBaseProps,
    Omit<
      AllHTMLAttributes<HTMLInputElement>,
      'id' | 'label' | 'value' | 'onChange' | 'onBlur'
    > {
  onFocus?: (event: SyntheticEvent) => void;
  onBlur?: (value: string) => void;
  onChange: (value: string) => void;
  onRemove?: () => void;
  className?: string;
  extraContainerStyles?: Interpolation<Theme>;
  value?: string;
  type?: 'text' | 'password' | 'tel';
  'data-testid'?: string;
  children?: ReactNode;
  validating?: boolean;
  required?: boolean;
  /**
   * Override of logic to show outline error styling on input field without
   * validation messages. e.g. when using this component to compose compound
   * components with multiple inputs and shared validation.
   */
  showVisualErrorStyles?: boolean;
}

export const TEST_IDS = {
  TEXT_INPUT: 'text-input',
  CONTROL_CONTAINER: 'text-input-control-container',
};

/**
 * Do not use the InputFieldWithoutMemo export in the platform, use the InputField export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const InputFieldWithoutMemo = (
  {
    onFocus,
    onBlur,
    onChange,
    extraContainerStyles,
    label,
    helpText,
    value = '',
    disabled = false,
    type = 'text',
    'data-testid': testId = TEST_IDS.TEXT_INPUT,
    id,
    className,
    children,
    validation,
    placeholder,
    showValidation = false,
    name,
    showRequiredIndicator = false,
    showVisualErrorStyles = false,
    required = false,
  }: InputFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const { inputStyles, containerStyles, stateStyles } = useInputFieldStyles({
    hasAdjacentSibling: !!children,
    disabled,
    error: showValidation || showVisualErrorStyles,
  });

  return (
    <FieldContainer
      id={id}
      label={label}
      validation={validation}
      showValidation={showValidation}
      disabled={disabled}
      showRequiredIndicator={showRequiredIndicator}
      helpText={helpText}
    >
      <Box
        stack="row"
        css={[containerStyles, stateStyles, extraContainerStyles]}
        data-testid={TEST_IDS.CONTROL_CONTAINER}
      >
        <input
          className={className}
          css={inputStyles}
          id={id}
          onChange={({ currentTarget: { value } }) => {
            onChange(value);
          }}
          onFocus={(e) => {
            if (onFocus) onFocus(e);
          }}
          onBlur={({ currentTarget: { value } }) => {
            if (onBlur) onBlur(value);
          }}
          placeholder={placeholder}
          type={type}
          value={value || ''}
          data-testid={testId}
          disabled={disabled}
          name={name}
          // Note this is aria-required to indicate to screen-readers it
          // is a required field. If we used ‘required’ it would block submit
          // if the field is empty, however Blurr handles feedback here.
          aria-required={required || showRequiredIndicator}
          ref={ref}
        />
        {children}
      </Box>
    </FieldContainer>
  );
};

// Manually reset the displayName in the component tree.
InputFieldWithoutMemo.displayName = 'InputField';

export const InputField = memo(forwardRef(InputFieldWithoutMemo));

import { css } from '@emotion/react';

import { focusRingStyle, themeifyStyle } from '../../themes/utilities';

export const input = themeifyStyle((theme) => ({
  ':focus + label': focusRingStyle(theme).style,
}));

export const label = themeifyStyle((theme) => ({
  transition: focusRingStyle(theme).transition,
  'input + &': {
    border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral200}`,
    ':not(:hover)': {
      boxShadow: 'none',
    },
    ':hover': {
      border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral200}`,
    },
  },
  'input:checked + &': {
    border: `${theme.spacing.baseNeg8}px solid ${theme.palette.primary500}`,
  },
}));

export const tick = css({
  'input:not(:checked) + label & ': {
    visibility: 'hidden',
  },
});

export const icon = themeifyStyle(({ palette }) => ({
  'input:checked + label &': {
    color: `${palette.primary500}`,
  },
}));

import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, NavLink } from 'react-router-dom';

import { BumblebeeProvider, hiveTheme } from '@shieldpay/bumblebee';

import { App } from './app';

import hiveMessages from '@shieldpay/i18n/hive/en-GB.json';
import defaultMessages from '@shieldpay/i18n/shared/en-GB.json';

export const APP_ROOT = 'root';

const container = document.getElementById(APP_ROOT);

if (!container) {
  throw new Error(`no element with id of “${APP_ROOT}” found`);
}

const root = createRoot(container);

const getNavigatorLanguage = (): string =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      'en';

root.render(
  <BumblebeeProvider
    theme={{ name: 'hive', tokens: hiveTheme }}
    NavLink={NavLink}
  >
    <BrowserRouter>
      <IntlProvider
        locale={getNavigatorLanguage()}
        messages={{ ...defaultMessages, ...hiveMessages }}
      >
        <App />
      </IntlProvider>
    </BrowserRouter>
  </BumblebeeProvider>,
);

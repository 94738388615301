import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { useTextStyles } from '../text/use-text-styles';

import * as styles from './use-input-field.styles';

export interface UseInputFieldStyleOptions {
  hasAdjacentSibling?: boolean;
  disabled: boolean;
  error?: boolean;
  includePadding?: boolean;
}

export const useInputFieldStyles = ({
  hasAdjacentSibling = false,
  disabled,
  error = false,
  includePadding = true,
}: UseInputFieldStyleOptions) => {
  const theme = useTheme();
  const { variant } = useTextStyles({ variant: 'input' });

  return useMemo(() => {
    const inputStyles = styles.input(theme);
    const containerStyles = styles.container(theme);
    const stateStyles = styles.states(theme);

    return {
      inputStyles: [
        inputStyles.base,
        includePadding && inputStyles.padding,
        hasAdjacentSibling && inputStyles.withAdjacentSibling,
        disabled && inputStyles.disabled,
        variant,
      ],
      containerStyles: [containerStyles],
      stateStyles: [
        error && stateStyles.error,
        disabled && stateStyles.disabled,
      ],
    };
  }, [hasAdjacentSibling, disabled, error, includePadding, theme, variant]);
};

import { Theme } from '@emotion/react';

import { shieldpayTheme } from './shieldpay';

export const hiveTheme: Theme = {
  ...shieldpayTheme,
  layout: {
    ...shieldpayTheme.layout,
    bumblebee: {
      columns: { xs: [140, '1fr'], xxl: [264, '1fr'] },
      rows: {
        xs: [112, '1fr'],
        xxl: [100, '1fr'],
      },
    },
    bumblebeeContainer: {
      columns: {
        l: ['1fr', 696, '1fr'],
        xl: ['1fr', 1038, '1fr'],
        xxl: ['1fr', 1160, '1fr'],
      },
    },
  },
};

import { ReactNode } from 'react';

import * as styles from './table-row.styles';

export interface TableRowProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Child nodes (e.g. TableDataCell, TableHeaderCell).
   */
  children: ReactNode;
}

export const TableRow = ({ children, className }: TableRowProps) => (
  <tr css={[styles.tableRow]} className={className}>
    {children}
  </tr>
);

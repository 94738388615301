import { themeifyStyle } from '../../themes/utilities';

export const paginationItem = themeifyStyle((theme) => ({
  width: theme.spacing.baseNeg2 * 3,
  height: theme.spacing.baseNeg2 * 3,
  padding: theme.spacing.none,
  // For Emotion reasons this needs to be set to !important.
  // Fix when we move away from Emotion
  border: `${theme.spacing.baseNeg8}px solid currentcolor !important`,
}));

export const enabled = themeifyStyle((theme) => ({
  color: theme.palette.neutral400,
  ':hover': {
    color: theme.palette.neutral500,
  },
}));

// We need this to be a class not a pseudo-class - span does not have :disabled state
// For Emotion reasons this needs to be set to !important.
// Fix when we move away from Emotion
export const disabled = themeifyStyle((theme) => ({
  color: `${theme.palette.neutral200} !important`,
}));

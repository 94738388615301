import { css } from '@emotion/react';

import { focusRingStyle, themeifyStyle } from '../../themes/utilities';

const V1_FIXED_USER_MENU_WIDTH = 360;

export const floating = css({
  background: 'transparent',
  overflow: 'visible',
  zIndex: 3,
});

export const menu = css({
  width: V1_FIXED_USER_MENU_WIDTH,
});

export const logout = themeifyStyle((theme) => ({
  transition: focusRingStyle(theme).transition,
}));

export const logoutSelected = themeifyStyle(
  (theme) => focusRingStyle(theme).style,
);

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgMeatballsIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 18a2 2 0 110-4 2 2 0 010 4zM24 18a2 2 0 110-4 2 2 0 010 4zM16 18a2 2 0 110-4 2 2 0 010 4z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgMeatballsIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgTickIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M20.03 7.28L9.143 18.17 4.1 13.127l1.061-1.06 3.98 3.98L18.97 6.22l1.06 1.06z"
      />
    </svg>,
  );
};

export default SvgTickIcon;

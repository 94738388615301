import { Box } from '../box/box';

import * as styles from './progress-indicator.styles';

export interface ProgressIndicatorProps {
  max?: number;
  value: number;
  variant?: keyof typeof styles.variant;
  'data-testid'?: string;
  progressLabel: (progress: { max: number; value: number }) => string;
}

export const TEST_IDS = {
  WRAPPER: 'wrapper',
};

export const ProgressIndicator = ({
  max = 100,
  value,
  variant = 'primary',
  'data-testid': wrapperTestId = TEST_IDS.WRAPPER,
  progressLabel,
}: ProgressIndicatorProps) => (
  <Box
    data-testid={wrapperTestId}
    alignItems={['full', 'top']}
    padding={{
      xs: { top: 'basePos2', bottom: 'baseNeg2' },
      s: { top: 'basePos8', bottom: 'basePos7' },
    }}
  >
    <progress
      css={[styles.progressIndicator, styles.variant[variant]]}
      max={max}
      value={value}
      aria-label={progressLabel({ max, value })}
    />
  </Box>
);

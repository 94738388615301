import { ReactNode } from 'react';

import { Box } from '../box/box';
import { ChevronRightBreadcrumbIcon } from '../icons';
import { Link } from '../link/link';
import { Text } from '../text/text';

interface BreadcrumbItem {
  label: ReactNode;
  path?: string;
}

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  ariaLabel: string;
  'data-testid'?: string;
}

export interface BreadcrumbItemProps {
  path?: string;
  children: ReactNode;
}

export const TEST_IDS = {
  BREADCRUMB: 'breadcrumb-component',
};

export const BreadcrumbItem = ({ path, children }: BreadcrumbItemProps) =>
  path ? (
    <>
      <Link to={path}>{children}</Link>
      <ChevronRightBreadcrumbIcon size="s" />
    </>
  ) : (
    <Text variant="body">{children}</Text>
  );

export const Breadcrumb = ({
  items,
  ariaLabel,
  'data-testid': testId = TEST_IDS.BREADCRUMB,
}: BreadcrumbProps) => (
  <Box component="nav" aria-label={ariaLabel} data-testid={testId}>
    <Box
      component="ul"
      stack="row"
      spacing="baseNeg5"
      alignItems={['left', 'center']}
    >
      {items.map(({ label, path }, index) => (
        <Box
          component="li"
          key={index}
          stack="row"
          alignItems={['left', 'center']}
          spacing="baseNeg5"
        >
          <Breadcrumb.Item path={path}>{label}</Breadcrumb.Item>
        </Box>
      ))}
    </Box>
  </Box>
);

Breadcrumb.Item = BreadcrumbItem;

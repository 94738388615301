import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export const grid = themeifyStyle(({ spacing }) => ({
  display: 'grid',
  gridTemplateColumns: `${spacing.basePos5}px 1fr auto`,
  overflowX: 'clip',
  // Suggest reviewing if we still need fallback end of 2023 onwards.
  '@supports not (overflow: clip)': {
    overflow: 'hidden',
  },
}));

export const timeline = {
  gridTemplateRows: '1fr 1fr',
};

export const content = css({
  minWidth: 0,
  overflowX: 'clip',
  // Suggest reviewing if we still need fallback end of 2023 onwards.
  '@supports not (overflow: clip)': {
    overflow: 'hidden',
  },
});

export const line = themeifyStyle(({ palette, spacing }) => ({
  // we need to make the height 100% + the theme token <List />
  // uses for spacing (expect for the last line)
  height: `calc(100% + ${spacing.base}px)`,
  '::before': {
    content: '""',
    backgroundColor: palette.neutral150,
    flexGrow: 1,
    width: `${spacing.baseNeg7}px`,
  },
}));

export const lineLast = {
  height: '100%',
};

export const leftIcon = themeifyStyle(({ palette }) => ({
  backgroundColor: palette.neutral000,
}));

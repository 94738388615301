import { css } from '@emotion/react';

export const reset = css({
  'html, body, ul, ol, li, dialog': {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    font: 'inherit',
    verticalAlign: 'baseline',
  },
});

export const boxSizing = css({
  html: {
    boxSizing: 'border-box',
  },
});

export const box = css({
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
});

export const doc = css({
  'html, body, #root': {
    width: '100%',
    height: '100%',
  },
});

import { Box, Heading, Tag, Text } from '@shieldpay/bumblebee';
import TagMarkdown from '@shieldpay/bumblebee/markdown/tag.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const TagComponentPage = () => {
  usePageTitle({ id: 'hive.components.tag.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview alignItems={['left', 'top']}>
        <Heading variant="bodyMedium150">Small tags</Heading>
        <Box stack="row" spacing="base">
          <Tag size="small">Primary tag</Tag>
          <Tag size="small" variant="warning">
            Warning tag
          </Tag>
          <Tag size="small" variant="alert">
            Alert tag
          </Tag>
          <Tag size="small" variant="success">
            Success tag
          </Tag>
          <Tag size="small">
            Super Long text exampleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
          </Tag>
        </Box>

        <Heading variant="bodyMedium150">Medium tags</Heading>
        <Box stack="row" spacing="base">
          <Tag size="medium">Primary tag</Tag>
          <Tag size="medium" variant="warning">
            Warning tag
          </Tag>
          <Tag size="medium" variant="alert">
            Alert tag
          </Tag>
          <Tag size="medium" variant="success">
            Success tag
          </Tag>
          <Tag size="medium">
            Super Long text exampleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
          </Tag>
        </Box>

        <Heading variant="bodyMedium150">Large tags</Heading>
        <Box stack="row" spacing="base">
          <Tag size="large">Primary tag</Tag>
          <Tag size="large" variant="warning">
            Warning tag
          </Tag>
          <Tag size="large" variant="alert">
            Alert tag
          </Tag>
          <Tag size="large" variant="success">
            Success tag
          </Tag>
          <Tag size="large">
            Super Long text exampleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
          </Tag>
        </Box>
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            Tags help label and organise items using keywords that describe
            them. Use short labels for easy scanning. Tags can also be used as a
            method of filtering data. Top level label cannot be removed and
            remains at the top of the page to display key information. Removable
            tags are used when searching in the search bar or selecting an
            option in the dropdown. Users can remove tags by clicking the “X”
            icon.
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            Top level labels are placed on the top half of the page, under the
            title of page and display key information. Removable tags are at the
            bottom of the search bar, inside a table to display active filters.
            Please refer to Placement in page designs for examples.
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the appropriate ARIA attributes for screen readers where
            applicable. Keyboard support The control to remove a tag is
            implemented as a button with standard keyboard support.
          </Text>
        </Design.Section>
        <Design.Section heading="Tag content">
          <Heading level="3" variant="bodyMedium150">
            Searching
          </Heading>
          <Text variant="body150">
            The text of the tag will reflect the text used in the search input.
          </Text>

          <Heading level="3" variant="bodyMedium150">
            Selecting a filter
          </Heading>
          <Text variant="body150">
            The text of the tag will reflect the option or options selected. For
            example, if the user selects the “funded” status, the tag text will
            be “Funded”
          </Text>

          <Heading level="3" variant="bodyMedium150">
            Selecting a date
          </Heading>
          <Text variant="body150">
            The text of the tag will reflect one of the default date ranges
            provided, a specific date with the format “dd/mm/yyy” or a specific
            date range with the format “dd/mm/yyy to dd/mm/yyy”
          </Text>

          <Heading level="3" variant="bodyMedium150">
            Top level labels
          </Heading>
          <Text variant="body150">
            The text within the labels will be key information set by the page
            it sits in.
          </Text>
        </Design.Section>

        <Design.Section heading="Adding a tag">
          <Heading level="3" variant="bodyMedium150">
            Adding a tag using the search input
          </Heading>
          <Text variant="body150">
            A tag will appear under the “search input” after the user types
            something in the search input and presses the enter button.
          </Text>

          <Heading level="3" variant="bodyMedium150">
            Adding a tag using the multiselect filter
          </Heading>
          <Text variant="body150">
            A tag will appear under the filter after the user selects something
            one of the options in the dropdown menu.
          </Text>

          <Heading level="3" variant="bodyMedium150">
            Adding a tag using the date filter
          </Heading>
          <Text variant="body150">
            A tag will appear under the filter after the user selects one of
            options or enters a date, or range of dates manually.
          </Text>
        </Design.Section>

        <Design.Section heading="Removing">
          <Text variant="bodyMedium150" color="neutral450">
            Removing a single tag
          </Text>
          <Text variant="body150">
            Users can remove individual tags by clicking the “x” icon inside the
            tag or selecting the specific filter and clicking “clear” inside the
            dropdown menu.
          </Text>

          <Text variant="bodyMedium150" color="neutral450">
            Removing all tags
          </Text>
          <Text variant="body150">
            Users can remove all tags at the same time by clicking the “clear
            filters” button on the right.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <TagMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { css } from '@emotion/react';

export const columnStart = {
  '1': css({ gridColumnStart: '1' }),
  '2': css({ gridColumnStart: '2' }),
  '3': css({ gridColumnStart: '3' }),
};

export const columnEnd = {
  '1': css({ gridColumnEnd: '1' }),
  span2: css({ gridColumnEnd: 'span 2' }),
};

export const rowStart = {
  '1': css({ gridRowStart: '1' }),
  '2': css({ gridRowStart: '2' }),
  '3': css({ gridRowStart: '3' }),
};

export const rowEnd = {
  '1': css({ gridRowEnd: '1' }),
  span2: css({ gridRowEnd: 'span 2' }),
};

import { css, keyframes } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

const spin = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const container = css({
  animation: `${spin} 1s infinite linear`,
});

export const wrapper = css({
  height: '100%',
});

export const svg = themeifyStyle((theme) => ({
  width: `${theme.spacing.basePos8}px`,
  height: `${theme.spacing.basePos8}px`,
  borderRadius: '50%',
}));

export const color = {
  primary: themeifyStyle(({ palette }) => ({
    background: `conic-gradient(from 90deg at 20% 50%, transparent 0%, transparent 27%, ${palette.primary500} 98%)`,
  })),
  secondary: themeifyStyle(({ palette }) => ({
    background: `conic-gradient(from 90deg at 20% 50%, transparent 0%, transparent 27%, ${palette.secondary500} 98%)`,
  })),
  white: themeifyStyle(({ palette }) => ({
    background: `conic-gradient(from 90deg at 20% 50%, transparent 0%, transparent 27%, ${palette.neutral000} 98%)`,
  })),
};

export const size = {
  small: themeifyStyle(({ spacing }) => ({
    width: `${spacing.basePos2}px`,
    height: `${spacing.basePos2}px`,
  })),
  medium: themeifyStyle(({ spacing }) => ({
    width: `${spacing.basePos3}px`,
    height: `${spacing.basePos3}px`,
  })),
  large: themeifyStyle(({ spacing }) => ({
    width: `${spacing.basePos4}px`,
    height: `${spacing.basePos4}px`,
  })),
  xlarge: themeifyStyle(({ spacing }) => ({
    width: `${spacing.basePos6}px`,
    height: `${spacing.basePos6}px`,
  })),
  xxlarge: themeifyStyle(({ spacing }) => ({
    width: `${spacing.basePos8}px`,
    height: `${spacing.basePos8}px`,
  })),
};

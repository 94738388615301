import { TopBar } from '@shieldpay/bumblebee';
import TopBarMarkdown from '@shieldpay/bumblebee/markdown/top-bar.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const TopBarComponentPage = () => {
  usePageTitle({ id: 'hive.components.topBar.title' });

  return (
    <>
      <ContentsTable />
      {/* custom padding to provide correct white space for top bar to sit in */}
      <ComponentPreview padding={{ top: 'none', bottom: 'basePos11' }}>
        <TopBar
          email="vera.boca@italo.disco"
          group="Top Business Manager"
          logOutText="Log out"
          logOutHref="/"
        />
      </ComponentPreview>

      <MDXCodeblockProvider>
        <TopBarMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export const showPasswordToggle = themeifyStyle((theme) => ({
  cursor: 'pointer',
  borderRadius: theme.borderRadius,
  padding: 0,
  margin: 0,
  flex: '0 1',
  color: theme.palette.neutral350,
}));

export const input = css({
  flex: '1 0',
});

export const withInputControl = css({
  paddingRight: 0,
});

export const buttonContainer = css({
  flex: '0 1',
});

// do not show the focus ring on the input when the show/hide password button has focus
export const inputDisableFocusWhenButtonFocused = css({
  ':has(button:focus)': {
    boxShadow: 'none',
  },
});

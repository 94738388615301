import { transformValues } from '@shieldpay/utility-functions-ui';

import {
  createThemedStyleObjects,
  themeifyStyle,
  transparentize,
} from '../../themes/utilities';

export const variant = {
  primary: themeifyStyle(({ palette, spacing }) => ({
    border: `${spacing.baseNeg8}px solid ${palette.neutral200}`,
  })),
  lightShadow: themeifyStyle(({ palette, spacing }) => ({
    boxShadow: `0 ${spacing.baseNeg7}px ${spacing.baseNeg4}px ${transparentize(
      palette.neutral500,
      25,
    )}`,
    border: `${spacing.baseNeg8}px solid ${palette.neutral350}`,
  })),
  darkShadow: themeifyStyle(({ palette, spacing }) => ({
    boxShadow: `0 ${spacing.baseNeg7}px ${spacing.baseNeg4}px ${transparentize(
      palette.neutral500,
      50,
    )}`,
  })),
};

export const [background] = createThemedStyleObjects(({ palette }) =>
  transformValues(palette, (backgroundColor) => ({
    backgroundColor,
  })),
);

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgChevronRightBreadcrumbIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.32 13.2l3.36 4.8-3.36 4.8H14.4l3.2-4.8-3.2-4.8h1.92z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgChevronRightBreadcrumbIcon;

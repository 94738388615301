import { Theme } from '@emotion/react';

import { darken, lighten } from './utilities';

const primary500 = '#5033ff';
const secondary500 = '#70e08f';
const tertiary500 = '#ff7c5b';
const neutral500 = '#353c4a';
const alert500 = '#cf3a14';
const success500 = '#28bf5b';
const warning500 = '#f2bd00';
const info500 = '#4382fc';

export const shieldpayTheme: Theme = {
  breakpoints: {
    xs: 320,
    s: 576,
    m: 768,
    l: 992,
    xl: 1366,
    xxl: 1680,
  },
  palette: {
    primary200: lighten(primary500, 37),
    primary300: lighten(primary500, 23),
    primary400: lighten(primary500, 7),
    primary500,
    primary600: darken(primary500, 20),
    secondary200: lighten(secondary500, 30),
    secondary500,
    tertiary200: lighten(tertiary500, 30),
    tertiary500,
    neutral100: lighten(neutral500, 72),
    neutral200: lighten(neutral500, 60),
    neutral250: lighten(neutral500, 50),
    neutral300: lighten(neutral500, 40),
    neutral350: lighten(neutral500, 30),
    neutral400: lighten(neutral500, 20),
    neutral450: lighten(neutral500, 10),
    neutral150: lighten(neutral500, 70),
    neutral500,
    alert200: lighten(alert500, 53),
    alert500,
    alert600: darken(alert500, 11),
    success200: lighten(success500, 50),
    success500,
    warning200: lighten(warning500, 46),
    warning500,
    info200: lighten(info500, 34),
    info500,
    info600: darken(info500, 25),
    neutral000: '#fff',
    black: '#000',
  },
  transitions: {
    standard: '0.25s ease',
  },
  iconSizes: {
    xs: 14,
    s: 20,
    m: 24,
    l: 32,
    xl: 48,
  },
  borderRadius: 4,
  spacing: {
    none: 0,
    baseNeg8: 1,
    baseNeg7: 2,
    baseNeg6: 4,
    baseNeg5: 6,
    baseNeg4: 8,
    baseNeg3: 10,
    baseNeg2: 12,
    baseNeg1: 14,
    base: 16,
    basePos1: 18,
    basePos2: 20,
    basePos3: 24,
    basePos4: 32,
    basePos5: 40,
    basePos6: 48,
    basePos7: 64,
    basePos8: 80,
    basePos9: 96,
    basePos10: 128,
    basePos11: 160,
    basePos12: 224,
  },
  typography: {
    fontFamily: `'Inter', Helvetica, Arial, sans-serif`,
    fontWeight: {
      regular: 400,
      medium: 500,
    },
    lineHeight: {
      base: 1,
      mid: 1.3,
      tall: 1.5,
    },
    variant: {
      headline1: {
        fontSize: {
          xs: 24,
          m: 30,
        },
        lineHeight: 'mid',
        fontWeight: 'medium',
      },
      headline2: {
        fontSize: 24,
        lineHeight: 'mid',
        fontWeight: 'medium',
      },
      title: {
        fontSize: 20,
        lineHeight: 'base',
        fontWeight: 'medium',
      },
      section: {
        fontSize: 16,
        lineHeight: 'mid',
        fontWeight: 'medium',
        textTransform: 'uppercase',
      },
      bodyMedium150: {
        fontSize: 16,
        lineHeight: 'tall',
        fontWeight: 'medium',
      },
      bodyMedium: {
        fontSize: 16,
        lineHeight: 'base',
        fontWeight: 'medium',
      },
      captionMedium150: {
        fontSize: 14,
        lineHeight: 'tall',
        fontWeight: 'medium',
      },
      captionMedium: {
        fontSize: 14,
        lineHeight: 'base',
        fontWeight: 'medium',
      },
      captionSmall: {
        fontSize: 12,
        lineHeight: 'base',
        fontWeight: 'regular',
      },
      body150: {
        fontSize: 16,
        lineHeight: 'tall',
        fontWeight: 'regular',
      },
      body: {
        fontSize: 16,
        lineHeight: 'base',
        fontWeight: 'regular',
      },
      input: {
        fontSize: 16,
        lineHeight: 'mid',
        fontWeight: 'regular',
      },
      caption150: {
        fontSize: 14,
        lineHeight: 'tall',
        fontWeight: 'regular',
      },
      caption: {
        fontSize: 14,
        lineHeight: 'base',
        fontWeight: 'regular',
      },
      inter: {
        fontSize: 12,
        lineHeight: 'mid',
        fontWeight: 'regular',
      },
    },
  },
  modal: {
    width: {
      large: 440,
    },
  },
  dropdown: {
    menu: {
      height: 360,
    },
  },
  layout: {
    dashboard: {
      columns: { xs: [140, '1fr'], xxl: [264, '1fr'] },
      rows: {
        xs: [112, '1fr'],
        xxl: [100, '1fr'],
      },
    },
    onboarding: {
      columns: { xs: ['1fr'], l: [300, '1fr'], xl: [450, '1fr'] },
      rows: {
        xs: ['min-content', '1fr', 'max-content'],
        m: ['basePos10', '1fr', 'min-content'],
        l: ['basePos11', '1fr', 'min-content'],
      },
    },
    onboardingForm: {
      columns: {
        xs: [0, '1fr', 0],
        m: ['1fr', 440, '1fr'],
      },
    },
    dashboardForm: {
      columns: [440, '1fr'],
    },
  },
};

import { Heading, TextList } from '@shieldpay/bumblebee';
import TextListMarkdown from '@shieldpay/bumblebee/markdown/text-list.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

export const TextListComponentPage = () => {
  usePageTitle({ id: 'hive.components.textList.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview alignItems={['left', 'top']}>
        <Heading level="3" variant="title">
          Unordered TextList
        </Heading>
        <TextList>
          <TextList.Item>
            That’s what it said on ‘Ask Jeeves’ He’ll want to use your yacht,
            and I don’t want this thing smelling like fish. Now, when you do
            this without getting punched in the chest, you’ll have more fun. I
            don’t understand the question, and I won’t respond to it.
          </TextList.Item>
          <TextList.Item>
            I don’t understand the question, and I won’t respond to it. I’m a
            monster. There’s so many poorly chosen words in that sentence. I’m a
            monster. Get me a vodka rocks. And a piece of toast. That’s why you
            always leave a note!
          </TextList.Item>
        </TextList>
        <Heading level="3" variant="title">
          Ordered TextList
        </Heading>
        <TextList ordered>
          <TextList.Item>
            That’s what it said on ‘Ask Jeeves’ He’ll want to use your yacht,
            and I don’t want this thing smelling like fish. Now, when you do
            this without getting punched in the chest, you’ll have more fun. I
            don’t understand the question, and I won’t respond to it.
          </TextList.Item>
          <TextList.Item>
            I don’t understand the question, and I won’t respond to it. I’m a
            monster. There’s so many poorly chosen words in that sentence. I’m a
            monster. Get me a vodka rocks. And a piece of toast. That’s why you
            always leave a note!
          </TextList.Item>
        </TextList>
      </ComponentPreview>

      <MDXCodeblockProvider>
        <TextListMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export const base = themeifyStyle(({ spacing }) => ({
  listStylePosition: 'outside',
  paddingLeft: spacing.basePos2,
}));

export const type = {
  ol: css({
    listStyleType: 'decimal',
  }),
  ul: css({
    listStyleType: 'disc',
  }),
};

export const item = css({
  display: 'list-item',
});

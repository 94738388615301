import { MouseEventHandler, useEffect, useState } from 'react';

import { usePositionedPopup } from '../../hooks/use-positioned-popup';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { Card } from '../card/card';
import { GrandEntrance } from '../grand-entrance/grand-entrance';
import { ChevronDownIcon } from '../icons';
import { Link } from '../link/link';
import { Text } from '../text/text';

import { UserMenuCardProps } from './user-menu-card';

import * as styles from './user-menu.styles';

export interface UserMenuProps extends UserMenuCardProps {
  logOutHref: string;
  logOutCallback?: never;
}

export const TEST_IDS = {
  MENU: 'user-menu--menu',
  EMAIL: 'user-menu--email',
  CARD_EMAIL: 'user-menu-card--email',
  CARD_GROUP: 'user-menu-card--group',
};

const ariaIds = {
  label: 'user-menu-label',
  toggleButton: 'user-menu-toggle-button',
};

export const UserMenu = ({
  email,
  group,
  logOutHref,
  logOutText,
}: UserMenuProps) => {
  const [open, setOpen] = useState(false);

  const {
    callbackRefs,
    mutableRefs: { floating },
    constrainStyles,
    positionStyles,
  } = usePositionedPopup<HTMLButtonElement, HTMLDialogElement>({
    open,
    placement: 'bottom-end',
  });

  useEffect(() => {
    if (open) floating?.current?.showModal();
  }, [floating, open]);

  const closeOnOutsideClick:
    | MouseEventHandler<HTMLDialogElement>
    | undefined = ({ currentTarget, target }) => {
    const hasClickedOutsideModal =
      target === currentTarget && target === floating.current;

    hasClickedOutsideModal && floating.current?.close();
  };

  return (
    <>
      <Text
        paragraph
        data-testid={TEST_IDS.EMAIL}
        id={ariaIds.label}
        htmlFor={ariaIds.toggleButton}
      >
        {email}
      </Text>
      <Button
        onClick={() => setOpen(true)}
        variant="icon"
        ref={callbackRefs.setReference}
        id={ariaIds.toggleButton}
        aria-labelledby={ariaIds.label}
      >
        <ChevronDownIcon />
      </Button>

      <dialog
        css={[styles.dialog, positionStyles]}
        ref={callbackRefs.setFloating}
        onClick={closeOnOutsideClick}
        onClose={() => {
          setOpen(false);
        }}
        // dialog does not have accessible role in jsdom
        data-testid={TEST_IDS.MENU}
      >
        <GrandEntrance
          show={open}
          config={
            open
              ? {
                  mass: 1,
                  tension: 260,
                  friction: 10,
                  precision: 0.01,
                  clamp: true,
                  velocity: 0.002, // seconds
                }
              : {
                  duration: 0,
                }
          }
        >
          <Box
            css={[styles.menu, constrainStyles]}
            alignItems={false}
            padding={{ top: 'baseNeg2' }}
          >
            <Card
              variant="lightShadow"
              alignItems={['left', 'top']}
              role="menu"
              spacing="basePos4"
              padding={{ x: 'basePos3', top: 'basePos3', bottom: 'basePos4' }}
            >
              <Box spacing="baseNeg4">
                <Text paragraph data-testid={TEST_IDS.CARD_EMAIL}>
                  {email}
                </Text>
                <Text paragraph data-testid={TEST_IDS.CARD_GROUP}>
                  {group}
                </Text>
              </Box>
              <Link to={logOutHref}>{logOutText}</Link>
            </Card>
          </Box>
        </GrandEntrance>
      </dialog>
    </>
  );
};

import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
  flip,
  Placement,
  ReferenceType,
  size,
  useFloating,
} from '@floating-ui/react-dom';

interface UsePositionedPopupOptions {
  placement?: Placement;
  open: boolean;
  /**
   * Set the popup to the same width as the reference
   */
  matchWidths?: boolean;
}

type ConstrainStyles = Record<'maxWidth' | 'maxHeight' | 'width', number>;

export const usePositionedPopup = <
  ReferenceElement extends ReferenceType = HTMLButtonElement,
  FloatingElement = HTMLElement,
>({
  placement = 'bottom-start',
  matchWidths = false,
  open,
}: UsePositionedPopupOptions) => {
  const applyStylesRefs = useRef({ initialised: false, open });

  const theme = useTheme();
  const [constrainStyles, setConstrainStyles] =
    useState<Partial<ConstrainStyles>>();

  const { x, y, refs, strategy, update } = useFloating<ReferenceElement>({
    placement,
    middleware: [
      flip({ padding: theme.spacing.base }),
      size({
        padding: theme.spacing.base,
        apply({ availableWidth, availableHeight, elements }) {
          if (
            !applyStylesRefs.current.open &&
            applyStylesRefs.current.initialised
          ) {
            // To improve performance we do not set the styles after
            // 1: initial mount OR
            // 2: popup is closed
            return;
          }

          setConstrainStyles({
            maxWidth: availableWidth,
            maxHeight: Math.min(theme.dropdown.menu.height, availableHeight),
            width: matchWidths
              ? elements.reference.getBoundingClientRect().width
              : undefined,
          });

          if (!applyStylesRefs.current.initialised) {
            applyStylesRefs.current.initialised = true;
          }
        },
      }),
    ],
  });

  useEffect(() => {
    window.addEventListener('scroll', update);
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, [update]);

  // Schedule an update whenever the open state changes
  // making sure all sizes and positions are correct.
  useEffect(() => {
    // We use this to branch setting styles in the size middleware
    applyStylesRefs.current.open = open;

    // schedule a style update if opened
    if (open) update();
  }, [open, update]);

  return {
    /**
     * Internal refs required if you want to merge with another library. e.g. downshiftJs
     */
    mutableRefs: {
      reference: refs.reference,
      floating: refs.floating as MutableRefObject<FloatingElement>,
    },
    /**
     * Refs to apply directly to JSX elements
     */
    callbackRefs: {
      setReference: refs.setReference,
      setFloating: refs.setFloating,
    },
    /**
     * Styles for positioning the popup.
     */
    positionStyles: {
      position: strategy,
      left: x || '',
      top: y || '',
    },
    /**
     * Styles for constraining the dimensions of the popup within the viewport.
     */
    constrainStyles,
  };
};

/**
 * Iterates over every entry in an object, allowing a transformation on each entry.
 * There are optional ObjectIn and ObjectOut generics for more complex scenarios.
 */
export const transformValues = <
  ObjectIn extends Record<string, unknown>,
  Key extends keyof ObjectIn,
  ObjectOut extends Record<Key, unknown>,
>(
  object: ObjectIn,
  callback: (value: ObjectIn[Key], key: Key) => ObjectOut[Key],
): ObjectOut =>
  Object.entries(object).reduce(
    (acc: ObjectOut, [key, value]) => ({
      ...acc,
      [key]: callback(value as ObjectIn[Key], key as Key),
    }),
    {} as ObjectOut,
  );

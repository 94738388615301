import {
  AllHTMLAttributes,
  CSSProperties,
  DialogHTMLAttributes,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import { SpringValue } from 'react-spring';

import { UseBoxStyleProps, useBoxStyles } from './use-box-styles';

export interface BoxProps
  extends UseBoxStyleProps,
    Omit<AllHTMLAttributes<HTMLElement>, 'width' | 'height' | 'style'> {
  component?: ElementType;
  style?: Record<string, SpringValue> | CSSProperties;
  children?: ReactNode;
  /** special case to be used for NavLink components */
  to?: string;
  /** special case to be used for Dialog components */
  onClose?: DialogHTMLAttributes<HTMLElement>['onClose'];
}

/**
 * Do not use the BoxWithoutForwardRef export in the platform, use the Box export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const BoxWithoutForwardRef = <T,>(
  {
    component: Component = 'div',
    children,
    stack,
    inline,
    spacing,
    padding,
    alignItems,
    touchArea,
    rounded,
    screenReaderOnly,
    expand,
    ...restProps
  }: BoxProps,
  ref: ForwardedRef<T>,
) => {
  const styles = useBoxStyles({
    stack,
    screenReaderOnly,
    inline,
    spacing,
    padding,
    rounded,
    alignItems,
    touchArea,
    expand,
  });

  return (
    <Component css={[styles]} {...restProps} ref={ref}>
      {children}
    </Component>
  );
};

// Manually reset the displayName in the component tree.
BoxWithoutForwardRef.displayName = 'Box';

export const Box = forwardRef(BoxWithoutForwardRef);

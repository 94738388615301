import { Box, Button, Card, Heading, Link, Text } from '@shieldpay/bumblebee';
import CardMarkdown from '@shieldpay/bumblebee/markdown/card.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const CardComponentPage = () => {
  usePageTitle({ id: 'hive.components.card.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview alignItems={['left', 'top']}>
        <Card spacing="basePos5">
          <Heading level="3" variant="title">
            Primary
          </Heading>
          <Box stack="row" spacing="basePos5">
            <Text paragraph>This is an example of a primary card</Text>
            <Button variant="outline">OK, hun</Button>
          </Box>
        </Card>
        <Heading level="3" variant="title">
          Light-shadow
        </Heading>
        <Card
          variant="lightShadow"
          spacing="basePos5"
          alignItems={['left', 'top']}
        >
          <Box>
            <Text paragraph>This is an example of a</Text>
            <Text paragraph>Light-shadow card</Text>
          </Box>

          <Link href="/">More info</Link>
        </Card>
        <Heading level="3" variant="title">
          Dark-shadow
        </Heading>
        <Card
          variant="darkShadow"
          background="neutral150"
          padding={{ x: 'basePos3', y: 'basePos1' }}
        >
          <Text paragraph>
            This is an example of a Dark-shadow card
            <br />
            with custom padding and background
          </Text>
        </Card>
      </ComponentPreview>
      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The card component is what gets used behind the content of any
            self-contained components. These would be components that have a
            background or frame such as a popover or dialog box.
          </Text>
          <Text paragraph>
            Due to the way that components are built in Figma, we will not be
            adding this to the library. Components that have a card will be
            designed within the frame of the component itself.
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            The card component consists of the blank rectangle with a 4px radius
            on all corners. The colour and styles of the card vary depending on
            the context of its usage and how elevated it is.
          </Text>
          <Text paragraph>
            The contents within the card component should adhere to the spacing
            guidelines in the specs. However these may differ between
            components.
          </Text>
          <Text paragraph>There are three types of card components:</Text>
          <Box>
            <Heading level="5" variant="title">
              Primary
            </Heading>
            <Text paragraph>
              This is the main card behind UI on the ground level.
              <br />
              Example: Balance card
            </Text>
          </Box>
          <Text paragraph>
            Style:
            <br />
            White with a 1px
            <br />
            Primary-lighten-30 stroke. No drop shadow.
          </Text>
          <Box>
            <Heading level="5" variant="title">
              Light-shadow
            </Heading>
            <Text paragraph>
              This is the card used for any components that are elevated on
              mezzanine or higher.
              <br />
              Example: User menu (top bar).
            </Text>
          </Box>
          <Text paragraph>
            Style:
            <br />
            White with a 1px Primary-lighten-30 stroke.
          </Text>
          <Text paragraph>
            Drop shadow:
            <br />
            X:0px / Y:2px / Blur: 8px / Spread: 0px
            <br />
            Primary-base at 50% opacity
          </Text>
          <Box>
            <Heading level="5" variant="title">
              Dark-shadow
            </Heading>
            <Text paragraph>
              This is the card used for any components that are elevated on
              mezzanine or higher.
              <br />
              Example: Toast notification
            </Text>
          </Box>
          <Text paragraph>
            Style:
            <br />
            Can be multiple colours but no stroke
          </Text>
          <Text paragraph>
            Drop shadow:
            <br />
            X:0px / Y:2px / Blur: 8px / Spread: 0px
            <br />
            Primary-base at 50% opacity
          </Text>
          <Text paragraph>
            Please refer to <Text variant="bodyMedium150">Specs</Text> for
            examples
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            The card component will have various placements. These will be
            defined in the component documentation.
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the appropriate ARIA attributes for screen readers where
            applicable.
          </Text>
        </Design.Section>
      </Design>
      <MDXCodeblockProvider>
        <CardMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

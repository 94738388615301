import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgSearchIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.184 21.598A10.458 10.458 0 0113.5 24C7.701 24 3 19.299 3 13.5S7.701 3 13.5 3 24 7.701 24 13.5c0 2.54-.901 4.868-2.402 6.684l1.911 1.91a.995.995 0 00.193.151l1.556.934c.07.042.135.093.193.15l3.963 3.964a1 1 0 010 1.414l-.707.707a1 1 0 01-1.414 0l-3.963-3.963a1.002 1.002 0 01-.15-.193l-.935-1.556a.998.998 0 00-.15-.193l-1.911-1.91zM22 13.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgSearchIcon;

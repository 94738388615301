import { ReactNode } from 'react';

import { ThemeTokens } from '../../themes/types';
import { Text } from '../text/text';

import * as styles from './table-cell.styles';

export type CellTextVariantType = keyof Pick<
  ThemeTokens['typography']['variant'],
  'caption150' | 'captionMedium150'
>;

export interface TableDataCellProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Children text/nodes.
   */
  children?: ReactNode;
  /**
   * When the header applies to multiple columns.
   */
  colSpan?: number;
  /**
   * When the header applies to multiple rows.
   **/
  rowSpan?: number;
  /**
   * Aligning value based on the value type. E.g. Left align text values and right align number values.
   */
  align?: keyof typeof styles.textAlign;
  /**
   * Adds extra padding on cells which are either first and last child of a row.
   * This is true by default. Setting to will be needed for tables with cells that
   * expand over multiple rows to prevent misalignment due to the use of selectors
   * :first-of-type and :last-child used to add extra padding on table left and right
   */
  indent?: boolean;
  /**
   *  Set the text variant for this cell. This is `caption150` by default
   */
  cellTextVariant?: CellTextVariantType;
}

export const TableDataCell = ({
  children,
  colSpan = 1,
  rowSpan = 1,
  className,
  align = 'left',
  indent = true,
  cellTextVariant = 'caption150',
}: TableDataCellProps) => (
  <td
    colSpan={colSpan}
    rowSpan={rowSpan}
    css={[styles.base, indent && styles.indent, styles.textAlign[align]]}
    className={className}
  >
    <Text variant={cellTextVariant}>{children}</Text>
  </td>
);

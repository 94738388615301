import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgAddPersonIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.027 24.998h9.053c2.973.003 6.76.002 9.035.001v-2.49a2.81 2.81 0 00-.178-.267c-.21-.283-.52-.63-.924-1.021a20.816 20.816 0 00-2.876-2.28c-1.184-.781-3.02-1.441-5.047-1.441-2.027 0-3.863.66-5.048 1.442-1.038.684-2.11 1.532-2.922 2.324a8.208 8.208 0 00-.912 1.022c-.074.104-.12.18-.148.228l-.033 2.482zm-1.963-2.802l-.05 3.79a.998.998 0 00.997 1.012h10.068c3.47.003 8.048.002 10.04.001a.997.997 0 00.996-1v-3.73a.69.69 0 00-.012-.144c-.26-1.234-2.653-3.394-4.864-4.853-1.5-.99-3.718-1.772-6.15-1.772-2.431 0-4.648.782-6.149 1.772-2.254 1.487-4.697 3.704-4.876 4.924zM16.43 9.384A3.398 3.398 0 0013.046 6a3.398 3.398 0 00-3.384 3.384 3.398 3.398 0 003.384 3.385 3.398 3.398 0 003.384-3.385zM13.046 4a5.398 5.398 0 015.384 5.384 5.398 5.398 0 01-5.384 5.385 5.398 5.398 0 01-5.384-5.385A5.398 5.398 0 0113.046 4zM30 15H20v-2h10v2z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 19V9h2v10h-2z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgAddPersonIcon;

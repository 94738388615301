import { ElementType, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Home } from './components/home/home';
import { BoxComponentPage } from './pages/components/box';
import { BreadcrumbComponentPage } from './pages/components/breadcrumb';
import { ButtonComponentPage } from './pages/components/button';
import { CardComponentPage } from './pages/components/card';
import { CardControlsComponentPage } from './pages/components/card-controls';
import { CheckboxComponentPage } from './pages/components/checkbox';
import { ChoiceListComponentPage } from './pages/components/choice-list';
import { DataTableComponentPage } from './pages/components/data-table';
import { DateInputComponentPage } from './pages/components/date-input';
import { DividerComponentPage } from './pages/components/divider';
import { DropdownComponentPage } from './pages/components/dropdown';
import { HeadingComponentPage } from './pages/components/heading';
import { IconComponentPage } from './pages/components/icon';
import { InfoblockComponentPage } from './pages/components/infoblock';
import { InputFieldComponentPage } from './pages/components/input-field';
import { LinkComponentPage } from './pages/components/link';
import { ListComponentPage } from './pages/components/list';
import { LoadingSpinnerComponentPage } from './pages/components/loading-spinner';
import { ModalComponentPage } from './pages/components/modal';
import { PaginatedTableComponentPage } from './pages/components/paginated-table';
import { PaginationComponentPage } from './pages/components/pagination';
import { PasswordInputComponentPage } from './pages/components/password-input';
import { PhoneNumberInputComponentPage } from './pages/components/phone-number-input';
import { PillComponentPage } from './pages/components/pill';
import { PrimaryNavComponentPage } from './pages/components/primary-nav';
import { ProgressIndicatorComponentPage } from './pages/components/progress-indicator';
import { RadioButtonComponentPage } from './pages/components/radio-button';
import { SortCodeInputPage } from './pages/components/sort-code-input';
import { TableComponentPage } from './pages/components/table';
import { TagComponentPage } from './pages/components/tag';
import { TextComponentPage } from './pages/components/text';
import { TextListComponentPage } from './pages/components/text-list';
import { ToastRackComponentPage } from './pages/components/toast-rack';
import { TopBarComponentPage } from './pages/components/top-bar';
import { ValidationChecklistComponentPage } from './pages/components/validation-checklist';

export interface RouteItem {
  path: string;
  Component: ElementType;
  title: ReactNode;
}

export interface Route {
  home: RouteItem;
  components: { [key: string]: RouteItem };
}

export const componentsPath = '/components';

export const routes: Route = {
  home: {
    path: '/',
    Component: Home,
    title: <FormattedMessage id="hive.titles.home" />,
  },
  components: {
    box: {
      path: 'box',
      Component: BoxComponentPage,
      title: <FormattedMessage id="hive.components.box.title" />,
    },
    button: {
      path: 'button',
      Component: ButtonComponentPage,
      title: <FormattedMessage id="hive.components.button.title" />,
    },
    breadcrumb: {
      path: 'breadcrumb',
      Component: BreadcrumbComponentPage,
      title: <FormattedMessage id="hive.components.breadcrumb.title" />,
    },
    card: {
      path: 'card',
      Component: CardComponentPage,
      title: <FormattedMessage id="hive.components.card.title" />,
    },
    cardControls: {
      path: 'cardcontrols',
      Component: CardControlsComponentPage,
      title: <FormattedMessage id="hive.components.cardControls.title" />,
    },
    checkbox: {
      path: 'checkbox',
      Component: CheckboxComponentPage,
      title: <FormattedMessage id="hive.components.checkbox.title" />,
    },
    choiceList: {
      path: 'choiceList',
      Component: ChoiceListComponentPage,
      title: <FormattedMessage id="hive.components.choiceList.title" />,
    },
    dataTable: {
      path: 'datatable',
      Component: DataTableComponentPage,
      title: <FormattedMessage id="hive.components.dataTable.title" />,
    },
    dateInput: {
      path: 'dateinput',
      Component: DateInputComponentPage,
      title: <FormattedMessage id="hive.components.dateInput.title" />,
    },
    divider: {
      path: 'divider',
      Component: DividerComponentPage,
      title: <FormattedMessage id="hive.components.divider.title" />,
    },
    dropdown: {
      path: 'dropdown',
      Component: DropdownComponentPage,
      title: <FormattedMessage id="hive.components.dropdown.title" />,
    },
    heading: {
      path: 'heading',
      Component: HeadingComponentPage,
      title: <FormattedMessage id="hive.components.heading.title" />,
    },
    icon: {
      path: 'icon',
      Component: IconComponentPage,
      title: <FormattedMessage id="hive.components.icon.title" />,
    },
    inputField: {
      path: 'inputfield',
      Component: InputFieldComponentPage,
      title: <FormattedMessage id="hive.components.inputField.title" />,
    },
    infoblock: {
      path: 'infoblock',
      Component: InfoblockComponentPage,
      title: <FormattedMessage id="hive.components.infoblock.title" />,
    },
    link: {
      path: 'link',
      Component: LinkComponentPage,
      title: <FormattedMessage id="hive.components.link.title" />,
    },
    list: {
      path: 'list',
      Component: ListComponentPage,
      title: <FormattedMessage id="hive.components.list.title" />,
    },

    loadingSpinner: {
      path: 'loadingspinner',
      Component: LoadingSpinnerComponentPage,
      title: <FormattedMessage id="hive.components.loadingSpinner.title" />,
    },
    modal: {
      path: 'modal',
      Component: ModalComponentPage,
      title: <FormattedMessage id="hive.components.modal.title" />,
    },
    paginatedTable: {
      path: 'paginatedtable',
      Component: PaginatedTableComponentPage,
      title: <FormattedMessage id="hive.components.paginatedTable.title" />,
    },
    pagination: {
      path: 'pagination',
      Component: PaginationComponentPage,
      title: <FormattedMessage id="hive.components.pagination.title" />,
    },
    passwordInput: {
      path: 'passwordinput',
      Component: PasswordInputComponentPage,
      title: <FormattedMessage id="hive.components.passwordInput.title" />,
    },
    pill: {
      path: 'pill',
      Component: PillComponentPage,
      title: <FormattedMessage id="hive.components.pill.title" />,
    },
    phoneNumberInput: {
      path: 'phonenumberinput',
      Component: PhoneNumberInputComponentPage,
      title: <FormattedMessage id="hive.components.phoneNumberInput.title" />,
    },
    primaryNav: {
      path: 'primarynav',
      Component: PrimaryNavComponentPage,
      title: <FormattedMessage id="hive.components.primaryNav.title" />,
    },
    progressIndicator: {
      path: 'progressindicator',
      Component: ProgressIndicatorComponentPage,
      title: <FormattedMessage id="hive.components.progressIndicator.title" />,
    },
    radioButton: {
      path: 'radiobutton',
      Component: RadioButtonComponentPage,
      title: <FormattedMessage id="hive.components.radioButton.title" />,
    },
    sortCodeInput: {
      path: 'sortcodeinput',
      Component: SortCodeInputPage,
      title: <FormattedMessage id="hive.components.sortCodeInput.title" />,
    },
    table: {
      path: 'table',
      Component: TableComponentPage,
      title: <FormattedMessage id="hive.components.table.title" />,
    },
    tag: {
      path: 'tag',
      Component: TagComponentPage,
      title: <FormattedMessage id="hive.components.tag.title" />,
    },
    text: {
      path: 'text',
      Component: TextComponentPage,
      title: <FormattedMessage id="hive.components.text.title" />,
    },
    textList: {
      path: 'text-list',
      Component: TextListComponentPage,
      title: <FormattedMessage id="hive.components.textList.title" />,
    },
    toast: {
      path: 'toast',
      Component: ToastRackComponentPage,
      title: <FormattedMessage id="hive.components.toast.title" />,
    },
    topBar: {
      path: 'topbar',
      Component: TopBarComponentPage,
      title: <FormattedMessage id="hive.components.topBar.title" />,
    },
    validationChecklist: {
      path: 'validationchecklist',
      Component: ValidationChecklistComponentPage,
      title: (
        <FormattedMessage id="hive.components.validationChecklist.title" />
      ),
    },
  },
};

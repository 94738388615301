import { useState } from 'react';

import { InputField, Text } from '@shieldpay/bumblebee';
import InputFieldMarkdown from '@shieldpay/bumblebee/markdown/input-field.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const InputFieldComponentPage = () => {
  usePageTitle({ id: 'hive.components.inputField.title' });

  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('name.domain.com');
  const [value3, setValue3] = useState<string | null>();
  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <InputField
          id="someInput1"
          label="I am a input field with some help"
          helpText="Fill this in for a prize"
          onChange={(value) => setValue1(value)}
          value={value1}
        />
        <InputField
          id="someInput2"
          label="I am a input field with some help and an error"
          helpText="Example: name@domain.com"
          validation={{ messages: ["Oops, that's not right"], valid: false }}
          showValidation={true}
          onChange={(value) => setValue2(value)}
          value={value2}
        />
        <InputField
          id="someInput3"
          label="I am a mandatory input field"
          onChange={(value) => setValue3(value)}
          validation={{
            messages: ["Oops, that's not right"],
            valid: value3 !== '',
          }}
          showValidation={value3 ? value3.length === 0 : true}
          showRequiredIndicator={true}
          value={value3 || ''}
        />
        <InputField
          id="someInput4"
          label="I am a disabled input field"
          value="Can't touch this"
          disabled
          onChange={noop}
        />
        <InputField
          id="someInput5"
          label="I am a input field with multiple error messages"
          onChange={noop}
          value={value2}
          validation={{
            valid: false,
            messages: ['hey', 'you'],
          }}
          showValidation={true}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The input field component (form field) is the interactive component
            used to capture information. The form field is used with the field
            label component and the helper text component if required.
          </Text>
          <Text paragraph>
            All form fields are based on the ‘master primitive’ component
            (inactive state). If any of the layers or details within the master
            component are changed, all form instances will be updated.
          </Text>
        </Design.Section>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            The form field height is constructed based on the ‘Field input‘ text
            style and padding. It retains a height of 52px across all viewports.
          </Text>
          <Text paragraph>
            The width of the form field is flexible, unless specifically
            constrained by a defined value or alignment. The width of the form
            field should match the width of the field label text box across all
            viewport sizes, unless otherwise specified.
          </Text>
          <Text paragraph>
            The form field has a 1px border with 4px rounded corners to be
            consistent with the buttons.
          </Text>
          <Text paragraph>
            When text is entered/displayed in the form field it uses the ‘Field
            input’ style (16px) across all viewports.
          </Text>
          <Text paragraph>
            It is not proposed to display a disabled state for an empty field.
            If a field is not required it should be hidden.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            The state of the form field changes depending on the actions of the
            user. The structure of how the instances behave are outlined, right.
          </Text>
          <Text paragraph>
            Transition to display a state change with a 0.25 ease.
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            The focused state is used to help accessibility and is consistent
            with the focus state used on other components. Use the appropriate
            aria attributes for screen readers where applicable.
          </Text>
          <Text paragraph>
            The ‘Text’ style colour used on entered text in the field, is
            comfortably within the AAA contrast ratio for legibility.
          </Text>
          <Text paragraph>
            Placeholder text will not be used within text fields. Placeholder
            text has to be dark enough to pass AA rating for legibility. It can
            confuse users who may think the field is already completed. When a
            detailed description or an example is required to complement a field
            label, ‘Helper text’ will be used.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <InputFieldMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

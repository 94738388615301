export type Locale = string | Array<string>;

// Creates an array of value/label pairs with months as local strings
export const createMonths = (locale?: Locale) =>
  Array.from({ length: 12 }).map((_, month) => ({
    value: month.toString(),
    label: new Date(`2000 ${month + 1}`).toLocaleString(locale, {
      month: 'long',
    }),
  }));

import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Card } from '../card/card';
import { gridItemStyles } from '../grid/grid-item';
import { Text } from '../text/text';

import * as styles from './list.styles';

interface ListProps {
  children: ReactNode;
  ordered?: boolean;
}

interface TextContentProps {
  children: ReactNode;
  label?: ReactNode;
}

interface DefaultListItemProps {
  children: ReactNode;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
  position?: never;
  label: ReactNode;
}

interface DisplayListItemProps {
  children: ReactNode;
  LeftIcon: ReactNode;
  RightIcon?: ReactNode;
  position?: never;
  label?: ReactNode;
}

interface TimelineListItemProps {
  children: ReactNode;
  LeftIcon: ReactNode;
  RightIcon?: ReactNode;
  position?: 'default' | 'first' | 'last';
  label?: ReactNode;
}

type ListItemProps =
  | ({ variant?: 'default' } & DefaultListItemProps)
  | ({ variant: 'display' } & DisplayListItemProps)
  | ({ variant: 'timeline' } & TimelineListItemProps);

const TextContent = ({ children, label }: TextContentProps) => (
  <Box
    expand
    spacing="baseNeg5"
    css={styles.content}
    alignItems={['full', 'center']}
  >
    {label ? (
      <Text variant="captionSmall" truncate>
        {label}
      </Text>
    ) : null}
    <Text variant="body" truncate>
      {children}
    </Text>
  </Box>
);

const DefaultListItem = ({
  children,
  LeftIcon,
  RightIcon,
  label,
}: DefaultListItemProps) => (
  <Box
    component="li"
    stack="row"
    alignItems={['full', 'center']}
    spacing="baseNeg3"
  >
    {LeftIcon}
    <TextContent label={label}>{children}</TextContent>
    {RightIcon}
  </Box>
);

const DisplayListItem = ({
  children,
  LeftIcon,
  RightIcon,
  label,
}: DisplayListItemProps) => {
  return (
    <Card
      component="li"
      stack="row"
      alignItems={['full', 'center']}
      padding={{ y: 'baseNeg4', left: 'baseNeg4', right: 'baseNeg2' }}
      spacing="base"
      css={styles.grid}
    >
      {LeftIcon}
      <TextContent label={label}>{children}</TextContent>
      {RightIcon}
    </Card>
  );
};

const TimelineListItem = ({
  children,
  LeftIcon,
  RightIcon,
  position,
}: TimelineListItemProps) => (
  <Box
    component="li"
    spacing="base"
    alignItems={false}
    css={[styles.grid, styles.timeline]}
  >
    <Box
      component="span"
      alignItems={['center', 'full']}
      css={[
        styles.line,
        gridItemStyles({
          columnStart: '1',
          rowStart: position === 'first' ? '2' : '1',
          rowEnd: position === 'default' ? 'span2' : undefined,
        }),
        position === 'last' && styles.lineLast,
      ]}
    />
    <Box
      component="span"
      alignItems={['center', 'center']}
      css={[
        gridItemStyles({
          columnStart: '1',
          rowStart: '1',
          rowEnd: 'span2',
        }),
      ]}
    >
      {/* we give this a white background to knock out the line */}
      <Box css={[styles.leftIcon]}>{LeftIcon}</Box>
    </Box>
    <Box
      css={gridItemStyles({ columnStart: '2', rowStart: '1', rowEnd: 'span2' })}
      stack="column"
      spacing="baseNeg5"
    >
      {children}
    </Box>
    {RightIcon ? (
      <Box
        css={gridItemStyles({
          rowStart: '1',
          rowEnd: 'span2',
        })}
      >
        {RightIcon}
      </Box>
    ) : null}
  </Box>
);

const ListItem = ({
  children,
  variant = 'default',
  position = 'default',
  LeftIcon,
  RightIcon,
  label,
}: ListItemProps) => {
  if (variant === 'default') {
    return (
      <DefaultListItem LeftIcon={LeftIcon} label={label} RightIcon={RightIcon}>
        {children}
      </DefaultListItem>
    );
  }

  if (!LeftIcon) {
    throw new Error('no LeftIcon');
  }

  if (variant === 'display') {
    return (
      <DisplayListItem LeftIcon={LeftIcon} label={label} RightIcon={RightIcon}>
        {children}
      </DisplayListItem>
    );
  }

  return (
    <TimelineListItem
      position={position}
      LeftIcon={LeftIcon}
      label={label}
      RightIcon={RightIcon}
    >
      {children}
    </TimelineListItem>
  );
};

export const List = ({ children, ordered = false }: ListProps) => {
  const listType = ordered ? 'ol' : 'ul';

  return (
    <Box component={listType} spacing="basePos2" expand>
      {children}
    </Box>
  );
};

List.Item = ListItem;

import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  BumblebeeLayout,
  Button,
  NavigationLogo,
  PrimaryNav,
  Text,
} from '@shieldpay/bumblebee';

import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { componentsPath, routes } from '../../routes';
import { BumblebeeIcon } from '../logos/bumblebee-icon';
import { BumblebeeLogo } from '../logos/bumblebee-logo';

interface LayoutProps {
  children: ReactNode;
}

const {
  home: { title: homeTitle, path: homePath },
} = routes;

const NavItems = () => (
  <>
    <PrimaryNav.Heading>
      <FormattedMessage id="hive.titles.overview" />
    </PrimaryNav.Heading>

    <PrimaryNav.Item to={homePath}>{homeTitle}</PrimaryNav.Item>

    <PrimaryNav.Heading>
      <FormattedMessage id="hive.titles.components" />
    </PrimaryNav.Heading>

    {Object.entries(routes.components).map(([key, { path, title }]) => (
      <PrimaryNav.Item key={key} to={`${componentsPath}/${path}`}>
        {title}
      </PrimaryNav.Item>
    ))}
  </>
);

export const Layout = ({ children }: LayoutProps) => {
  const { handleScrollToTop } = useScrollToTop();
  return (
    <BumblebeeLayout
      header={
        <NavigationLogo
          to={homePath}
          icon={BumblebeeIcon}
          logo={BumblebeeLogo}
        />
      }
      navItems={<NavItems />}
    >
      <Box spacing="basePos4">{children}</Box>
      <Box spacing="base" alignItems={['center', 'center']}>
        <Text paragraph>
          <Button variant="text-underline" onClick={handleScrollToTop}>
            Back to top
          </Button>
        </Text>
      </Box>
    </BumblebeeLayout>
  );
};

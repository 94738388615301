import { onlyText } from '@shieldpay/utility-functions-ui';

import { DecoratedToastContent, ToastContent } from './types';

const CHARACTER_TIME_MULTIPLIER = 100;

export const isDecoratedToastContent = (
  toastContent: ToastContent,
): toastContent is DecoratedToastContent =>
  Object.prototype.hasOwnProperty.call(toastContent, 'icon');

/**
 * Accepts a toast notification and calculates the character length
 */
export const getDismissalTimeFromCharacterLength = (
  toastContent: ToastContent,
  characterTimeMultiplier = CHARACTER_TIME_MULTIPLIER,
): number => {
  const text = isDecoratedToastContent(toastContent)
    ? onlyText(toastContent.text)
    : onlyText(toastContent);
  return text.length * characterTimeMultiplier;
};

import { themeifyStyle } from '../../themes/utilities';

export const pill = themeifyStyle(({ spacing }) => ({
  borderRadius: `${spacing.basePos2}px`,
  maxWidth: `${spacing.basePos11}px`,
}));

export const variant = {
  default: themeifyStyle(({ palette, spacing }) => ({
    border: `${spacing.baseNeg8}px solid ${palette.neutral500}`,
    backgroundColor: `${palette.neutral150}`,
  })),
  success: themeifyStyle(({ palette, spacing }) => ({
    border: `${spacing.baseNeg8}px solid ${palette.secondary500}`,
    backgroundColor: `${palette.secondary200}`,
  })),
  action: themeifyStyle(({ palette, spacing }) => ({
    border: `${spacing.baseNeg8}px solid ${palette.primary500}`,
    backgroundColor: `${palette.primary200}`,
  })),
  alert: themeifyStyle(({ palette, spacing }) => ({
    border: `${spacing.baseNeg8}px solid ${palette.alert500}`,
    backgroundColor: `${palette.alert200}`,
  })),
};

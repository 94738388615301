export const stringToDigitsOnly = (string = '') => string.replace(/\D/g, '');

/**
 * converts a digit-only sort code to a value with hyphens between pairs of values
 */
export const sortCodeToFormattedValue = (string = '') => {
  // for safety make sure there are only digits first
  const digitsOnly = stringToDigitsOnly(string);

  return [
    digitsOnly.slice(0, 2),
    digitsOnly.slice(2, 4),
    digitsOnly.slice(4, 6),
  ]
    .filter(Boolean)
    .join('-');
};

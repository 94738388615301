import { ReactNode } from 'react';

import { TableHeaderCellProps } from './table-header-cell';
export interface TableHeadProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Child nodes (e.g. TableRow, TableHeaderCell).
   */
  children: ReactNode;
}

export const TableHead = ({ children, className }: TableHeaderCellProps) => {
  return <thead className={className}>{children}</thead>;
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgBankWithBgIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 46 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={46} height={30} rx={1.852} fill="#F6F7F8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.563 13.355v7.5h-1.625v-7.5h1.625zM26.063 13.355v7.5h-1.625v-7.5h1.625zM30.563 13.355v7.5h-1.625v-7.5h1.625zM17.063 13.355v7.5h-1.625v-7.5h1.624zM31.125 10.23v-.335L23 6.145l-8.125 3.75v.335h16.25zm.813 1.625H14.063a.812.812 0 01-.813-.812V9.375c0-.317.184-.605.472-.738l8.938-4.125c.216-.1.464-.1.68 0l8.938 4.125c.288.133.472.421.472.738v1.668a.812.812 0 01-.813.812zM32.75 23.918h-19.5v-1.625h19.5v1.625z"
        fill="#606C85"
      />
    </svg>,
  );
};

export default SvgBankWithBgIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgSafetyIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7a5 5 0 015-5h2a5 5 0 015 5v6h-2V7a3 3 0 00-3-3h-2a3 3 0 00-3 3v6h-2V7z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13v15h18V13H7zm-1-2a1 1 0 00-1 1v17a1 1 0 001 1h20a1 1 0 001-1V12a1 1 0 00-1-1H6z"
        fill="#353C4A"
      />
      <path d="M18 20.5a2 2 0 11-4 0 2 2 0 014 0z" fill="#353C4A" />
    </svg>,
  );
};

export default SvgSafetyIcon;

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgHideIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16s3 10 13 10 13-10 13-10S26 6 16 6 3 16 3 16zm2.126 0a15.202 15.202 0 002.185 3.75C9.049 21.922 11.772 24 16 24s6.952-2.079 8.688-4.25A15.201 15.201 0 0026.874 16a15.201 15.201 0 00-2.185-3.75C22.951 10.078 20.228 8 16 8s-6.952 2.079-8.688 4.25A15.202 15.202 0 005.126 16z"
        fill="#79869F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 20a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 100-12 6 6 0 000 12z"
        fill="#79869F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.226 26.367l18-22 1.548 1.266-18 22-1.548-1.266z"
        fill="#79869F"
      />
    </svg>,
  );
};

export default SvgHideIcon;

import { Breadcrumb, Text } from '@shieldpay/bumblebee';
import BreadcrumbMarkdown from '@shieldpay/bumblebee/markdown/breadcrumb.md';
import { useAriaLabel, usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

const breadcrumbItems = [
  {
    path: '/',
    label: 'Home',
  },
  {
    path: '/components/toast',
    label: 'Internal Toast',
  },
  {
    path: 'https://www.collinsdictionary.com/dictionary/english/toast',
    label: 'External Toast',
  },
  {
    path: '',
    label: 'Eaten Toast',
  },
];

export const BreadcrumbComponentPage = () => {
  usePageTitle({ id: 'hive.components.breadcrumb.title' });
  const { ariaLabel } = useAriaLabel({
    id: 'hive.components.breadcrumb.label',
  });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Breadcrumb ariaLabel={ariaLabel} items={breadcrumbItems} />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The breadcrumb component will be when the user has opened a
            sub-page. It can be used for instant navigation between the pages -
            the count of text links will depend on the usage(3 sub-pages = 3
            links in breadcrumbs). It helps users find their place within a
            website or web application.
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            A breadcrumb will consist of 1 or more text links, a chevron symbol
            between the elements.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            A breadcrumb can be used by the user in multiple ways.
          </Text>
          <Text paragraph>
            The user can see a list of links to the parent pages from the
            sub-page(current page). The user can hover upon and click on the
            text link to turn back to the parent page. The user can also use the
            keyboard button to interact(focus state). The current page shows as
            a disabled link in the breadcrumb.
          </Text>
          <Text paragraph>
            Selecting(redirect) any parent page on the breadcrumb trail should
            change the breadcrumb list and show the last text link in disabled
            state as a current page.
          </Text>
          <Text paragraph>
            Please refer to Behaviour and Prototype for examples
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            Breadcrumbs are placed horizontally before a page’s main content.
          </Text>
          <Text paragraph>
            Please refer to Placement in page designs for examples.
          </Text>
          <Text paragraph>This is also outlined in Core Layouts</Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the appropriate ARIA attributes for screen readers where
            applicable.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <BreadcrumbMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

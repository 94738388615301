import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export { color } from '../text/use-text.styles';

export const icon = css({
  display: 'inline-block',
  verticalAlign: 'middle',
});

export const iconType = {
  default: { color: 'currentColor' },
  warning: themeifyStyle(({ palette }) => ({ color: palette.alert500 })),
  error: themeifyStyle(({ palette }) => ({ color: palette.alert600 })),
  info: themeifyStyle(({ palette }) => ({ color: palette.info600 })),
};

export const iconSize = {
  xs: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.xs,
    width: iconSizes.xs,
  })),
  s: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.s,
    width: iconSizes.s,
  })),
  m: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.m,
    width: iconSizes.m,
  })),
  l: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.l,
    width: iconSizes.l,
  })),
  xl: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.xl,
    width: iconSizes.xl,
  })),
};

export const svg = css({
  height: '100%',
  width: 'auto',
  '*': {
    fill: 'currentColor', // inherit the icon color from parent
  },
  '[stroke]': {
    /**
     * We remove the fill on current color when an element in an svg has a stroke attribute,
     * and apply currentColor on the stroke instead. This assume that all icons will be
     * one colour and no element will have a mix of stroke and fill.
     */
    fill: 'revert',
    stroke: 'currentColor',
  },
});

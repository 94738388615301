import { FormattedMessage } from 'react-intl';

import { Box, Card, Heading, Link } from '@shieldpay/bumblebee';

export const CONTENT_IDS = {
  LIVE_DEMO: 'live-demo',
  DESIGN: 'design',
  CODE: 'code',
};

export const ContentsTable = () => {
  return (
    <Box alignItems={['left', 'top']}>
      <Card alignItems={['left', 'top']} spacing={'basePos3'}>
        <Heading level="2" variant="captionMedium150">
          <FormattedMessage id="hive.titles.contents" />
        </Heading>
        <Link href={`#${CONTENT_IDS.LIVE_DEMO}`}>
          <FormattedMessage id="hive.titles.liveDemo" />
        </Link>
        <Link href={`#${CONTENT_IDS.DESIGN}`}>
          <FormattedMessage id="hive.titles.design" />
        </Link>
        <Link href={`#${CONTENT_IDS.CODE}`}>
          <FormattedMessage id="hive.titles.code" />
        </Link>
      </Card>
    </Box>
  );
};

import { css } from '@emotion/react';

import { themeifyStyle } from '../../themes/utilities';

export const textAlign = {
  right: css({
    textAlign: 'right',
  }),
  left: css({
    textAlign: 'left',
  }),
  center: css({
    textAlign: 'center',
  }),
};

export const base = themeifyStyle((theme) => ({
  verticalAlign: 'top',
  padding: `${theme.spacing.base}px ${theme.spacing.baseNeg5}px`,
}));

export const indent = themeifyStyle((theme) => ({
  ':first-of-type': {
    paddingLeft: `${theme.spacing.basePos4}px`,
  },
  ':last-of-type': {
    paddingRight: `${theme.spacing.basePos4}px`,
  },
}));

export const link = css({
  position: 'absolute',
  width: '100%',
  left: 0,
  padding: 0,
  height: '100%',
  a: {
    display: 'block',
    border: 'none',
    height: '100%',
  },
});

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgCardIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 7.5h-23v17h23v-17zm-23-2a2 2 0 00-2 2v17a2 2 0 002 2h23a2 2 0 002-2v-17a2 2 0 00-2-2h-23z"
        fill="#353C4A"
      />
      <path d="M4.5 11h23v4h-23v-4z" fill="#353C4A" />
    </svg>,
  );
};

export default SvgCardIcon;

import { useState } from 'react';

import { Dropdown, DropdownField, Heading, Text } from '@shieldpay/bumblebee';
import DropdownMarkdown from '@shieldpay/bumblebee/markdown/dropdown.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

const exampleItems = [
  { label: 'Bank of Mum‘n’Dad', value: '11' },
  { label: 'Fine Ants', value: '22' },
  {
    label: 'The Royal Bank of Haywards Heath',
    value: '33',
  },
  { label: 'WTF Bank', value: '44' },
  { label: 'Shieldbank', value: '55' },
  { label: 'Paybank', value: '66' },
  { label: 'Bank of Pay', value: '77' },
  { label: 'Bank of Shield', value: '88' },
  { label: 'Bank of Single dads', value: 'a11' },
  { label: 'Fine Schwine', value: 'a22' },
  {
    label: 'The Royal Bank of HH',
    value: 'a33',
  },
  { label: 'WTAF Bank', value: 'a44' },
  { label: 'BankShield', value: 'a55' },
  { label: 'BankPay', value: 'a66' },
  { label: 'Pay Society', value: 'a77' },
  { label: 'Shield Society', value: 'a88' },
  { label: 'Bank of Mum‘n’Dad', value: 'b11' },
  { label: 'Fine Ants', value: 'b22' },
  {
    label: 'The Royal Bank of Haywards Heath',
    value: 'b33',
  },
  { label: 'WTF Bank', value: 'b44' },
  { label: 'Shieldbank', value: 'b55' },
  { label: 'Paybank', value: 'b66' },
  { label: 'Bank of Pay', value: 'b77' },
  { label: 'Bank of Shield', value: 'b88' },
  { label: 'Bank of Single dads', value: 'ca11' },
  { label: 'Fine Schwine', value: 'ca22' },
  {
    label: 'The Royal Bank of HH',
    value: 'ca33',
  },
  { label: 'WTAF Bank', value: 'ca44' },
  { label: 'BankShield', value: 'ca55' },
  { label: 'BankPay', value: 'ca66' },
  { label: 'Pay Society', value: 'ca77' },
  { label: 'Shield Society', value: 'ca88' },
];

export const DropdownComponentPage = () => {
  usePageTitle({ id: 'hive.components.dropdown.title' });

  const [controlledValue, setControlledValue] = useState(exampleItems[3].value);

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Dropdown
          label={<>Regular size dropdown</>}
          placeholder="Select account…"
          items={exampleItems}
          onChange={noop}
        />
        <Dropdown
          label={<>Large dropdown</>}
          size="large"
          placeholder="Select account…"
          items={exampleItems}
          onChange={noop}
        />
        <Dropdown
          label={<>disabled</>}
          placeholder="Select account…"
          disabled
          items={exampleItems}
          onChange={noop}
        />
        <Dropdown
          label={<>Controlled dropdown with selected value</>}
          placeholder="Select account…"
          items={exampleItems}
          value={controlledValue}
          onChange={setControlledValue}
        />
        <DropdownField
          id="yo"
          label={<>DropdownField</>}
          placeholder="Select account…"
          items={exampleItems}
          onChange={noop}
        />
        <DropdownField
          id="yo"
          size="large"
          label={<>DropdownField with validation error</>}
          placeholder="Select account…"
          validation={{ messages: ['oof!'], valid: false }}
          showValidation={true}
          items={exampleItems}
          onChange={noop}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The dropdown component will be when the user has multiple options to
            choose from. It can be used within a form to alter the content of a
            page - the type of dropdown will depend on the usage.
          </Text>
          <Text paragraph>
            This is to be used on a form when the user is or to alter the
            content of a page
          </Text>
        </Design.Section>
        <Design.Section heading="Specs">
          <Text paragraph>
            A dropdown will consist of a background plate, a chevron icon, an
            open panel list with options and (depending on its type) a text
            label.
          </Text>
          <Heading level="4" variant="title">
            Large dropdown
          </Heading>
          <Text paragraph>
            This is a regular full-sized dropdown to be used along with text
            inputs in forms and against a large button component. It will have
            the same attributes as a text input including a text label and error
            functionality.
          </Text>
          <Heading level="4" variant="title">
            Medium dropdown
          </Heading>
          <Text paragraph>
            This is a slightly shorter dropdown to be used outside of the
            context of a form when there is less space on the page (i.e.
            selecting a marketplace on the Balances page) and against a small
            button component.
          </Text>
          <Text paragraph>
            This is a slightly shorter dropdown to be used outside of the
            context of a form when there is less space on the page (i.e.
            selecting a marketplace on the Balances page) and against a small
            button component.
          </Text>
        </Design.Section>
        <Text paragraph>Please refer to Specs for examples</Text>
        <Design.Section heading="Behaviour">
          <Text paragraph>
            The dropdown component can be opened by the user in multiple ways.
          </Text>
          <Text paragraph>
            The user can hover upon and click on the dropdown to open it and
            then hover over and select the options. The user can also use the
            keyboard button to interact.
          </Text>
          <Text paragraph>
            When the dropdown is in the Active state, the user should be able to
            hover over other items while it is Active.
          </Text>
          <Text paragraph>
            Selecting any option on the dropdown list should change the dropdown
            state from Active back to Focus and populate the field with the
            selected option. Clicking anywhere outside the dropdown list should
            change the dropdown state to Default.
          </Text>
          <Text paragraph>
            Transition to display a state change with a 0.25 ease.
          </Text>
          <Text paragraph>
            Animations In (dropdown opening) to be a Scale at Cubic Ease-out at
            120ms
          </Text>
          <Text paragraph>
            Animations Out (dropdown closing) should not have any motion.
          </Text>

          <Heading level="4" variant="title">
            Reverse active state
          </Heading>
          <Text paragraph>
            In the case where there is no space below a dropdown on the viewport
            (i.e. the placement is at the bottom of the screen or the user has
            scrolled) the Options list should open upwards instead of downwards.
          </Text>

          <Heading level="4" variant="title">
            Scrollable option list
          </Heading>
          <Text paragraph>
            The scrollable Options list should display a maximum of 6 options,
            after which it should scroll.
          </Text>
          <Text paragraph>
            The user should be able to use the mouse, the keyboard (once active)
            or click and drag the scrollbar.
          </Text>
          <Text paragraph>
            Accessible behaviour for scrollable Options lists should be
            consistent i.e the focus state should be shown if they pres ENTER on
            the field to it after which the user should be able to use Tab or Up
            and Down arrows to select an option. The Options list should always
            only display 6 options.
          </Text>
          <Text paragraph>
            The scrollbar should resize automatically depending on the amount of
            options in the Option list.
          </Text>
          <Text paragraph>
            Please refer to Behaviour and Prototype for examples
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>No standard placement for the dropdown.</Text>
          <Text paragraph>
            Please refer to Placement in page designs for examples
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Use the appropriate ARIA attributes for screen readers where
            applicable.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <DropdownMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgFilledCheckIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 29c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13zm-2.289-7.353l9.229-9.229-1.415-1.414-7.814 7.814-3.004-3.004-1.414 1.415 4.418 4.418z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgFilledCheckIcon;

import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Heading } from '@shieldpay/bumblebee';

import { CONTENT_IDS } from '../contents-table/contents-table';

export interface DesignProps {
  children: ReactNode;
}

export interface DesignSectionProps {
  heading: ReactNode;
  children: ReactNode;
}

export const Design = ({ children }: DesignProps) => {
  return (
    <Box spacing="base">
      <Heading level="2" id={CONTENT_IDS.DESIGN}>
        <FormattedMessage id="hive.titles.design" />
      </Heading>
      {children}
    </Box>
  );
};

const DesignSection = ({ children, heading }: DesignSectionProps) => (
  <Box spacing="base">
    <Heading level="3" variant="title">
      {heading}
    </Heading>
    <Box spacing="base">{children}</Box>
  </Box>
);

Design.Section = DesignSection;

import { ForwardedRef, forwardRef } from 'react';
import { Theme, useTheme } from '@emotion/react';

import { Box, BoxProps } from '../box/box';

import * as styles from './card.styles';

export interface CardProps extends Omit<BoxProps, 'rounded'> {
  variant?: keyof typeof styles.variant;
  background?: keyof Theme['palette'];
  'data-testid'?: string;
}

export const TEST_IDS = {
  CARD: 'card-component',
};

/**
 * Do not use the CardWithoutForwardRef export in the platform, use the Card export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const CardWithoutForwardRef = <T,>(
  {
    variant = 'primary',
    padding = 'basePos4',
    'data-testid': testId = TEST_IDS.CARD,
    stack,
    background = 'neutral000',
    className,
    ...props
  }: CardProps,
  ref: ForwardedRef<T>,
) => {
  const theme = useTheme();

  return (
    <Box
      className={className}
      {...props}
      css={[styles.variant[variant], styles.background(theme)[background]]}
      data-testid={testId}
      padding={padding}
      ref={ref}
      stack={stack}
      rounded
    />
  );
};

// Manually reset the displayName in the component tree.
CardWithoutForwardRef.displayName = 'Card';

export const Card = forwardRef(CardWithoutForwardRef);

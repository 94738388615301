import { ReactNode } from 'react';

import { FieldContainerBaseProps } from '../field-container/field-container';
import { FormControl, FormControlProps } from '../form-control/form-control';

import * as styles from '../form-control/form-control.styles';

export const TEST_IDS = {
  OVERLAY_SVG: 'checkbox-overlay-svg',
};

export interface CheckboxControlProps
  extends Omit<FieldContainerBaseProps, 'children' | 'label'>,
    Omit<FormControlProps, 'type'> {
  'data-testid'?: string;
  children?: ReactNode;
  validating?: boolean;
}

export const CheckboxControl = ({
  checked,
  onClick,
  label,
  disabled,
  id,
}: CheckboxControlProps) => {
  return (
    <FormControl
      type="checkbox"
      id={id}
      label={label}
      checked={checked}
      disabled={disabled}
      onClick={onClick}
    >
      <svg
        css={styles.fauxInputSvg}
        data-testid={TEST_IDS.OVERLAY_SVG}
        width="14"
        height="12"
        viewBox="0 0 14 12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 2.70001L5.09999 11.1L0.299988 6.20001L2.39999 4.10001L5.19999 6.90001L11.4 0.600006L13.5 2.70001Z"
        />
      </svg>
    </FormControl>
  );
};

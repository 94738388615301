import { css } from '@emotion/react';

const MVP_DIAL_CODE_WIDTH = 123;

export const dialCode = css({
  flex: `0 0 ${MVP_DIAL_CODE_WIDTH}px`,
});

export const dialCodeInput = css({
  width: `${MVP_DIAL_CODE_WIDTH}px`,
});

export const phoneNumber = css({
  flex: '1 0',
});

import { ReactNode } from 'react';
import { UseSelectPropGetters } from 'downshift';

import { Box } from '../box/box';
import { Button } from '../button/button';
import { Card } from '../card/card';
import { Text } from '../text/text';

import * as styles from './user-menu-legacy.styles';

export interface UserMenuCardProps {
  email: string;
  group: string;
  logOutText: ReactNode;
}

export const TEST_IDS = {
  EMAIL: 'user-menu-card--email',
  GROUP: 'user-menu-card--group',
};

export const items = ['logout'];

export const UserMenuCard = ({
  email,
  group,
  logOutText,
  getItemProps,
  getMenuProps,
  logoutSelected,
}: UserMenuCardProps & {
  logoutSelected: boolean;
} & Pick<UseSelectPropGetters<string>, 'getItemProps' | 'getMenuProps'>) => (
  <Card
    variant="lightShadow"
    alignItems={['left', 'top']}
    role="menu"
    spacing="basePos4"
    padding={{ x: 'basePos3', top: 'basePos3', bottom: 'basePos4' }}
    {...getMenuProps()}
  >
    <Box spacing="baseNeg4">
      <Text paragraph data-testid={TEST_IDS.EMAIL}>
        {email}
      </Text>
      <Text paragraph data-testid={TEST_IDS.GROUP}>
        {group}
      </Text>
    </Box>
    <Text paragraph>
      <Button
        role="menuitem"
        size="small"
        css={[styles.logout, logoutSelected && styles.logoutSelected]}
        variant="text-underline"
        {...getItemProps({
          index: 0,
          item: items[0],
        })}
      >
        {logOutText}
      </Button>
    </Text>
  </Card>
);

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgEditIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#edit-icon_svg__clip0_3434_7045)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#353C4A"
      >
        <path d="M6.181 23.625c-.717.1-1.3-.571-1.1-1.267l1.443-5.035a.997.997 0 01.304-.477L19.616 5.73a1 1 0 011.41.1l3.742 4.305a.999.999 0 01-.097 1.41L11.883 22.66a.997.997 0 01-.515.234l-5.187.73zM20.172 7.896l2.43 2.796-11.807 10.264-3.368.474.938-3.27L20.172 7.896z" />
        <path d="M25.573 5.656c-.697-.803-1.844-.852-2.552-.236l-1.338 1.162 2.431 2.796 1.337-1.162c.709-.616.82-1.758.122-2.56zM21.71 3.91c1.572-1.367 3.978-1.173 5.373.432 1.396 1.606 1.253 4.016-.319 5.382L23.917 12.2l-5.055-5.815 2.847-2.474zM3 27.635a1 1 0 011-1h24a1 1 0 110 2H4a1 1 0 01-1-1z" />
      </g>
      <defs>
        <clipPath id="edit-icon_svg__clip0_3434_7045">
          <path fill="#fff" transform="translate(3 3)" d="M0 0h26v25.635H0z" />
        </clipPath>
      </defs>
    </svg>,
  );
};

export default SvgEditIcon;

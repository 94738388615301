import { css } from '@emotion/react';

import { transformValues } from '@shieldpay/utility-functions-ui';

import { Breakpoint } from '../../themes/types';
import {
  createThemedStyleObjects,
  isResponsive,
  responsiveStyle,
} from '../../themes/utilities';

export const heading = css({
  margin: 0,
  padding: 0,
});

export const [variant] = createThemedStyleObjects((theme) => {
  const { typography } = theme;

  // Generate an object of CSSObjects from the `theme.typography.variant` tokens.
  return transformValues(
    typography.variant,
    // Callback to generate a CSSObject for a variant
    ({ fontSize, lineHeight, fontWeight, textTransform }) => {
      const staticStyles = {
        lineHeight: typography.lineHeight[lineHeight],
        fontWeight: typography.fontWeight[fontWeight],
        textTransform,
      };

      if (isResponsive(fontSize)) {
        return {
          ...staticStyles,
          ...responsiveStyle(
            // responsiveStyle accepts an array in the shape of
            // [{breakpoint: 'xl', styles: {fontSize}}]
            (Object.entries(fontSize) as [Breakpoint, number][]).map(
              ([breakpoint, fontSize]) => ({
                breakpoint,
                styles: { fontSize },
              }),
            ),
            theme,
          ),
        };
      }

      return {
        ...staticStyles,
        fontSize,
      };
    },
  );
});

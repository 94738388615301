import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Card } from '../card/card';
import { Columns, DataTable, RowLink, Rows } from '../data-table/data-table';
import { Pagination, PaginationLegacy } from '../pagination/pagination';
import { Text } from '../text/text';

export const TEST_IDS = {
  COLLECTION: 'paginated-table',
  TABLE: 'paginated-table-data-table',
  FALLBACK: 'paginated-table-fallback-message',
};

export interface PaginatedTableProps {
  children?: ReactNode;
  /**
   * Each object is a table row and each object key represents a table cell.
   */
  rows: Rows;
  /**
   * Each object is a table column.
   */
  columns: Columns;
  /**
   * A message to render when row data is empty.
   */
  fallbackMessage?: ReactNode;
  /**
   * Table heading. Visually hidden but accessible to screen readers.
   */
  caption: ReactNode;
  /**
   * Configure whether the collection should have a border, defaults to 'bordered'.
   */
  variant?: 'bordered' | 'borderless';
  /**
   * Initial data fetching status.
   */
  isLoading?: boolean;
  /**
   * Adds a visually hidden table column with path as cell value to make entire row clickable.
   */
  rowLink?: RowLink;
  'data-testid'?: string;
}

export const PaginatedTable = ({
  rows,
  columns,
  caption,
  children,
  fallbackMessage,
  isLoading = false,
  variant = 'bordered',
  rowLink,
  'data-testid': testId = TEST_IDS.COLLECTION,
}: PaginatedTableProps) => {
  const Component = variant === 'bordered' ? Card : Box;

  return (
    <Component
      alignItems={['full', 'full']}
      padding="none"
      data-testid={testId}
      expand
    >
      <DataTable
        caption={caption}
        rows={rows}
        columns={columns}
        variant="borderless"
        data-testid={TEST_IDS.TABLE}
        rowLink={rowLink}
      />
      {!rows.length && !isLoading ? (
        <Box
          alignItems={['center', 'center']}
          padding="basePos3"
          data-testid={TEST_IDS.FALLBACK}
          expand
        >
          <Text paragraph variant="body">
            {fallbackMessage}
          </Text>
        </Box>
      ) : null}

      {children}
    </Component>
  );
};

PaginatedTable.Pagination = Pagination;
PaginatedTable.PaginationLegacy = PaginationLegacy;

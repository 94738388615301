import { ReactNode } from 'react';

import { Text } from '../text/text';

import * as styles from './table-cell.styles';

export interface TableRowLinkHeaderCellProps {
  /**
   * Additional classes to apply.
   */
  className?: string;
  /**
   * Visually hidden but available for screen readers.
   */
  linkHeader: ReactNode;
  /**
   * When the header applies to multiple columns.
   */
  colSpan?: number;
  /**
   * When the header applies to multiple rows.
   **/
  rowSpan?: number;
  /**
   * To help screen readers interpret tables.
   * In the header row, the col value for scope associates each header cell with the data cells in the column.
   * In the header column, the row value associates the individual headers with their rows.
   */
  scope?: 'row' | 'col';
}

export const TableRowLinkHeaderCell = ({
  linkHeader,
  colSpan = 1,
  rowSpan = 1,
  scope = 'col',
  className,
}: TableRowLinkHeaderCellProps) => {
  return (
    <th
      colSpan={colSpan}
      rowSpan={rowSpan}
      scope={scope}
      css={styles.link}
      className={className}
    >
      <Text variant="captionMedium150" screenReaderOnly>
        {linkHeader}
      </Text>
    </th>
  );
};

import { css } from '@emotion/react';

export const animating = css({
  overflowY: 'clip',
  // Suggest reviewing if we still need fallback end of 2023 onwards.
  '@supports not (overflow: clip)': {
    overflow: 'hidden',
  },
});

export const hide = css({
  visibility: 'hidden',
  pointerEvents: 'none',
});

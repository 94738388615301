import { css, Theme } from '@emotion/react';

export const toastRack = ({ spacing }: Theme) =>
  css({
    position: 'fixed',
    bottom: spacing.basePos6,
    left: 0,
    right: 0,
    flexDirection: 'column-reverse',
  });

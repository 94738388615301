import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgDocumentIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 0h32v32H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 26V12.165L17.323 6H10v20h13zM9 4a1 1 0 00-1 1v22a1 1 0 001 1h15a1 1 0 001-1V11.775a1 1 0 00-.264-.678l-6.239-6.774A1 1 0 0017.761 4H9z"
        fill="#353C4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11V6h2v5h5v2h-5a2 2 0 01-2-2z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgDocumentIcon;

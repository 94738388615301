import { css } from '@emotion/react';

import { createThemedStyleObjects, spacingStyle } from '../../themes/utilities';

export const base = css({
  display: 'grid',
});

export const fullHeight = css({
  minHeight: '100%',
});

export const [spacing] = createThemedStyleObjects((theme) =>
  spacingStyle(theme.spacing),
);

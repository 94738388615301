import { memo, ReactNode, useMemo } from 'react';

import { Box } from '../box/box';
import {
  DropdownField,
  DropdownFieldProps,
} from '../dropdown-field/dropdown-field';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';
import { InputField, InputFieldProps } from '../input-field/input-field';

import { createMonths, Locale } from './utilities';

import * as styles from './date-input.styles';

export interface DateInputProps
  extends Pick<FieldContainerBaseProps, 'label' | 'id' | 'disabled'> {
  dayInputProps: Omit<InputFieldProps, 'label'>;
  monthInputProps: Omit<DropdownFieldProps, 'label' | 'items'>;
  yearInputProps: Omit<InputFieldProps, 'label'>;
  dayLabel: ReactNode;
  dayPlaceholder: string;
  monthLabel: ReactNode;
  monthPlaceholder: string;
  yearLabel: ReactNode;
  yearPlaceholder: string;
  validationVisibilityProps?: Record<
    string,
    { showValidation: boolean; onBlur: () => void }
  >;
  locale: Locale;
}

export const DateInputWithoutMemo = ({
  validationVisibilityProps,
  label,
  id,
  dayInputProps: { validation: dayValidation, ...dayProps },
  dayLabel,
  dayPlaceholder,
  monthInputProps: { validation: monthValidation, ...monthProps },
  monthLabel,
  monthPlaceholder,
  yearInputProps: { validation: yearValidation, ...yearProps },
  yearLabel,
  yearPlaceholder,
  disabled,
  locale = 'en-GB',
}: DateInputProps) => {
  const months = useMemo(() => createMonths(locale), [locale]);

  return (
    <FieldContainer
      variant="fieldGroup"
      label={label}
      id={id}
      disabled={disabled}
      validation={[dayValidation, monthValidation, yearValidation]}
      showValidation={
        validationVisibilityProps
          ? [dayProps.id, monthProps.id, yearProps.id].some(
              (id) => validationVisibilityProps[id]?.showValidation,
            )
          : true
      }
    >
      {/* uses custom css grid styles - TODO support natively in component? */}
      <Box alignItems={false} spacing="base" css={styles.fields}>
        <InputField
          label={dayLabel}
          placeholder={dayPlaceholder}
          disabled={disabled}
          {...dayProps}
          {...validationVisibilityProps?.[dayProps.id]}
        />

        <DropdownField
          items={months}
          label={monthLabel}
          placeholder={monthPlaceholder}
          disabled={disabled}
          {...monthProps}
          {...validationVisibilityProps?.[monthProps.id]}
          size="large"
        />

        <InputField
          label={yearLabel}
          placeholder={yearPlaceholder}
          disabled={disabled}
          {...yearProps}
          {...validationVisibilityProps?.[yearProps.id]}
        />
      </Box>
    </FieldContainer>
  );
};

DateInputWithoutMemo.displayName = 'DateInput';

export const DateInput = memo(DateInputWithoutMemo);

import { Box, Pill, Text, TextList } from '@shieldpay/bumblebee';
import PillMarkdown from '@shieldpay/bumblebee/markdown/pill.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const PillComponentPage = () => {
  usePageTitle({ id: 'hive.components.pill.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview alignItems={['left', 'top']}>
        <Box stack="row" spacing="base">
          <Pill>Default Pill</Pill>
          <Pill variant="action">Action Pill</Pill>
          <Pill variant="alert">Alert Pill</Pill>
          <Pill variant="success">Success Pill</Pill>
          <Pill>Super Long text examplee</Pill>
        </Box>
      </ComponentPreview>

      <Design>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            The text is positioned to be optically centered in the pill. The
            pill is constructed with a Caption text style within 150 height
            works out at 34px tall. The size of the pill will depend of the
            content. Once it reaches 150px, it is truncated with an ellipsis.
          </Text>
        </Design.Section>
        <Design.Section heading="Usage">
          <Text paragraph>
            Pills should be used in place of text when a little more visual
            context is required - such as for statuses. Use adjectives for the
            names of the pills. Do not use verbs, verbs might suggest that the
            pill is clickable and can be be used to perform actions.
          </Text>
        </Design.Section>
        <Design.Section heading="Colour usage">
          <Box>
            <Text paragraph>
              The colours of the pill will vary depending on the status they
              represent. Using established color patterns will help users
              identify their status.
            </Text>
            <Text paragraph>
              Use green for status to represent completed processes such as:
              funded, paid, completed, passed.
            </Text>
            <TextList>
              <TextList.Item>Background: Shieldpay/Secondary-200</TextList.Item>
              <TextList.Item>Outline: Shieldpay/Secondary-500</TextList.Item>
            </TextList>

            <Text paragraph>
              Use grey to represent initiated processes such as created.
            </Text>
            <TextList>
              <TextList.Item>Background: Global/neutral-150</TextList.Item>
              <TextList.Item>Outline: Global/neutral-500</TextList.Item>
            </TextList>

            <Text paragraph>
              Use red to represent critical status such as cancelled.
            </Text>
            <TextList>
              <TextList.Item>Background: Global/alert-200</TextList.Item>
              <TextList.Item>Outline: Global/alert-500</TextList.Item>
            </TextList>

            <Text paragraph>
              Use purple to represent processes waiting for an action such as:
              pending, in progress, partially approved.
            </Text>
            <TextList>
              <TextList.Item>Background: Shieldpay/Primary-200</TextList.Item>
              <TextList.Item>Outline: Shieldpay/Primary-500</TextList.Item>
            </TextList>
          </Box>
        </Design.Section>

        <Design.Section heading="Accessibility">
          <Text paragraph>
            Accessibility Use the appropriate ARIA attributes for screen readers
            where applicable.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <PillMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgEmptyCheck = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 27c6.075 0 11-4.925 11-11S22.075 5 16 5 5 9.925 5 16s4.925 11 11 11zm0 2c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgEmptyCheck;

import { Heading, Text, ValidationChecklist } from '@shieldpay/bumblebee';
import ValidationChecklistMarkdown from '@shieldpay/bumblebee/markdown/validation-checklist.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const ValidationChecklistComponentPage = () => {
  usePageTitle({ id: 'hive.components.validationChecklist.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <Heading level="3" variant="title">
          “Active” field state
        </Heading>
        <ValidationChecklist
          items={[
            {
              valid: true,
              text: 'Valid checklist item',
            },
            {
              valid: false,
              text: 'Invalid checklist item',
            },
            {
              valid: false,
              text: 'Another invalid checklist item',
            },
          ]}
        />
        <Heading level="3" variant="title">
          “Inactive with error” field state
        </Heading>
        <ValidationChecklist
          displayState="error"
          items={[
            {
              valid: true,
              text: 'Valid checklist item',
            },
            {
              valid: false,
              text: 'Invalid checklist item',
            },
            {
              valid: false,
              text: 'Another invalid checklist item',
            },
          ]}
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            Password field should update to show the rules as the user types my
            password so I can update/change my password easily.
          </Text>
          <Text paragraph>
            This will be used in the instance of a new user signing up and
            setting their new password or an existing user resetting their
            password if they have forgotten it.
          </Text>
        </Design.Section>
        <Design.Section heading="Structure">
          <Text paragraph>
            The Primary password rules should be shown beneath the password
            field in a similar way to the helper text. The Primary rules should
            be displayed in a checklist format (with varying states of icons at
            20px). Please follow the spacing as per the designs.
          </Text>
          <Text paragraph>
            The secondary password rules should be displayed in an Alert box
            under the password field and under the Primary rules (if shown
            together). Please follow the spacing as per the designs.
          </Text>
          <Text paragraph>
            In the instance of multiple secondary rules, they should always be
            shown the order provided.
          </Text>
        </Design.Section>
        <Design.Section heading="Interaction">
          <Text paragraph>
            As the user interacts with the password field and it changes to
            active state, the primary checklist should be shown. As the user
            types and the password rules are met, they should update from
            incomplete icons to complete icons.
          </Text>
          <Text paragraph>
            As I am typing, primary checklist rules will toggle between
            incomplete and complete states.
          </Text>
          <Text paragraph>
            If a user clicks out of the password field (including in another
            field or button) and I have correctly met all password requirements,
            primary checklist should be hidden.
          </Text>
          <Text paragraph>
            If a user clicks out of the password field (including in another
            field or button) before meeting all password requirements, they
            should be shown a primary and/or secondary error.
          </Text>
          <Text paragraph>
            If the error is primary, then the error should be shown in the
            checklist.
          </Text>
          <Text paragraph>
            The incomplete rule should change colour to Alert-base and
          </Text>
          <Text paragraph>
            If the error is secondary, then it should display in an external
            alert box.
          </Text>
          <Text paragraph>
            Transition to display a state change with a 0.25 ease.
          </Text>
          <Text paragraph>Animations to be a Cubic Ease-out at 250ms</Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Text paragraph>
            Input field relies on the standard text field component.
          </Text>
          <Text paragraph>
            Text size of the Primary rules checklist should follow the standard
            Field Helper style
          </Text>
          <Text paragraph>
            The visual style for Secondary rules error box should follow the set
            visual as the error box.
          </Text>
          <Text paragraph>
            For password creation we should be following the Version 1 style
            with bullet points.
          </Text>
          <Text paragraph>
            If there are specific ARIA attributes to help users, these should be
            applied.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <ValidationChecklistMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { Divider, Text } from '@shieldpay/bumblebee';
import CardMarkdown from '@shieldpay/bumblebee/markdown/card.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const DividerComponentPage = () => {
  usePageTitle({ id: 'hive.components.divider.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview alignItems={['left', 'top']}>
        <Text variant="headline2">Example heading before Divider</Text>
        <Divider />
        <Text paragraph>Example text after Divider</Text>
      </ComponentPreview>
      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The divider is used to divide different sections of content.
          </Text>
        </Design.Section>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            The divider is simply a 1px line using colour: global/neutral-250
            The divider adjust to the width of the container.
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            The card component will have various placements. These will be
            defined in the component documentation.
          </Text>
        </Design.Section>
        <Design.Section heading="Placement">
          <Text paragraph>
            Use the divider on the heading for the review page on the onboarding
            flow.
          </Text>
        </Design.Section>
      </Design>
      <MDXCodeblockProvider>
        <CardMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

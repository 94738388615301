import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgCorrectIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12z"
        stroke="#353C4A"
        strokeWidth={2}
      />
      <path
        d="M10 16.521l3.711 3.712 8.522-8.522"
        stroke="#353C4A"
        strokeWidth={2}
      />
    </svg>,
  );
};

export default SvgCorrectIcon;

// hook to scroll to top of current page
export const useScrollToTop = () => {
  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return { handleScrollToTop };
};

import { ReactNode } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';

import { Box } from '@shieldpay/bumblebee';

interface CodeblockProps {
  children?: ReactNode;
}

export const Codeblock = ({ children }: CodeblockProps) => (
  <Highlight {...defaultProps} code={children as string} language="jsx">
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <Box className={className} css={style} padding="base" component="pre">
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </Box>
    )}
  </Highlight>
);

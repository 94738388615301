import { FormattedMessage } from 'react-intl';

import { Box, Heading, Infoblock, Table, Text } from '@shieldpay/bumblebee';
import InfoblockMarkdown from '@shieldpay/bumblebee/markdown/infoblock.md';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const InfoblockComponentPage = () => {
  return (
    <>
      <ContentsTable />

      <ComponentPreview alignItems={['left', 'top']}>
        <Box spacing="base">
          <Heading level="3" variant="title">
            Infoblock Variants
          </Heading>

          <Table caption="variant examples">
            <Table.Head>
              <Table.Row>
                <Table.HeaderCell>Info block</Table.HeaderCell>
                <Table.HeaderCell>Error block</Table.HeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.DataCell>
                  <Infoblock variant="info">
                    <FormattedMessage id="hive.components.infoblock.title" />
                  </Infoblock>
                </Table.DataCell>

                <Table.DataCell>
                  <Infoblock variant="error">
                    <FormattedMessage id="hive.components.infoblock.title" />
                  </Infoblock>
                </Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The error/alert message is presented below a form field when an
            error is detected to help the user correct the error.
          </Text>
          <Text paragraph>
            All instances of the error alert is based on the
            ‘Message-alert-error’ master primitive component. If the master
            primitive component is changed, all instances will be updated.
          </Text>
          <Text paragraph>
            It is expected that other message notifications, such as ‘Info’ will
            be based on this style but with different colours and icons.
          </Text>
        </Design.Section>
        <Design.Section heading="Anatomy">
          <Text paragraph>
            Example of the component mentioning the tokens and other components
            used.
          </Text>
          <Design.Section heading="Placement">
            <Text paragraph>
              Short explanation about where the component is going to be used.
              Example: At the bottom of a card, inside a table. Please refer to
              Placement in page designs for examples.{' '}
            </Text>
          </Design.Section>
          <Design.Section heading="Accesibility">
            <Text paragraph>
              To avoid reliance on colour only, the alert error/alert message
              uses a shaded box and an icon to signify alert, alongside a
              description of the error.
            </Text>
            <Text paragraph>
              The error description text colour is within the AA contrast ratio
              for legibility at 14px size.
            </Text>
            <Text paragraph>
              The error/alert message does not rely on icons within the field as
              it may produce unexpected results with browser tools for managing
              forms.
            </Text>
            <Text paragraph>
              Use the appropriate ARIA attributes for screen readers where
              applicable.
            </Text>
          </Design.Section>
          <Design.Section heading="Behaviour">
            <Text paragraph>
              If an error/alert message is displayed, it should ‘push down’ all
              content below with the space it occupies, so that any content
              below retains the existing spacer between it and the next
              component.
            </Text>
          </Design.Section>
          <Design.Section heading="Responsive">
            <Text paragraph>
              The instances of the component can scale vertically and
              horizontally, depending on the amount of text or required
              alignment. The icon and the text retains their alignment and
              positioning as per the spacing example.
            </Text>
          </Design.Section>
          <Design.Section heading="Min + Max Sizing ">
            <Text paragraph>
              The width of the error/alert message box should match the width of
              the form field above across all viewport sizes, unless otherwise
              specified.
            </Text>
          </Design.Section>
          <Design.Section heading="States">
            <Text paragraph>
              Different states of the component. Example: idle, hover, focus,
              active, disabled.
            </Text>
          </Design.Section>
        </Design.Section>
      </Design>
      <MDXCodeblockProvider>
        <InfoblockMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { css } from '@emotion/react';

const V1_FIXED_MAX_WIDTH = 327;

export const notification = css({
  maxWidth: V1_FIXED_MAX_WIDTH,
});

export const noIcon = css({
  textAlign: 'center',
});

import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Heading,
  InputField,
  ProgressIndicator,
  Text,
} from '@shieldpay/bumblebee';
import ProgressIndicatorMarkdown from '@shieldpay/bumblebee/markdown/progress-indicator.md';
import { usePageTitle } from '@shieldpay/utilities-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const ProgressIndicatorComponentPage = () => {
  const { formatMessage, formatNumber } = useIntl();
  usePageTitle({ id: 'hive.components.progressIndicator.title' });
  const [max, setMax] = useState(100);
  const [value, setValue] = useState(30);

  return (
    <>
      <ContentsTable />
      <ComponentPreview spacing="basePos2">
        <ProgressIndicator
          max={max}
          value={value}
          progressLabel={({ max, value }) => {
            const percentage = value / max;
            return formatMessage(
              { id: 'hive.components.progressIndicator.progressPercentage' },
              {
                percentage: formatNumber(percentage, { style: 'percent' }),
              },
            );
          }}
        />
        <InputField
          id="progressIndicatorMax"
          label="Max value"
          value={String(max)}
          onChange={(val) => setMax(Number(val))}
        />
        <InputField
          id="progressIndicatorValue"
          label="Current value"
          value={String(value)}
          onChange={(val) => setValue(Number(val))}
        />
      </ComponentPreview>
      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            The progress indicator should only be used when we have steps and we
            should inform your user on which stage he is now.
          </Text>
        </Design.Section>
        <Design.Section heading="Anatomy">
          <Heading level="4" variant="title">
            Desktop:
          </Heading>
          <Text paragraph>
            The progress indicator should consist of 2 lines and the percent
            optional. The first line should be 440px width(light colour) and the
            second one should fill it step by step.
          </Text>
          <Heading level="4" variant="title">
            Mobile:
          </Heading>
          <Text paragraph>
            The progress indicator should consist of 2 lines and the percent
            optional. The first line should be as a maximum width(light colour)
            but not more then 440px and the second one should fill it step by
            step. Also, here we have company logo.
          </Text>
        </Design.Section>
        <Design.Section heading="Behaviour">
          <Heading level="4" variant="title">
            Desktop:
          </Heading>
          <Text paragraph>
            The progress indicator should consist of 2 lines and the percent
            optional. The first line should be 440px width(light colour) and the
            second one should fill it step by step.
          </Text>
          <Heading level="4" variant="title">
            Mobile:
          </Heading>
          <Text paragraph>
            The first line should be as a maximum width(light colour) but not
            more then 440px and the second one should fill it step by step.
            Also, the percentages on the top left or right corner should be
            counted and showed(optional) to the user. Also, for mobile we have a
            version without a logo and percent (simple version).
          </Text>
        </Design.Section>
        <Design.Section heading="Accessibility">
          <Heading level="4" variant="title">
            For screen readers:
          </Heading>
          <Text paragraph>
            Need to add a percentage indicator lebel to ensure people using
            these readers can also understand how far along they are in the
            process.
          </Text>
        </Design.Section>
      </Design>
      <MDXCodeblockProvider>
        <ProgressIndicatorMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

// we need to pass the whole descriptor instead of just the ID,
// otherwise formatjs won't be able to statically
// evaluate messages
export const usePageTitle = (descriptor: MessageDescriptor) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    document.title = formatMessage(descriptor);

    // we don't want to pass the whole descriptor as a dependency,
    // because this hook's usage will be something like usePageTitle({ id: someId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, descriptor.id]);
};

import { memo } from 'react';

import { Dropdown, DropdownProps } from '../dropdown/dropdown';
import {
  FieldContainer,
  FieldContainerBaseProps,
} from '../field-container/field-container';

export interface DropdownFieldProps<Value = string>
  extends Omit<DropdownProps<Value>, 'label'>,
    FieldContainerBaseProps {
  /**
   * Override of logic to show outline error styling on dropdown without validation messages
   */
  showVisualErrorStyles?: boolean;
}

export const DropdownFieldWithoutMemo = <Value,>({
  disabled = false,
  items,
  placeholder,
  validation,
  onBlur,
  onChange,
  label,
  helpText,
  value,
  id,
  size = 'medium',
  showValidation = false,
  showRequiredIndicator = false,
  showVisualErrorStyles = false,
}: DropdownFieldProps<Value>) => {
  return (
    // Pass label directly to Dropdown rather than FieldContainer.
    // Downshift needs to pass props onto the label.
    <FieldContainer
      id={id}
      validation={validation}
      showValidation={showValidation}
      disabled={disabled}
      showRequiredIndicator={showRequiredIndicator}
      helpText={helpText}
    >
      <Dropdown
        disabled={disabled}
        items={items}
        label={label}
        useLabelComponent
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        size={size}
        value={value || ''} // always use Dropdown as controlled, which requires us to provide an empty string rather than undefined
        hasError={showValidation || showVisualErrorStyles}
      />
    </FieldContainer>
  );
};

// Manually reset the displayName in the component tree.
DropdownFieldWithoutMemo.displayName = 'DropdownField';

export const DropdownField = memo(
  DropdownFieldWithoutMemo,
) as typeof DropdownFieldWithoutMemo;

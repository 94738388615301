import {
  focusRingShadowProperty,
  focusRingStyle,
  themeifyStyle,
} from '../../themes/utilities';

export const link = themeifyStyle((theme) => ({
  backgroundColor: theme.palette.neutral500,
  position: 'relative',
}));

export const enabled = themeifyStyle((theme) => ({
  textDecoration: 'none',
  transition: `background-color ${theme.transitions.standard}, ${
    focusRingStyle(theme).transition
  }`,
  '&[aria-current="page"]': {
    background: theme.palette.neutral450,
    '::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: theme.spacing.baseNeg3,
      backgroundColor: theme.palette.secondary500,
    },
  },
  ':hover': {
    backgroundColor: theme.palette.neutral450,
    cursor: 'pointer',
  },
  ':focus': {
    outline: 'none',
    '::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      boxShadow: focusRingShadowProperty(theme, 'baseNeg7', true),
    },
  },
}));

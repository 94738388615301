import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Padding } from '../box/use-box-styles';
import { Text } from '../text/text';

import * as styles from './tag.styles';

type TagSize = 'small' | 'medium' | 'large';

export type TagProps = {
  variant?: keyof typeof styles.variant;
  size?: TagSize;
  children: ReactNode;
  'data-testid'?: string;
};

export const TEST_IDS = {
  TAG: 'tag-component',
  TEXT: 'tag-component-text',
};

const buttonPadding: Record<TagSize, { box: Padding; text: Padding }> = {
  small: { box: { x: 'baseNeg6' }, text: { x: 'baseNeg6', y: 'baseNeg7' } },
  medium: { box: { x: 'baseNeg5' }, text: { x: 'baseNeg6', y: 'baseNeg5' } },
  large: { box: { x: 'baseNeg4' }, text: { x: 'baseNeg5', y: 'baseNeg3' } },
};

export const Tag = ({
  variant = 'primary',
  size = 'medium',
  children,
  'data-testid': testId = TEST_IDS.TAG,
}: TagProps) => (
  <Box
    padding={buttonPadding[size].box}
    rounded
    css={[styles.tag, styles.variant[variant]]}
    data-testid={testId}
  >
    <Text
      padding={buttonPadding[size].text}
      variant={size === 'small' ? 'captionSmall' : 'caption'}
      truncate
      data-testid={TEST_IDS.TEXT}
    >
      {children}
    </Text>
  </Box>
);

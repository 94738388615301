import { focusRingStyle, themeifyStyle } from '../../themes/utilities';

export const fauxInput = themeifyStyle((theme) => ({
  minWidth: theme.spacing.basePos3,
  minHeight: theme.spacing.basePos3,
  transition: `border ${theme.transitions.standard}, ${
    focusRingStyle(theme).transition
  }`,
}));

export const fauxInputStates = themeifyStyle((theme) => ({
  border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral200}`,
  '*:focus-within > &': {
    border: `${theme.spacing.baseNeg8}px solid ${theme.palette.primary300}`,
    ...focusRingStyle(theme).style,
  },
  '*:hover > &': {
    border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral500}`,
  },
  '*:focus-within:hover > &': {
    border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral500}`,
    ...focusRingStyle(theme).style,
  },
}));

export const fauxInputType = {
  radio: {
    borderRadius: '50%',
  },
  checkbox: themeifyStyle(({ borderRadius }) => ({
    borderRadius,
  })),
};

export const fauxInputDisabled = themeifyStyle((theme) => ({
  border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral250}`,
}));

export const fauxInputSvg = themeifyStyle((theme) => ({
  fill: theme.palette.neutral500,
}));

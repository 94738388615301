import {
  ButtonHTMLAttributes,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
  SyntheticEvent,
} from 'react';

import {
  LinkOrButton,
  useClickableElementStyles,
  UseClickableElementStylesOptions,
} from '../../hooks/use-clickable-element-styles/use-clickable-element-styles';
import { Box } from '../box/box';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { Text } from '../text/text';

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
> &
  Omit<UseClickableElementStylesOptions, 'size' | 'variant'> &
  Partial<LinkOrButton> & {
    onClick?: (e: SyntheticEvent) => void;
    children?: ReactNode;
    'data-testid'?: string;
    loading?: boolean;
    LeftIcon?: ElementType;
    RightIcon?: ElementType;
  };

export const TEST_IDS = {
  LEFT_ICON: 'left-icon',
  RIGHT_ICON: 'right-icon',
};

/**
 * Do not use the ButtonWithoutForwardRef export in the platform, use the Button export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const ButtonWithoutForwardRef = (
  {
    size = 'medium',
    'data-testid': testId,
    onClick,
    children,
    variant = 'solid',
    type = 'button',
    disabled,
    loading = false,
    LeftIcon,
    RightIcon,
    ...buttonProps
  }: ButtonProps,
  ref?: ForwardedRef<HTMLButtonElement>,
) => {
  const { buttonResetStyles, styles, padding, textVariant, iconSize } =
    useClickableElementStyles({
      ...({ size, variant } as LinkOrButton),
      disabled,
    });

  const spinnerSize = size === 'small' ? 'small' : 'large';

  return (
    <Box
      component="button"
      stack="row"
      css={[styles, buttonResetStyles]}
      onClick={onClick}
      data-testid={testId}
      type={type}
      ref={ref}
      disabled={loading || disabled}
      padding={{ x: padding.x, y: 'none' }}
      alignItems={['left', 'center']}
      {...buttonProps}
    >
      {LeftIcon ? (
        <LeftIcon data-testid={TEST_IDS.LEFT_ICON} size={iconSize} />
      ) : null}
      <Text
        color="currentColor"
        padding={{ y: padding.y }}
        variant={textVariant}
      >
        {children}
      </Text>
      {RightIcon ? (
        <RightIcon data-testid={TEST_IDS.RIGHT_ICON} size={iconSize} />
      ) : null}
      {loading ? (
        <LoadingSpinner
          size={spinnerSize}
          color={variant === 'solid' ? 'white' : 'primary'}
        />
      ) : null}
    </Box>
  );
};

// Manually reset the displayName in the component tree.
ButtonWithoutForwardRef.displayName = 'Button';

export const Button = forwardRef(ButtonWithoutForwardRef);

import * as styles from './grid-item.styles';

type GridStart = '1' | '2' | '3';
type GridEnd = 'span2';

export interface GetGridsItemStyles {
  columnStart?: GridStart;
  columnEnd?: GridEnd;
  rowStart?: GridStart;
  rowEnd?: GridEnd;
}

/**
 * For providing custom positioning of child elements of the Grid component
 */
export const gridItemStyles = ({
  columnStart,
  columnEnd,
  rowStart,
  rowEnd,
}: GetGridsItemStyles) => [
  columnStart && styles.columnStart[columnStart],
  columnEnd && styles.columnEnd[columnEnd],
  rowStart && styles.rowStart[rowStart],
  rowEnd && styles.rowEnd[rowEnd],
];

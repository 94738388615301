import { ReactNode } from 'react';

import { Box, BoxProps } from '../box/box';

import { UseTextStyleProps, useTextStyles } from './use-text-styles';

type ParagraphOrComponent =
  | {
      component: BoxProps['component'];
      paragraph?: never;
    }
  | {
      component?: never;
      paragraph?: boolean;
    };

export type TextProps = Omit<BoxProps, 'size' | 'color' | 'component'> &
  UseTextStyleProps &
  ParagraphOrComponent & {
    children: ReactNode;
  };

export const Text = ({
  component,
  className,
  color,
  children,
  variant,
  transform = false,
  hidden = false,
  screenReaderOnly = false,
  paragraph = false,
  truncate = false,
  padding = 'none',
  align,
  alignItems = false,
  ...boxProps
}: TextProps) => (
  <Box
    component={!component ? (paragraph ? 'p' : 'span') : component}
    className={className}
    css={
      useTextStyles({
        variant,
        color,
        truncate,
        transform,
        align,
      }).styles
    }
    hidden={hidden}
    alignItems={alignItems}
    padding={padding}
    screenReaderOnly={screenReaderOnly}
    inline={!paragraph}
    {...boxProps}
  >
    {children}
  </Box>
);

import { useState } from 'react';

import { ChoiceList } from '@shieldpay/bumblebee';
import ChoiceListMarkdown from '@shieldpay/bumblebee/markdown/choice-list.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { ComponentPreview } from '../../components/preview/component-preview';

const options = [
  { label: <>One</>, value: '1' },
  { label: <>Two</>, value: '2' },
];

export const ChoiceListComponentPage = () => {
  usePageTitle({ id: 'hive.components.checkbox.title' });

  const [checkboxes, setCheckboxes] = useState<Array<string>>([]);
  const [radiobutton, setRadiobutton] = useState('1');

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <ChoiceList
          id="example1"
          name="example1"
          variant="checkbox"
          label="checkbox"
          value={checkboxes}
          options={options}
          onChange={setCheckboxes}
        />
        <ChoiceList
          id="example2"
          name="example2"
          variant="radiobutton"
          label="radiobutton"
          value={radiobutton}
          options={options}
          onChange={setRadiobutton}
        />
        <ChoiceList
          disabled
          id="example3"
          name="example3"
          variant="checkbox"
          label="checkbox disabled"
          value={null}
          options={options}
          onChange={noop}
        />
        <ChoiceList
          disabled
          id="example4"
          name="example4"
          variant="radiobutton"
          label="radiobutton disabled"
          value={null}
          options={options}
          onChange={noop}
        />
      </ComponentPreview>

      <MDXCodeblockProvider>
        <ChoiceListMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};

import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgQuestionIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 27c6.075 0 11-4.925 11-11S22.075 5 16 5 5 9.925 5 16s4.925 11 11 11zm0 2c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13z"
        fill="#353C4A"
      />
      <path
        d="M14.5 22a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM14.993 19.148h2.048v-.136c.029-1.647.501-2.385 1.719-3.136 1.281-.774 2.07-1.862 2.07-3.473 0-2.328-1.762-3.903-4.39-3.903-2.414 0-4.347 1.41-4.44 3.989h2.177c.086-1.519 1.167-2.184 2.263-2.184 1.217 0 2.205.809 2.205 2.076 0 1.067-.666 1.819-1.518 2.349-1.332.816-2.12 1.625-2.134 4.282v.136z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgQuestionIcon;

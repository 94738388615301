import { createElement } from 'react';

import { Icon, IconProps } from '../icon/icon';

const SvgChevronLeftEndIcon = (componentProps: IconProps) => {
  const props = {
    css: [],
  };
  return createElement(
    Icon,
    componentProps,
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.199 25.191l-9.191-9.207L22.2 6.792l1.414 1.414-7.779 7.78 7.78 7.792-1.416 1.413zM9 6.86h2V25H9V6.86z"
        fill="#353C4A"
      />
    </svg>,
  );
};

export default SvgChevronLeftEndIcon;

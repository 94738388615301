import { ReactNode } from 'react';

import { Box } from '../box/box';
import { Grid } from '../grid/grid';
import { gridItemStyles } from '../grid/grid-item';
import { PrimaryNav } from '../primary-nav/primary-nav';

import * as styles from './side-panel.styles';

interface BumblebeeLayoutProps {
  navItems: ReactNode;
  header: ReactNode;
  children: ReactNode;
}

export const BumblebeeLayout = ({
  children,
  header,
  navItems,
}: BumblebeeLayoutProps) => (
  <Grid layout="bumblebee" fullHeight>
    <Box
      component="header"
      css={[styles.sidePanel]}
      padding={{
        x: 'basePos3',
        top: 'basePos4',
        bottom: 'basePos5',
      }}
    >
      {header}
    </Box>

    <PrimaryNav
      css={[
        gridItemStyles({ columnStart: '1', rowStart: '2' }),
        styles.sidePanel,
      ]}
    >
      {navItems}
    </PrimaryNav>

    <Grid
      layout="bumblebeeContainer"
      css={gridItemStyles({ columnStart: '2', rowStart: '2' })}
    >
      <Box
        component="main"
        css={gridItemStyles({ columnStart: '2' })}
        spacing="base"
      >
        {children}
      </Box>
    </Grid>
  </Grid>
);

import { RadioButton, Text } from '@shieldpay/bumblebee';
import RadioButtonMarkdown from '@shieldpay/bumblebee/markdown/radio-button.md';
import { usePageTitle } from '@shieldpay/utilities-ui';
import { noop } from '@shieldpay/utility-functions-ui';

import { MDXCodeblockProvider } from '../../components/code-block/mdx-codeblock-provider';
import { ContentsTable } from '../../components/contents-table/contents-table';
import { Design } from '../../components/design/design';
import { ComponentPreview } from '../../components/preview/component-preview';

export const RadioButtonComponentPage = () => {
  usePageTitle({ id: 'hive.components.radioButton.title' });

  return (
    <>
      <ContentsTable />
      <ComponentPreview>
        <RadioButton
          id="example1"
          key="example1"
          label="I am radio button 1, check it."
          onClick={noop}
          value="radio-button-1"
        />
        <RadioButton
          id="example2"
          key="example2"
          label="I am radio button 2, check it."
          onClick={noop}
          checked
          value="radio-button-2"
        />
        <RadioButton
          id="example3"
          key="example3"
          label="I am a disabled radio button."
          onClick={noop}
          value="radio-button-3"
          disabled
        />
      </ComponentPreview>

      <Design>
        <Design.Section heading="Usage">
          <Text paragraph>
            Only to be used when only a single option can be selected from a
            list.
          </Text>
          <Text paragraph>
            Focus state has the same 4px outline as per the buttons.
          </Text>
        </Design.Section>
      </Design>

      <MDXCodeblockProvider>
        <RadioButtonMarkdown />
      </MDXCodeblockProvider>
    </>
  );
};
